import React, { useState, useEffect } from 'react';
import { Upload, Button, Modal, message } from 'antd'
import { uploadHeader } from '../helpers/response'
import { UploadOutlined, InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import { SentralModel } from '../models/SentralModel'

const { Dragger } = Upload

export const Uploader = (props) => {
    const [files, setFiles] = useState([])
    const [urlFile, setUrlFile] = useState()

    const { title = 'Click to Upload', accept = '.png,.jpg,.jpeg,.pdf,.xlsx,.docx', dragger = false, folder = "sample", view = false } = props

    const removeFile = () => {
        if (files.length > 0) {
            const previuosFile = files[0].response.message
            SentralModel.remove(previuosFile.replace('storage/', ''))
        }
    }

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        props.isUploading(true)
        // if (info.file.status !== 'uploading') {
        // }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.isUploading(false)
            props.onUploaded(info.file.response)
            // removeFile()
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            props.isUploading(false)
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'file',
        multiple: false,
        action: `${process.env.REACT_APP_API_URL}/upload/${folder}`,
        headers: uploadHeader(),
        accept: accept,
        showUploadList: {
            showDownloadIcon: view,
            downloadIcon:
                <a target="_blank" rel="noreferrer" onClick={() => setUrlFile(files[0].response.message)} href={`${process.env.REACT_APP_API_FILE}/${urlFile}`}>View</a>,
            showRemoveIcon: view,
            removeIcon:
                <div style={{ marginLeft: 15 }}>
                    <DeleteOutlined style={{ color: 'red' }} />
                </div>
        },
        onChange: handleChange,
        onRemove: removeFile
    };

    return (
        <div>
            {
                dragger ?
                    <Dragger {...uploadHandler}>
                        <p className="text-center">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                    :
                    <Upload {...uploadHandler} fileList={files}>
                        <Button icon={<UploadOutlined />}>{title}</Button>
                    </Upload>
            }

        </div>
    );
}


export const ImageUploader = (props) => {
    const { title = 'Click to Upload', accept = '.png,.jpg,.jpeg', defaultFile = null } = props
    const [files, setFiles] = useState([])
    const [previewVisible, setPreviewVisible] = useState(false)
    useEffect(() => {
        if (defaultFile) {
            setFiles([{
                uid: 1,
                name: defaultFile,
                url: process.env.REACT_APP_API_FILE + defaultFile,
                status: 'done',
                isInitial: true
            }])
        }
    }, [defaultFile]);

    const removeFile = () => {
        if (files.length > 0 && files[0].isInitial) {
            console.log(files[0])
            // const previuosFile=files[0].name.replace('storage/','')
            // SentralModel.remove(previuosFile.replace('/',''))
        }
    }

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.onUploaded(info.file.response)
            removeFile()
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'file',
        multiple: false,
        action: `${process.env.REACT_APP_API_URL}/upload/${props.folder}`,
        headers: uploadHeader(),
        accept: accept,
        onChange: handleChange,
        onRemove: removeFile,
        onPreview: () => setPreviewVisible(true)
    };


    return (
        <div>
            {
                (files.length > 1) ? null :
                    <Upload listType="picture-card" {...uploadHandler} fileList={files} >
                        <div>
                            <UploadOutlined />
                            <div style={{ marginTop: 8 }}>{title}</div>
                        </div>
                    </Upload>
            }
            {
                files.length > 0 ?
                    <Modal
                        visible={previewVisible}
                        title={null}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={files[0].url} />
                    </Modal> : null
            }
        </div>
    );
}
