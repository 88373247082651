import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input,  message, Space, Select, DatePicker} from 'antd'
import {AddButton, PageTitle, Spinner, FormModal, DataTable, EditButton, DeleteButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
import moment from 'moment'
import XLSX from 'xlsx';

const {Text} = Typography
const {Option} = Select

const Realization = () => {
    const [form]= Form.useForm()
    const [budgets, setBudgets] = useState([])
    const [budgetPlans, setBudgetPlans] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const [formModal, setFormModal] = useState(false)
    const [formImportModal, setFormImportModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [importRealization, setImportRealization] = useState([])

    const fetchBudgets = (y) => {
        setLoaded(false)
        SentralModel.list('BudgetRealization', {filter:[["division_id", authData.division_id], ["year", y]]}).then(res => {
            setBudgets(res.data)
            setLoaded(true)
        })
    }

    const fetchBudgetPlan = (y) => {
        SentralModel.list('BudgetPlanning', {filter:[["division_id", authData.division_id], ["year", y]], orderBy: "description", order:"ASC"}).then((res) => {
            setBudgetPlans(res.data)
        })
    }

    useEffect(() => {
        fetchBudgets(moment().year());
        fetchBudgetPlan(moment().year())
    }, []);

    const dataSource = [
        ...budgets.map(el => {
            return{
                budget_realization_id: el.budget_realization_id,
                budget_planning_id: el.budget_planning_id,
                description: el.description,
                amount: el.amount,
                coa_code: el.budget?.coa_code,
                coa_name: el.budget?.coa?.coa_name,
                budget_description: el.budget?.description,
                year: moment(el.year, 'YYYY'),
                realization_month: el.realization_month,
                realization_month_name: moment(el.realization_month, 'M').format('MMMM'),
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },{
            title: 'COA',
            dataIndex: 'coa_name',
            key: 'coa_name',
        },{
            title: 'Budget',
            dataIndex: 'budget_description',
            key: 'budget_description',
        },{
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },{
            title: 'Amount',
            dataIndex: 'amount',
            render: (text) => toIdr(text)
        },{
            title: 'Realization',
            dataIndex: 'realization_month_name',
            key: 'realization_month_name',
        },{
            title: 'Action',
            align:'center',
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editRealization(row)}/>
                        <DeleteButton onConfirm={() => deleteRealization(row.budget_realization_id)} />
                    </Space>
                </div>
            )
        },
    ];

    const addRealization = () => {
        form.setFieldsValue({
            budget_realization_id: 0,
            budget_planning_id: null,
            year: moment(),
            description: null,
            amount: null,
            realization_month: moment(),
        });
        setFormModal(true);
    }

    const editRealization = (v) => {
        console.log(moment())
        console.log(moment(v.realization_month, 'M'))

        form.setFieldsValue({
            budget_realization_id: v.budget_realization_id,
            budget_planning_id: v.budget_planning_id,
            year: v.year,
            description: v.description,
            amount: toIdr(v.amount),
            realization_month: moment(v.realization_month, 'M'),
        })
        setFormModal(true)
    }

    const saveRealization = (v) => {
        setSaving(true)
        let payload = {
            budget_realization_id: v.budget_realization_id,
            division_id: authData.division_id,
            budget_planning_id: v.budget_planning_id,
            year: moment(v.year).format('YYYY'),
            description: v.description,
            amount: extractNumber(v.amount),
            realization_month: moment(v.realization_month, 'MMMM').format('MM'),
        }

        SentralModel.action('Budget', 'saveRealization', payload, v.budget_realization_id).then((res) => {
            fetchBudgets(selectedYear);
            message.success('Budget realization saved', 2);
            setFormModal(false);
            setSaving(false);
        }).catch(() => {
            message.destroy();
            message.error('Failed to add realization', 2);
            setSaving(false);
        })
    }

    const deleteRealization = (id) => {
        message.loading('Deleting realization...');

        SentralModel.action("Budget", "deleteRealization", null, id).then((res) => {
            message.destroy();
            message.success('Realization deleted', 2);
            fetchBudgets(selectedYear);
        })
    }

    const changeYear = (v) => {
        setSelectedYear(v)
        fetchBudgets(v)
    }

    const changeAmount = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({amount: toIdr(number)})
    }

    const addImport=() => {
        setDefaultValues({
            budget_planning_id: 0,
        })
        setFormImportModal(true)
    }

    const handleFile = (file /*:File*/) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
        //   console.log(rABS, wb);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          /* Update state */
          setImportRealization(data)
        //   console.log(data)
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const saveImportRealization = (v) => {
        setSaving(true)
        let payload = {
            year: moment(v.year).format('YYYY'),
            division_id: authData.division_id,
            realizations: importRealization
        }
        SentralModel.action('Budget', 'importRealization', payload, v.budget_planning_id).then(() => {
            fetchBudgets(selectedYear);
            message.success('Budget realization saved', 2);
            setFormImportModal(false);
            setSaving(false);
        }).catch(() => {
            message.destroy();
            message.error('Failed to add realization', 2);
            setSaving(false);
        })
    }

    const downloadTemplate = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/realizationTemplate?year=${selectedYear}&division_id=${authData.division_id}`)
    }

    return (
        
        <div>
            <Card className="content-container">
                <PageTitle title="Budget Realization" breadcrumbs={[['Budget Realization']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Budget Realization ({authData.division.division_name})</Text>
                        <AddButton right onAdd={addRealization }  t it le="Add Budget Realization"/>
                        <Text style={{float: 'right', marginRight: '5px', marginLeft: '5px'}}>&nbsp;</Text>
                        <AddButton right onAdd={addImport} title="Import Realization"/>
                    </div> }>
                    <div className="full-width">
                        <span className="mb-3" style={{float:'right', marginLeft:'10px'}}>
                            <Space>
                                <Select value={selectedYear} placeholder="Select Year" style={{ width: 250}} onChange={changeYear}>
                                    <Option value={moment().year()-1}>{moment().year()-1}</Option>
                                    <Option value={moment().year()}>{moment().year()}</Option>
                                    <Option value={moment().year()+1}>{moment().year()+1}</Option>
                                </Select>
                            </Space>
                        </span>
                    </div>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner/>
                    }
                </Card>
                
                {
                    (formModal) ?
                        <FormModal form={form} title='Budget Realization' submitForm={(v) => saveRealization(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="budget_realization_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item name="year" label="Year" rules={[{required:true}]}>
                                <DatePicker picker="year" style={{width:'100%'}} format='YYYY' />
                            </Form.Item>
                            <Form.Item name="realization_month" label="Realization Month" rules={[{required:true}]}>
                                <DatePicker picker="month" style={{width:'100%'}} format='MMMM'/>
                            </Form.Item>
                            <Form.Item name="budget_planning_id" label="Budget" rules={[{required:true}]}>
                                <Select showSearch allowClear placeholder="Select Budget" style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        budgetPlans.map((el, key) => (
                                            <Option key={key} value={el.budget_planning_id}>{el.coa_code} - {el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="description" label="Description" rules={[{required:true}]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="amount" label="Amount" onChange={changeAmount} rules={[{required:true}]}>
                                <Input />
                            </Form.Item>
                        </FormModal> 
                    : null
                }

                {
                    (formImportModal) ?
                    <FormModal title='Import Realization' defaultValues={defaultValues} submitForm={(v) => saveImportRealization(v)} width={400} showModal={formImportModal} onCloseModal={() => setFormImportModal(false)} saving={saving}>
                        <Form.Item name="year" label="Year" rules={[{required:true}]}>
                            <DatePicker picker="year" style={{width:'100%'}} format='YYYY'/>
                        </Form.Item>
                        <Form.Item label="Excel File" name="excel_file">
                            <ImportFile handleFile={handleFile} />
                        </Form.Item>
                        <div style={{marginBottom: '10px'}}>
                            <a href="#0" onClick={downloadTemplate} download>
                                Click here to download template
                            </a>
                        </div>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Realization;

class ImportFile extends React.Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
      const files = e.target.files;
      if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
      return (
        <div className="form-group">
            <input
                type="file"
                className="form-control"
                id="file"
                accept=".xlsx"
                onChange={this.handleChange}
            />
        </div>
      );
    }
}