import { useState, useEffect } from 'react';
import { Card, Descriptions, Button, Typography, Input, Select, message, Popconfirm, Row, Col, Space } from 'antd';
import { BasicModal, DataTable, PageTitle, Spinner } from '../../../components'
import { CloseCircleOutlined, PlusCircleOutlined, SaveOutlined, UndoOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { SentralModel } from '../../../models/SentralModel';
import { useParams } from 'react-router-dom';
import { toIdr, extractNumber } from '../../../utils/currencyHandler';
import { toFullDate } from '../../../utils/dateHandler';
import { Link } from 'react-router-dom';

const { Text } = Typography
const { Option } = Select

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Detail = () => {
    const { employeeCode } = useParams()
    const forceUpdate = useForceUpdate()
    const [saving, setSaving] = useState(false)
    const [employee, setEmployee] = useState(null)
    const [allowances, setAllowances] = useState(null)
    const [deductions, setDeductions] = useState(null)
    const [benefits, setBenefits] = useState(null)
    const [absentPenalty, setAbsentPenalty] = useState(0)
    const [components, setComponents] = useState([])
    const [overtimeCategories, setOvertimeCategories] = useState([])
    const [lateCategories, setLateCategories] = useState([])
    const [dailyIncentive, setDailyIncentive] = useState(0)
    const [weeklyIncentive, setWeeklyIncentive] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [basicSalary, setBasicSalary] = useState({})
    const [ovtCompesation, setOvtCompesation] = useState({})
    const [ovtMultiplier, setOvtMultiplier] = useState([])
    const [modalInfo, setModalInfo] = useState(false)

    const fetchComponents = () => {
        SentralModel.list('PayrollComponent').then((res) => {
            setComponents(res.data)
        })
    }

    const fetchBasicSalary = (employeeCode) => {
        SentralModel.action("EmployeeBasicSalary", "getSalaryEmp", { employee_code: employeeCode }, null).then((res) => {
            res.data !== null ? setBasicSalary(res.data?.basic_salary) : setBasicSalary(0)
        })
    }

    const fetchOvtMultiplier = () => {
        SentralModel.action("Overtime", "getOvertimeMultiplier", null, null).then((res) => {
            setOvtMultiplier(res.data)
        })
    }

    const fetchOvertimeCompesation = () => {
        SentralModel.action("Overtime", "overtimeCompensationActive", null, null).then((res) => {
            setOvtCompesation(res.data)
        })
    }

    const fetchSchema = (employeeCode) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getSchemaSetup', { employee_code: employeeCode }, 0).then((res) => {
            setEmployee(res.data.employee)
            setLateCategories(res.data.late)
            setOvertimeCategories(res.data.overtime)
            if (res.data.schema.allowances.length > 0) {
                setAllowances(res.data.schema.allowances)
            } else {
                setAllowances([{
                    component_code: null,
                    description: null,
                    amount: null,
                    type: null
                }])
            }
            if (res.data.schema.deductions.length > 0) {
                setDeductions(res.data.schema.deductions)
            } else {
                setDeductions([{
                    component_code: null,
                    description: null,
                    amount: null,
                    type: null
                }])
            }
            if (res.data.schema.benefits.length > 0) {
                setBenefits(res.data.schema.benefits)
            } else {
                setBenefits([{
                    component_code: null,
                    description: null,
                    amount: null,
                    type: null
                }])
            }
            setAbsentPenalty(res.data.absentPenalty)
            setDailyIncentive(res.data.daily_incentive)
            setWeeklyIncentive(res.data.weekly_incentive)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchComponents()
        fetchSchema(employeeCode)
        fetchBasicSalary(employeeCode)
        fetchOvtMultiplier()
        fetchOvertimeCompesation()

    }, [employeeCode]);

    const addAllowances = () => {
        let x = allowances;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setAllowances(x)
        forceUpdate()
    }

    const resetSchema = () => {
        setLoaded(false)
        setAllowances(null)
        setDeductions(null)
        SentralModel.action('Payroll', 'resetSchema', { employee_code: employeeCode }, 0).then(() => {
            message.success('Schema Has been reset', 2)
            fetchSchema(employeeCode)
        })
    }

    const addDeductions = () => {
        let x = deductions;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setDeductions(x)
        forceUpdate()
    }

    const addBenefits = () => {
        let x = benefits;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setBenefits(x)
        forceUpdate()
    }

    const onChangeAllowance = (v, key) => {
        let x = allowances
        if (x[key] !== undefined && x[key].component_code === 'GAPOK') {
            const hourSalary = (1 / 173) * extractNumber(v.target.value)
            overtimeCategories.forEach((el) => {
                Object.assign(el, { first_overtime_amount: hourSalary * el.first_hour, next_overtime_amount: hourSalary * el.next_hour })
            })
        }

        x[key].amount = extractNumber(v.target.value)
        setAllowances(x)
        forceUpdate()
    }

    const submitSchema = () => {
        setSaving(true)
        const check = allowances.filter((el) => el.component_code === 'GAPOK')
        if (check.length === 0) {
            allowances.push({
                component_code: "GAPOK",
                description: "Gaji Pokok",
                amount: basicSalary,
                type: "GAPOK"
            })
        }
        let schemaData = {
            employee_code: employeeCode,
            allowances: allowances,
            deductions: deductions,
            benefits: benefits,
            late: lateCategories,
            overtime: overtimeCategories,
            absent_penalty: absentPenalty,
            daily_incentive: dailyIncentive,
            weekly_incentive: weeklyIncentive
        }
        // console.log(schemaData)
        SentralModel.action('Payroll', 'saveSchema', schemaData, 0).then(() => {
            message.success("Payroll Schema successfully updated", 2)
            setSaving(false)
        }).catch(() => {
            setSaving(false)
        })
    }

    const columns = [
        {
            title: 'First Hour',
            render: (row) => (
                row?.first_hour
            ),
            key: 'first_hour'
        },
        {
            title: 'Next Hour',
            render: (row) => (
                row?.second_hour
            ),
            key: 'next_hour'
        },
        {
            title: 'Overtime Amount',
            render: (row) => (
                ovtCompesation.category === 'DIVIDER' ?
                    toIdr((basicSalary / ovtCompesation.value) * row.multiply)
                    :
                    toIdr(ovtCompesation.value * row.multiply)
            ),
            key: 'overtime_amount'
        }
    ]

    const lateColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Amount',
            render: (row) => (
                toIdr(row?.amount)
            ),
            key: 'amount'
        }
    ]

    return (
        <Card className="content-container">
            <PageTitle title="Payroll Schema" breadcrumbs={[['Payroll Schema', '/payroll/schema'], ['Edit']]}></PageTitle>
            {
                (loaded) ?
                    <>
                        <Descriptions className="mb-3" size="middle" column={4} bordered layout="vertical">
                            <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                                <div className="text-center">{employee.name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Division</Text></div>}>
                                <div className="text-center">{employee.division.division_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Position</Text></div>}>
                                <div className="text-center">{employee.position.position_name}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Join Date</Text></div>}>
                                <div className="text-center">{toFullDate(employee.join_date)}</div>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions className="mb-3" size="middle" column={4} bordered>
                            <Descriptions.Item label={<div className="text-center"><Text strong>Basic Salary</Text></div>}>
                                <div className="text-center">
                                    {
                                        allowances.filter((i) => i.component_code === "GAPOK").length > 0 ?
                                            toIdr(allowances.filter((i) => i.component_code === "GAPOK")[0].amount) :
                                            toIdr(basicSalary)
                                    }
                                </div>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                    : null
            }

            <Card style={{ marginBottom: 20 }} title={
                <Row justify='end'>
                    <Space>
                        <Popconfirm title="Reset payroll schema...?" okText="Yes" cancelText="No" onConfirm={() => resetSchema()}>
                            <Button size="middle" type="danger" icon={<UndoOutlined />}>Reset Schema</Button> &nbsp;
                        </Popconfirm>
                        {
                        !basicSalary ? 
                            <Link to={'/payroll/setup/'}>
                                <Button type="primary">Set Basic Salary</Button> &nbsp;
                            </Link> 
                        : null}
                        <Button type="primary" onClick={() => setModalInfo(true)}><InfoCircleOutlined /></Button>
                    </Space>
                </Row>
            }>
                {
                    (allowances && deductions && benefits) ?
                        <div>
                            <table className="table-default table-collapse" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: '35%' }}>Allowances</th>
                                        <th className="text-center" >Deductions</th>
                                        <th className="text-center" >Benefits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: "top" }}>
                                            <table className="table-noborder" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '60%' }}>Component</th>
                                                        <th>Value</th>
                                                        <th style={{ width: '7%' }}>
                                                            <Button type="primary" onClick={addAllowances} icon={<PlusCircleOutlined />} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allowances.filter((i) => i.component_code !== "GAPOK").map((alw, key) => (
                                                            <tr key={'allowance_item_' + key}>
                                                                <td>
                                                                    <Select value={alw.component_code} style={{ width: '100%' }} placeholder="Select Component" onChange={(v, e) => {
                                                                        let x = allowances
                                                                        x[key].component_code = v
                                                                        x[key].description = e.children
                                                                        let f = components.find(a => a.component_code === v)
                                                                        x[key].type = f.type
                                                                        setAllowances(x)
                                                                        forceUpdate()
                                                                    }}>
                                                                        {
                                                                            components.filter(el => el.category === "ALLOWANCE").map((el, key2) => (
                                                                                <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </td>
                                                                <td>
                                                                    <Input value={toIdr(alw.amount)} onChange={(v) => onChangeAllowance(v, key)} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button type="danger" onClick={() => {
                                                                        let x = allowances
                                                                        x.splice(key, 1);
                                                                        setAllowances(x)
                                                                        forceUpdate()
                                                                    }} icon={<CloseCircleOutlined />} />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ verticalAlign: "top" }}>
                                            <table className="table-noborder" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '60%' }}>Component</th>
                                                        <th>Value</th>
                                                        <th style={{ width: '5%' }}>
                                                            <Button type="primary" onClick={addDeductions} icon={<PlusCircleOutlined />} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        deductions.map((ded, key) => (
                                                            <tr key={'deduction_item_' + key}>
                                                                <td>
                                                                    <Select value={ded.component_code} style={{ width: '100%' }} placeholder="Select Component" onChange={(v, e) => {
                                                                        let x = deductions
                                                                        x[key].component_code = v
                                                                        x[key].description = e.children
                                                                        let f = components.find(a => a.component_code === v)
                                                                        x[key].type = f.type
                                                                        setDeductions(x)
                                                                        forceUpdate()
                                                                    }}>
                                                                        {
                                                                            components.filter(el => el.category === "DEDUCTION").map((el, key2) => (
                                                                                <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </td>
                                                                <td>
                                                                    <Input value={toIdr(ded.amount)} onChange={(v) => {
                                                                        let x = deductions
                                                                        x[key].amount = extractNumber(v.target.value)
                                                                        setDeductions(x)
                                                                        forceUpdate()
                                                                    }} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button type="danger" onClick={() => {
                                                                        let x = deductions
                                                                        x.splice(key, 1);
                                                                        setDeductions(x)
                                                                        forceUpdate()
                                                                    }} icon={<CloseCircleOutlined />} />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ verticalAlign: "top" }}>
                                            <table className="table-noborder" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '60%' }}>Component</th>
                                                        <th>Value</th>
                                                        <th style={{ width: '5%' }}>
                                                            <Button type="primary" onClick={addBenefits} icon={<PlusCircleOutlined />} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        benefits.map((ded, key) => (
                                                            <tr key={'benefit_item_' + key}>
                                                                <td>
                                                                    <Select value={ded.component_code} style={{ width: '100%' }} placeholder="Select Component" onChange={(v, e) => {
                                                                        let x = benefits
                                                                        x[key].component_code = v
                                                                        x[key].description = e.children
                                                                        let f = components.find(a => a.component_code === v)
                                                                        x[key].type = f.type
                                                                        setBenefits(x)
                                                                        forceUpdate()
                                                                    }}>
                                                                        {
                                                                            components.filter(el => el.category === "BENEFIT").map((el, key2) => (
                                                                                <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </td>
                                                                <td>
                                                                    <Input value={toIdr(ded.amount)} onChange={(v) => {
                                                                        let x = benefits
                                                                        x[key].amount = extractNumber(v.target.value)
                                                                        setBenefits(x)
                                                                        forceUpdate()
                                                                    }} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button type="danger" onClick={() => {
                                                                        let x = benefits
                                                                        x.splice(key, 1);
                                                                        setDeductions(x)
                                                                        forceUpdate()
                                                                    }} icon={<CloseCircleOutlined />} />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="full-width text-right mt-2">
                                <Button loading={saving} type="primary" onClick={submitSchema} icon={<SaveOutlined />} size="medium">Save</Button>
                            </div>
                        </div>
                        :
                        <Spinner />
                }
            </Card>

            {
                modalInfo ?
                    <BasicModal title="Information" showModal={modalInfo} onCloseModal={() => setModalInfo(false)} width={1000}>
                        <Card className='mb-3' title="Late Categories">
                            <DataTable bordered search={false} pagination={false} dataSource={lateCategories} columns={lateColumns} />
                        </Card>
                        <Card className='mb-3' title="Overtime Schema">
                            {
                                (ovtCompesation && basicSalary) &&
                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Card title="WORKING DAYS">
                                            <DataTable bordered search={false} pagination={false} dataSource={ovtMultiplier.filter((el) => el.category === 'WORKINGDAYS')} columns={columns} />
                                        </Card>
                                    </Col>
                                    <Col span={11}>
                                        <Card title="HOLIDAYS">
                                            <DataTable bordered search={false} pagination={false} dataSource={ovtMultiplier.filter((el) => el.category === 'HOLIDAY')} columns={columns} />
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </Card>
                    </BasicModal> : null
            }
        </Card>
    );
}

export default Detail;
