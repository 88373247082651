import React, {useEffect, useState} from 'react';
import {Card, Col, Descriptions, Row, Space, Form, Select, message} from 'antd';
import {DeleteButton, PageTitle, Spinner, FormModal, AddButton} from '../../../components';
import {EditButton, DataTable} from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {useHistory} from 'react-router-dom'
import {getQuery} from '../../../helpers/url'

const {Option} = Select

const Custom = () => {
    const history = useHistory();
    const [loaded, setLoaded] = useState(true)
    const [approvals, setApprovals] = useState([])
    const [info, setInfo] = useState(null)
    const [customModal, setCustomModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [saving, setSaving] = useState(false)

    const fetchCustomSchema = (schemaId, divisionId) => {
        SentralModel.action('Approval','customSchema',{schema_id: schemaId, division_id:divisionId},0).then((res) => {
            setApprovals(res.data.schema)
            setInfo(res.data.info)
            setLoaded(true)
        })
    }

    const fetchEmployees = (division_id) => {
        SentralModel.list('Employee',{filter:[['division_id',division_id],['is_active','=','1']]}).then((res)=>{
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        let schemaId = getQuery('schema')
        let divisionId = getQuery('division')
        fetchEmployees(divisionId)
        fetchCustomSchema(schemaId, divisionId)
    }, []);

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            className:'text-top',
            width:'70px',
            align:'center',
            key: 'name',
        },{
            title: 'Nama Karyawan',
            dataIndex: 'name',
            className:'text-top',
            key: 'schema_name',
        },{
            title: 'Approver',
            render: (row) =>(
                <ol style={{paddingLeft:'1em', marginBottom:0}}>
                {
                    row.approvers.map((el, key) => (
                        <li key={'approver_'+key}>{el.employee ? el.employee.name : null}</li>
                    ))
                }
                </ol>
            )
        },{
            title: 'Action',
            className:'text-top',
            align:'center',
            width:'10%',
            render: (row) =>(
                <Space>
                    <EditButton link to={'/management/approval-schema/custom/detail?id='+row.approval_custom_id}/>
                    <DeleteButton onConfirm={() => deleteCustomSchema(row.approval_custom_id)}/>
                </Space>
            )
        }
    ];
    const dataSource = [
        ...approvals.map((el) => {
            return {
                id: el.approval_schema_id,
                approval_custom_id: el.approval_custom_id,
                name: el.employee ? el.employee.name : null,
                approvers: el.approvers
            }
        })
    ]

    const deleteCustomSchema = (id) => {
        SentralModel.action('Approval','deleteCustomApproval',{approval_custom_id:id},0).then(()=>{
            message.success('Custom approval berhasil dihapus',2)
            fetchCustomSchema(getQuery('schema'), getQuery('division'))
        })
    }

    const addCustomApproval = (v) =>{
        setSaving(false)
        SentralModel.save('ApprovalCustom',{approval_schema_id: getQuery('schema'),employee_code:v.employee_code},0).then((res)=>{
            message.success('Custom approval berhasil ditambahkan', 2)
            history.push('/management/approval-schema/custom/detail?id='+res.id)
        })
    } 

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Approval Schema" breadcrumbs={[['Approval Schema','/management/approval-schema'],['Custom']]}></PageTitle>
                <div className="full-width">
                    <Row>
                        <Col span={12}>
                            <Descriptions layout="horizontal" column={1} bordered size="small">
                                <Descriptions.Item label="Divisi">{(info) ? info.division_name: '-'}</Descriptions.Item>
                                <Descriptions.Item label="Schema">{(info) ? info.description: '-'}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={12} className="text-right">
                            <AddButton onAdd={() => setCustomModal(true)} title="Tambah Custom Approval"/>
                        </Col>
                    </Row>
                </div>
                {
                    (loaded) ?
                    <DataTable bordered dataSource={dataSource} columns={columns}/>
                    :
                    <Spinner/>
                }
            </Card>
            {
                (customModal) ?
                    <FormModal title="Add Custom Approval" submitForm={(v)=>addCustomApproval(v)} width={500} showModal={customModal} onCloseModal={() => setCustomModal(false)} saving={saving}>
                        <Form.Item label="Pilih Karyawan" name="employee_code" rules={[{required:true}]}>

                            <Select 
                                showSearch 
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                    employees.map((el, key) => (
                                        <Option key={key} value={el.employee_code}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal> 
                : null
            }
        </div>
    );
}

export default Custom;
