import React, { useEffect, useState } from 'react'
import { Row, Typography, Divider, Empty, Form, Input, DatePicker, Switch, message } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner, Uploader } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import { ImageViewer } from '../../components/ImageViewer';
import moment from 'moment'
import { useParams } from 'react-router-dom'
import authData from '../../helpers/authData'

const { Title } = Typography
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Medical = (props) => {
    const { employeeCode } = useParams()
    const [empMedicalHistories, setEmpMedicalHistories] = useState(props.data);
    const [loaded, setLoaded] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [hospitalized, setHospitalized] = useState(true);
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fetchEmpMedicalHistories = () => {
        setLoaded(false)
        SentralModel.list('EmployeeMedicalHistory', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpMedicalHistories(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)
    }, []);

    const addMedical = () => {
        setModalTitle('Add Medical History')
        setDefaultValues({
            emp_medical_history_id: 0,
            is_hospitalization: false,
        })
        setHospitalized(false)
        // console.log(hospitalized)
        setFormModal(true)
    }

    const editMedical = (v) => {
        setModalTitle('Edit Medical History')
        setDefaultValues({
            emp_medical_history_id: v.emp_medical_history_id,
            title: v.title,
            description: v.description,
            period: [moment(v.from_period), moment(v.to_period)],
            is_hospitalization: parseInt(v.is_hospitalization),
            attachment: v.attachment
        })
        setHospitalized(v.is_hospitalization)
        setFormModal(true)
    }

    const saveMedical = (v) => {
        setSaving(true)
        let payload = {
            emp_medical_history_id: v.emp_medical_history_id,
            employee_code: props.emp_code,
            title: v.title,
            description: v.description,
            from_period: moment(v.period[0], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            to_period: moment(v.period[1], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            is_hospitalization: hospitalized,
            attachment: attachmentFile
        }
        SentralModel.save('EmployeeMedicalHistory', payload, v.emp_medical_history_id).then((res) => {
            setFormModal(false)
            fetchEmpMedicalHistories()
            message.success('Medical history saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteMedical = (id) => {
        message.loading('deleting medical history')
        SentralModel.deleteById('EmployeeMedicalHistory', id).then((res) => {
            message.destroy()
            message.success('Medical history deleted', 2)
            let tmp = empMedicalHistories
            let index = tmp.findIndex(el => el.emp_medical_history_id === id)
            tmp.splice(index, 1);
            setEmpMedicalHistories([])
            setEmpMedicalHistories(tmp)
        })
    }

    const changeHospitalized = e => {
        // console.log(e)
        setHospitalized(e)
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    return (
        <div>
            <div className="text-right">
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                        <AddButton onAdd={addMedical}>Add data</AddButton>
                        : null
                }
            </div>
            {
                loaded ?
                    (empMedicalHistories.length > 0) ?
                        empMedicalHistories.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key + 1}.</Title></Divider>
                                {
                                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                                        <ActionButton editable deleteable onEdit={() => editMedical(el)} onDelete={() => deleteMedical(el.emp_medical_history_id)} /> : null
                                }
                                <Row>
                                    <ProfileItem span={8} title="Title">{el.title}</ProfileItem>
                                    <ProfileItem span={8} title="Period">{moment(el.from_period).format('DD MMMM YYYY') + '-' + moment(el.to_period).format('DD MMMM YYYY')}</ProfileItem>
                                    <ProfileItem span={8} title="Hospitalized">{el.is_hospitalization === '1' ? 'Yes' : 'No'}</ProfileItem>
                                    <ProfileItem span={8} title="Description">{el.description}</ProfileItem>
                                    <ProfileItem image span={16} title="Attachment">
                                        <div style={{ width: 200, maxWidth: '100%', border: '1px solid #ededed' }}>
                                            {
                                                el.attachment !== null ?
                                                    el.attachment.includes('.pdf') ?
                                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + el.attachment}>
                                                            Show Attachment
                                                        </a> :
                                                        <ImageViewer previewable={el.attachment ? true : false} src={process.env.REACT_APP_API_FILE + "/" + el.attachment} alt="attachment" /> :
                                                    <ImageViewer previewable={el.attachment ? true : false} src={process.env.PUBLIC_URL + '/images/noimage.png'} alt="attachment" />
                                            }
                                        </div>
                                    </ProfileItem>
                                </Row>
                            </div>
                        ))
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveMedical(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving} loading={uploading}>
                        <Form.Item name="emp_medical_history_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                            <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item label="Period" name="period" rules={[{ required: true }]}>
                            <RangePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Hospitalized" name="is_hospitalization" rules={[{ required: true }]}>
                            <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={changeHospitalized} defaultChecked={defaultValues.is_hospitalization} />
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                            <TextArea rows={4} placeholder="Description" />
                        </Form.Item>
                        <Form.Item label="Attachment" name="medical-history" rules={[{ required: false }]}>
                            <Uploader dragger folder="medical-history" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default Medical;
