import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SentralModel } from "../../../models/SentralModel";
import { message } from "antd";
import currentUser from "../../../helpers/authData";
import moment from "moment";

export const fetchWorkingLocation = createAsyncThunk(
    'workingLocation/fetchWorkingLocation',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("WorkingLocation", "getWorkingLocation", { employee_code: currentUser.employee_code }, 0)

            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchWorkingLocationByDate = createAsyncThunk(
    'workingLocation/fetchWorkingLocationByDate',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("WorkingLocation", "getWorkingLocationByDate", payload, 0)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchCompany = createAsyncThunk(
    'workingLocation/fetchCompany',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Company", "getCompany", null, 0)

            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchMeasurement = createAsyncThunk(
    'workingLocation/fetchMeasurement',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.list('Measurement')
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const saveCompany = createAsyncThunk(
    'workingLocation/saveCompany',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Company", "saveCompany", payload, 0)
            message.success("Success Add Company")
            return response.data
        } catch (error) {
            message.warning("Failed Add Company")
            return rejectWithValue(error)
        }
    }
)

export const saveWorkingLocation = createAsyncThunk(
    'workingLocation/saveWorkingLocation',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "saveWorkingLocation", payload, null)
            message.success("Success Add Working Location")
            return response.data
        } catch (error) {
            message.warning("Failed Add Working Location")
            return rejectWithValue(error)
        }
    }
)

export const deleteWorkingLocation = createAsyncThunk(
    'workingLocation/deleteWorkingLocation',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("WorkingLocation", "deleteWorkingLocation", null, payload)
            message.success("Success Delete Working Location")
            return response.data
        } catch (error) {
            message.warning("Failed Delete Working Location")
            return rejectWithValue(error)
        }
    }
)

const workingLocationSlice = createSlice({
    name: 'karajoTicket',
    initialState: {
        listWorkingLocation: [],
        listWorkingLocationByDate: [],
        modalAddWorkingLocation: false,
        modalWorkingLocationTitle: "",
        modalCompany: false,
        defaultValues: {},
        defaultValuesWorking: {},
        companies: [],
        measurements: [],
        measurement: null,
        date: null,
    },
    reducers: {
        showAddWorkingLocation: (state, action) => {
            state.modalAddWorkingLocation = true
            state.modalWorkingLocationTitle = "Add Working Location"
            state.defaultValues = {
                working_location_id: 0,
                measurement_id: null,
                company_id: null,
                description: null,
            }
            state.defaultValuesWorking = {
                working_location_id: 0,
                measurement_id: null,
                company_id: null,
                description: null,
            }
            state.date = action.payload ?? null
        },
        showEditWorkingLocation: (state, action) => {
            state.modalAddWorkingLocation = true
            state.modalWorkingLocationTitle = "Edit Working Location"
            state.defaultValues = {
                working_location_id: action.payload.working_location_id,
                measurement_id: action.payload.measurement_id,
                company_id: action.payload.company_id,
                description: action.payload.description,
                time: [moment(action.payload.time_start, 'HH:mm'), moment(action.payload.time_end, 'HH:mm')]
            }
            state.measurement = action.payload.measurement_id
        },
        changeModalWorkingLocation: (state, action) => {
            state.modalAddWorkingLocation = action.payload
        },
        showModalCompany: (state, action) => {
            state.modalCompany = true
        },
        changeModalCompany: (state, action) => {
            state.modalCompany = action.payload
        },
        changeMeasurement: (state, action) => {
            state.measurement = action.payload
        },
        changeCompany: (state, action) => {
        },
        changeDate: (state, action) => {
            state.date = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWorkingLocation.fulfilled, (state, action) => {
            let data = []
            action.payload.forEach((el, key) => {
                return data.push({ ...el, index: key })
            })

            state.listWorkingLocation = data
        })
        builder.addCase(fetchWorkingLocationByDate.fulfilled, (state, action) => {
            let data = []
            action.payload.forEach((el, key) => {
                return data.push({ ...el, index: key })
            })

            state.listWorkingLocationByDate = data
        })
        builder.addCase(fetchCompany.fulfilled, (state, action) => {
            state.companies = action.payload
        })
        builder.addCase(fetchMeasurement.fulfilled, (state, action) => {
            let data = action.payload.filter((el) => el.measurement_id === 1 || el.measurement_id === 5 || el.measurement_id === 6)
            state.measurements = data
        })
        builder.addCase(saveWorkingLocation.fulfilled, (state, action) => {
            state.modalAddWorkingLocation = false
        })
        builder.addCase(deleteWorkingLocation.fulfilled, (state, action) => {
            state.modalAddWorkingLocation = false
        })
    }
})

export const {
    showAddWorkingLocation,
    showEditWorkingLocation,
    changeModalWorkingLocation,
    showModalCompany,
    changeModalCompany,
    changeMeasurement,
    changeCompany,
    changeEmployee,
    changeDate
} = workingLocationSlice.actions
export default workingLocationSlice.reducer