import React, {useState, useEffect} from 'react';
import {Card, Form, Input, Select, Row, Col, message} from 'antd'
import { PageTitle, Spinner, SubmitButton} from '../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData'
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons'
import moment from 'moment'

const {Option} = Select;

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Voting = () => {
    const forceUpdate = useForceUpdate()
    const {votingUrl} = useParams();
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [voteId, setVoteId] = useState(null)
    const [questions, setQuestions] = useState([]);
    const [reason, setReason] = useState([])
    const [isVoting, setIsVoting] = useState(false)
    const [dueDate, setDueDate] = useState(null)

    const fetchIsVoting = (votingUrl) => {
        SentralModel.action('Vote', 'getQuestion', {url: votingUrl}).then((res) => {
            setLoaded(false)
            setDueDate(res.data.due_date)
            setIsVoting(res.data.is_voting)
            setVoteId(res.data.vote_id)
            setQuestions(res.data.questions)

        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchIsVoting(votingUrl)
    }, [votingUrl]);

    const saveVoting = (v) => {
        setSaving(true)

        let data = {
            vote_id: voteId,
            employee_code: authData.employee_code,
            reasons: reason
        }

        questions.forEach(element => {
            data[element.question] = v[element.question]
        });

        SentralModel.action('Vote', 'saveVoting', data, votingUrl).then((res) => {
            message.success('form saved',2);
            setSaving(false)
            setIsVoting(true)
        })
    }

    const onChangeAnswer = (v, key) => {
        if (v === 'N') {
            let x = reason
            x.push({id: key, reason: null})
            form.setFieldsValue({
                ['reason['+key+']'] : null,
            })
            setReason(x)
            forceUpdate()
        } else {
            let index = reason.findIndex(rea => rea.id === key)
            if (index > -1) {
                let y = reason
                y.splice(index, 1)
                setReason(y)
                forceUpdate()
            }
        }
    }

    const onChangeReason = (v, key) => {
        let index = reason.findIndex(rea => rea.id === key)
        if (index > -1) {
            let x = reason
            x[index].reason = v.target.value
            setReason(x)
        }
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Voting" breadcrumbs={[['Voting']]}></PageTitle>
            </div>

            {
                loaded ?
                    (moment().format('YYYY-MM-DD') > dueDate) ?
                    <Card className="text-center">
                        {
                            <div>
                                <CloseCircleTwoTone style={{marginTop:'10%', fontSize:'100px'}} twoToneColor="#ff4d4e" /><br/><br/>
                                This vote has been closed !
                            </div>
                        }
                    </Card> :
                    isVoting ?
                    <Card className="text-center">
                        {
                            <div>
                                <CheckCircleTwoTone style={{marginTop:'10%', fontSize:'100px'}} twoToneColor="#52c41a" /><br/><br/>
                                Your vote has been submitted !
                            </div>
                        }
                    </Card> :
                    <Card>
                        <Form size="middle" form={form} layout="vertical" onFinish={saveVoting}>
                            {
                                questions.map((ques, j) => (
                                    <>
                                        <Form.Item key={'question_'+j} label={ques.question} name={ques.question} rules={[{required:true}]}>
                                            {
                                                <Select placeholder="Select Answer..." onChange={(v) => onChangeAnswer(v, j)}>
                                                    <Option key='keyY' value='Y'>Yes</Option>
                                                    <Option key='keyN' value='N'>No</Option>
                                                </Select>
                                            }
                                        </Form.Item>
                                        {
                                            reason.find((rea) => rea.id === j) ?
                                                <Form.Item key={'reason_'+j} name={'reason['+j+']'} rules={[{required:true}]}>
                                                    {
                                                        <Input.TextArea rows={2} placeholder="Reason" onChange={(v) => (onChangeReason(v, j))} />
                                                    }
                                                </Form.Item>
                                            : null
                                            
                                        }
                                    </>
                                ))
                            }
                            <Row justify="space-around">
                                <Col span={24} className="text-right">
                                    <SubmitButton loading={saving}/>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                : <Spinner />
            }
        </Card>
    );
}

export default Voting;
