import React, {useEffect, useState} from 'react';
import {Card, Space, Select, Row, Button} from 'antd'
import { PageTitle, Spinner, DataTable } from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import { setSessionStorage, getSessionStorage } from '../../../helpers/sessionStorage';
import { FileExcelOutlined } from '@ant-design/icons'

const {Option} = Select

const Component = () => {
    const [components, setComponents] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)

    const initialDivision = () => {
        if (getSessionStorage('appraisalDivision')) {
            return parseInt(getSessionStorage('appraisalDivision'))
        }
        return null
    }
    
    const fetchComponents = (divisionId) => {
        let payload = [];
        if (divisionId != null) {
            payload = [
                'division_id', divisionId
            ];
        }

        SentralModel.list('KpiItem', {filter:[payload], orderBy: "division_id", order: "ASC"}).then(res => {
            setComponents(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchCategories  = () => {
        SentralModel.list('KpiItemCategory')
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { fields: "division_id,division_name", orderBy: "division_name", order: "ASC" }).then((res) => {
            setDivisions(res.data)
        })
    }

    useEffect(() => {
        fetchComponents(selectedDivision)
        fetchDivisions()
        fetchCategories()
    }, [selectedDivision]);
    
    const dataSource = [
        ...components.map( el => {
            return {
                id: el.kpi_item_id,
                kpi_item_category_id: el.kpi_item_category_id,
                division: el.division?.division_name ?? 'All Division',
                category: el.category?.name ?? '',
                description: el.description,
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
          title: 'Division',
          dataIndex: 'division',
          key: 'division',
          width: '20%',
        },
        {
          title: 'Category',
          dataIndex: 'category',
          key: 'category',
          width: '20%',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
    ];

    const changeDivision = (v) => {
        setLoaded(false)
        fetchComponents(v)
        setSessionStorage('appraisalDivision', v)
        setSelectedDivision(v)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL +`/report/component-kpi?division_id=${selectedDivision}`);
    }
    
    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Component KPI" breadcrumbs={[['Component']]}></PageTitle>
                    
                <Row>
                    <div className="full-width">
                        <span className="mb-3" style={{ float: 'right' }}>
                            <Space>
                                <Select placeholder="Select Division" defaultValue={initialDivision} style={{ width: 250 }} onChange={changeDivision} loading={divisions.length === 0}>
                                    <Option key="division_all" value={0}>Semua Divisi</Option>
                                    {
                                        divisions.map((el, key) => (
                                            <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                                <Button type="primary" onClick={() => exportReport()}  color="#ededed" icon={<FileExcelOutlined />}>
                                    Export
                                </Button>
                            </Space>
                        </span>
                    </div>
                </Row>

                {
                    (loaded) ?
                    <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                    :
                    <Spinner/>
                }
            </Card>
        </div>
    );
}

export default Component;
