import React from 'react';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom'
export const Breadcrumbs = (props) => {
    return (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                {
                    props.items.map((el, key)=>(
                        (el.length===2) ?
                        <Breadcrumb.Item key={'breadcrumb_'+key}>
                            <Link to={el[1]}>{el[0]}</Link>
                        </Breadcrumb.Item> : 
                        <Breadcrumb.Item key={'breadcrumb_'+key}>
                            {el[0]}
                        </Breadcrumb.Item>
                    ))
                }
            </Breadcrumb>
        </React.Fragment>
    );
}