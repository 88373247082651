import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Tag, Select} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const {Option} = Select

const Index = () => {
    const [emails, setEmails] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchEmails = () =>{
        SentralModel.list('Email').then(res=>{
            setEmails(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmails()
    }, []);
    
    const dataSource = [
        ...emails.map( el => {
            return {
                id: el.email_id,
                name: el.name,
                email: el.email,
                is_system: el.is_system,
                system: el.is_system === '1' ? 'Yes' : 'No'
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: '50%'
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '40%'
        },
        {
            title: 'System',
            dataIndex: 'system',
            key: 'system',
            width: '10%',
            render: (text) => (
                <div className="text-center">
                    <Tag color={text === 'Yes' ? "green" : "red"}>{text}</Tag>
                </div>
            )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                    <EditButton onEdit={() => editEmail(row)}/>
                    <DeleteButton onConfirm={() => deleteEmail(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addEmail=()=>{
        setModalTitle('Add Email')
        setDefaultValues({
            email_id: 0,
            name: '',
            email: '',
            is_system : '0'
        })
        setFormModal(true)
    }

    const editEmail = (v) =>{
        setModalTitle('Edit Email')
        setDefaultValues({
            email_id: v.id,
            name: v.name,
            email: v.email,
            is_system: v.is_system
        })
        setFormModal(true)
    }

    const deleteEmail = (id) =>{
        message.loading('deleting email')
        SentralModel.deleteById('Email', id).then((res)=>{
            message.destroy()
            message.success('Email deleted', 2)
            let tmp = emails
            let index = tmp.findIndex(el => el.email_id===id)
            tmp.splice(index, 1);
            setEmails([])
            setEmails(tmp)
        })
    }

    const saveEmail = (v) => {
        setSaving(true)
        let payload={
            email_id: v.email_id,
            name: v.name,
            email: v.email,
            is_system: v.is_system
        }
        SentralModel.save('Email', payload, v.email_id).then((res)=>{
            setFormModal(false)
            fetchEmails()
            message.success('Email saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Emails" breadcrumbs={[['Email']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Email List</Text>
                        <AddButton right onAdd={addEmail} title="New Email"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveEmail(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="email_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Name" name="name" rules={[{required:true}]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item label="Email" name="email" rules={[{required:true}]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item name="is_system" label="System">
                            <Select>
                                <Option value="0">No</Option>
                                <Option value="1">Yes</Option>
                            </Select>
                        </Form.Item>
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
