import React, {useEffect, useState} from 'react'
import {Row, Typography, Divider, Empty, Form, Input, message} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'

const {Title} = Typography
const {TextArea} = Input;
const Skill = (props) => {
    const [empSkills, setEmpSkills] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);

    const fetchEmpSkills = () =>{
        setLoaded(false)
        SentralModel.list('RecruitmentApplicantSkill', {filter: [['rec_applicant_id', props.applicant_id]]}).then(res=>{
            setEmpSkills(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)
    }, []);

    const addSkill=()=>{
        setModalTitle('Add Skill')
        setDefaultValues({
            rec_applicant_skill_id: 0
        })
        setFormModal(true)
    }

    const editSkill = (v) =>{
        setModalTitle('Edit Skill')
        setDefaultValues({
            rec_applicant_skill_id: v.rec_applicant_skill_id,
            skill: v.skill,
            description: v.description
        })
        setFormModal(true)
    }

    const saveSkill = (v) => {
        setSaving(true)
        let payload={
            rec_applicant_skill_id: v.rec_applicant_skill_id,
            rec_applicant_id: props.applicant_id,
            skill: v.skill,
            description: v.description
        }
        SentralModel.save('RecruitmentApplicantSkill', payload, v.rec_applicant_skill_id).then((res)=>{
            setFormModal(false)
            fetchEmpSkills()
            message.success('Skill saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteSkill = (id) =>{
        message.loading('deleting skill')
        SentralModel.deleteById('RecruitmentApplicantSkill', id).then((res)=>{
            message.destroy()
            message.success('Skill deleted', 2)
            let tmp = empSkills
            let index = tmp.findIndex(el => el.rec_applicant_skill_id===id)
            tmp.splice(index, 1);
            setEmpSkills([])
            setEmpSkills(tmp)
        })
    }

    return (
        <div>
            <div className="text-right">
                <AddButton onAdd={addSkill}>Add data</AddButton>
            </div>
            {
                loaded ?
                    (empSkills.length > 0) ?
                        empSkills.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key+1}.</Title></Divider>
                                <ActionButton editable deleteable onEdit={() => editSkill(el)} onDelete={() => deleteSkill(el.rec_applicant_skill_id)}/>
                                <Row>
                                    <ProfileItem span={8} title="Skill">{el.skill}</ProfileItem>
                                    <ProfileItem span={16} title="Description">{el.description}</ProfileItem>
                                </Row>
                            </div>
                        ))
                    : <Empty />
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveSkill(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="rec_applicant_skill_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Skill" name="skill" rules={[{required:true}]}>
                        <Input placeholder="Skill" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:false}]}>
                        <TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                </FormModal> :
                null
            }
        </div>
    );
}

export default Skill;
