import React, { useState, useEffect } from 'react';
import { Card, Select, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';

const { Option } = Select

const Index = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [employeeData, setEmployeeData] = useState([])
    const [divisions, setDivisions] = useState([])
    const [employeeStatus, setEmployeeStatus] = useState([])
    const [filter, setFilter] = useState({})

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'employee', ft, 0).then((res) => {
            setEmployeeData(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ES']] }, 0).then((res) => {
            setEmployeeStatus(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchEmployeeStatus()
        fetchReport(null)
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ? v.division_id : '',
            status: v.status ? v.status : [],
            gender: v.gender ? v.gender : [],
        }
        fetchReport(ft)
        setFilter(ft)
        setShowFilter(false)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/employee?division_id=${filter.division_id}&status=${filter.status}&gender=${filter.gender}`)
    }

    const employeeColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            fixed: 'left',
            width: 50
        }, {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 200
        }, {
            title: 'NIK',
            dataIndex: 'employee_code',
            key: 'employee_code',
        }, {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
        }, {
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name',
        }, {
            title: 'Join Date',
            dataIndex: 'join_date',
            key: 'join_date',
        }, {
            title: 'Status',
            dataIndex: 'status_name',
            key: 'status_name'
        }, {
            title: 'Place of Birth',
            dataIndex: 'place_of_birth',
            key: 'place_of_birth'
        }, {
            title: 'Date of Birth',
            dataIndex: 'date_of_birth',
            key: 'date_of_birth'
        }, {
            title: 'Gender',
            dataIndex: 'gender_name',
            key: 'gender_name'
        }, {
            title: 'Email',
            dataIndex: 'personal_email',
            key: 'personal_email'
        }, {
            title: 'Phone Number',
            dataIndex: 'mobile_phone',
            key: 'mobile_phone'
        }, {
            title: 'Identity Card No.',
            dataIndex: 'identity_card_no',
            key: 'identity_card_no'
        }, {
            title: 'Address',
            dataIndex: 'address',
            key: 'address'
        }, {
            title: 'NPWP',
            dataIndex: 'npwp_no',
            key: 'npwp_no'
        }, {
            title: 'Tax Status',
            dataIndex: 'tax_status',
            key: 'tax_status'
        }, {
            title: 'Bank Name',
            dataIndex: 'bank_name',
            key: 'bank_name'
        }, {
            title: 'Account Number',
            dataIndex: 'account_no',
            key: 'account_no'
        }, {
            title: 'Last Education',
            render: (row) => (
                row.education_tier ?
                    <div>
                        ({row.education_tier}) {row.education_name}
                    </div>
                    : null
            )
        }, {
            title: 'Resign Date',
            dataIndex: 'exit_date',
            key: 'exit_date',
        }, {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
        }, {
            title: 'Station',
            dataIndex: 'station',
            key: 'station'
        }, {
            title: 'Grade',
            render: (row) => (
                <div>
                    {row.employee_grade} - {row.grade_name}
                </div>
            )
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Employee Report" breadcrumbs={[['Employee Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>

                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 2500 }} size="small" columns={employeeColumn} dataSource={employeeData} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select style={{ width: '100%' }} placeholder="Select Division" allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="status" label="Employee Status">
                        <Select style={{ width: '100%' }} placeholder="Employee Category" mode="multiple" allowClear>
                            {
                                employeeStatus.map((el, key) => (
                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="gender" label="Gender">
                        <Select style={{ width: '100%' }} placeholder="Gender" mode="multiple" allowClear>
                            <Option value='GF'>Female</Option>
                            <Option value='GM'>Male</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Index;
