import React from 'react';
import { Descriptions, Typography } from 'antd';
import {toIdr} from '../../../utils/currencyHandler'
const {Text} = Typography

const PaidDetail = (props) => {
    const {data={allowances:[], deductions:[]}} = props


    const getTotal = (type) =>{
        let total=0;
        data[type].forEach(el => {
            total+=el.amount
        });
        return total;
    }
    return (
        <div>
            <table className="table-collapse table-default mb-3" style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th className="text-center text-bold bg-light-grey">Allowances</th>
                        <th className="text-center text-bold bg-light-grey">Deductions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{width:'50%', verticalAlign:'top'}}>
                            <Descriptions className="full-width" size="small" bordered layout="horizontal" column={1}>
                                {
                                    data.allowances.map((el, key)=>(
                                        <Descriptions.Item key={key} label={<Text strong>{el.description}</Text>}>
                                            {toIdr(el.amount)}
                                        </Descriptions.Item>
                                    ))
                                }
                                
                            </Descriptions>
                        </td>
                        <td style={{width:'50%', verticalAlign:'top'}}>
                            <Descriptions className="full-width" size="small" bordered layout="horizontal" column={1}>
                                {
                                    data.deductions.map((el, key)=>(
                                        <Descriptions.Item key={key} label={<Text strong>{el.description}</Text>}>
                                            {toIdr(el.amount)}
                                        </Descriptions.Item>
                                    ))
                                }
                                
                            </Descriptions>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-right bg-light-grey">
                            <Text strong>Allowance Total : {toIdr(getTotal('allowances'))}</Text>
                        </td>
                        <td className="text-right bg-light-grey">
                            <Text strong>Deduction Total: {toIdr(getTotal('deductions'))}</Text>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-right bg-light-grey">
                            <Text strong>Take Home Pay : {toIdr(getTotal('allowances') - getTotal('deductions'))}</Text>
                        </td>
                        <td className=" bg-light-grey"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default PaidDetail;
