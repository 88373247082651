import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RequestForm from './RequestForm'
import {useHistory} from 'react-router-dom'
import {SentralModel} from '../../../models/SentralModel'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    
    const submitAtk = (v)=>{
        setSaving(true)
        SentralModel.action('Submission','saveStationery', v, 0).then(()=> {
            setSaving(false)
            message.destroy()
            message.success('Stationery submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/office-stationery');
            }, 1000);
        }).catch(() => {
            setSaving(false)
            message.destroy()
            message.error('oops, something went wrong', 2)
            setSaving(false)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Office Stationary" breadcrumbs={[['Office Stationery','/office-stationery'],['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitAtk(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
