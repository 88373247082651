import React, { useEffect, useState } from 'react';
import { Descriptions, Timeline, Typography } from 'antd'
import { Spinner } from '../../components';
import { SentralModel } from '../../models/SentralModel';
import { toFullDate } from '../../utils/dateHandler'
import { LoginOutlined, LogoutOutlined, DislikeOutlined, LikeOutlined, FormOutlined, SolutionOutlined } from '@ant-design/icons'

const { Text } = Typography

const History = (props) => {
    const [empHistory, setEmpHistory] = useState([]);
    const [loaded, setLoaded] = useState(false)

    const fetchHistory = (emp_code) => {
        setLoaded(false)
        SentralModel.action('Profile', 'getHistory', { emp_code: emp_code }, 0).then(res => {
            setEmpHistory(res.data)
            // console.log(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchHistory(props.emp_code)
        setLoaded(true)
    }, [props]);

    const getTimeLine = (v, key) => {
        switch (v.category) {
            case 'join':
                return <Timeline.Item dot={<LoginOutlined style={{ fontSize: '24px' }} />} key={key} color="green" >{toFullDate(v.date)} <br />Join Company</Timeline.Item>
            case 'punishment':
                return <Timeline.Item dot={<DislikeOutlined style={{ fontSize: '20px' }} />} key={key} color="red" >{toFullDate(v.date)}<br />Punishment<br />
                    <div dangerouslySetInnerHTML={{ __html: v.description }}></div>
                </Timeline.Item>
            case 'reward':
                return <Timeline.Item dot={<LikeOutlined style={{ fontSize: '20px' }} />} key={key} color="green" >{toFullDate(v.date)}<br />Reward<br />
                    <div dangerouslySetInnerHTML={{ __html: v.description }}></div>
                </Timeline.Item>
            case 'kpi':
                return <Timeline.Item dot={<FormOutlined style={{ fontSize: '20px' }} />} key={key} color="blue" >{toFullDate(v.date)}<br />Penilaian KPI<br />
                    {v.description}
                </Timeline.Item>
            case 'pra kpi':
                return <Timeline.Item dot={<FormOutlined style={{ fontSize: '20px' }} />} key={key} color="blue" >{toFullDate(v.date)}<br />Penilaian Pra KPI<br />
                    {v.description}
                </Timeline.Item>
            case 'training':
                return <Timeline.Item dot={<SolutionOutlined style={{ fontSize: '20px' }} />} key={key} color="blue" >{toFullDate(v.training_date)}
                    <br />Training<br />
                    {v.title}<br />
                </Timeline.Item>
            case 'exit':
                return <Timeline.Item dot={<LogoutOutlined style={{ fontSize: '20px' }} />} key={key} color="red" >{toFullDate(v.date)} <br />Resign</Timeline.Item>
            default:
                return ''
        }
    }

    return (
        <div>
            {
                loaded ?
                    <div style={{ maxHeight: '100vh', padding: 10, overflowY: 'auto' }}>
                        <Descriptions size="middle" column={1} layout="horizontal" style={{ width: '300px' }}>
                            <Descriptions.Item label={<Text strong>Nama</Text>}>
                                {props.data.name}
                            </Descriptions.Item>
                            <Descriptions.Item label={<Text strong>Divisi</Text>}>
                                {props.data.division.division_name}
                            </Descriptions.Item>
                            <Descriptions.Item label={<Text strong>Posisi</Text>}>
                                {props.data.position.position_name}
                            </Descriptions.Item>
                        </Descriptions>
                        <Timeline mode="left" className="mt-5">
                            {
                                empHistory.map((el, key) => (
                                    getTimeLine(el, key)
                                ))
                            }

                        </Timeline>
                    </div>
                    : <Spinner />
            }
        </div>
    );
}

export default History;
