import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography, Form, Input, Select, Checkbox, message} from 'antd'
import { PageTitle, Spinner, SubmitButton} from '../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData'
import {toFullDate} from '../../../utils/dateHandler'
import {useHistory} from 'react-router-dom'

const {Text} = Typography
const {Option} = Select

const Detail = () => {
    const history = useHistory()
    const {exitId} = useParams()
    const [form] = Form.useForm()
    const [questions, setQuestions] = useState([])
    const [saving, setSaving] = useState(false)


    const fetchQuestion = (exitId) =>{
        if(exitId === '1'){
            SentralModel.list('ExitClearanceQuestion').then((res)=>{
                setQuestions(res.data)
            }).finally(()=>{
            })
        } else {
            SentralModel.list('ExitInterviewQuestion').then((res)=>{
                setQuestions(res.data)
            }).finally(()=>{
            })
        }

        fetchAnswer(exitId)
    }

    const fetchAnswer = (exitId) =>{
        if(exitId === '1'){
            SentralModel.list('ExitClearanceAnswer', { filter:[['employee_code', authData.employee_code]] }).then((res)=>{
                if(res.data.length){
                    res.data.forEach((el, key) => {
                        let field = 'field_'+el.exit_clearance_question_id;
                        form.setFieldsValue({[field]: [el.answer ? 1 : 0]})
                    })
                }
            }).finally(()=>{
            })
        } else {
            SentralModel.list('ExitInterviewAnswer', { filter:[['employee_code', authData.employee_code]] }).then((res)=>{
                res.data.forEach((el, key) => {
                    let field = 'field_'+el.exit_interview_question_id;
                    form.setFieldsValue({[field]: el.answer})
                })
            }).finally(()=>{
            })
        }
    }

    const submitExitClearance = (v) =>{
        let answers = [];
        questions.forEach((el, key) => {
            if(form.getFieldValue('field_'+el.exit_clearance_question_id)){
                answers.push({
                    employee_code: authData.employee_code,
                    exit_clearance_question_id: el.exit_clearance_question_id,
                    answer: form.getFieldValue('field_'+el.exit_clearance_question_id) ? 1 : 0
                }) 
            }
        })

        let payload = {
            employee_code: authData.employee_code,
            answers: answers,
        }
        setSaving(true)
        SentralModel.action('Exit', 'saveClearance', payload, 0).then(()=>{
            message.destroy()
            message.success('Exit clearance saved', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/documentation/exit-clearance');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
        })
    } 

    const submitExitInterview = (v) =>{
        let answers = [];
        questions.forEach((el, key) => {
            if(form.getFieldValue('field_'+el.exit_interview_question_id)){
                answers.push({
                    employee_code: authData.employee_code,
                    exit_interview_question_id: el.exit_interview_question_id,
                    answer: form.getFieldValue('field_'+el.exit_interview_question_id)
                }) 
            }
        })

        let payload = {
            employee_code: authData.employee_code,
            answers: answers
        }
        setSaving(true)
        SentralModel.action('Exit', 'saveInterview', payload, 0).then(()=>{
            message.destroy()
            message.success('Exit interview saved', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/documentation/exit-clearance');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
        })

    } 

    useEffect(() => {
        fetchQuestion(exitId)
    }, [exitId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className="content-container">
            <div>
                <PageTitle title={ exitId === 1 ? 'Exit Clearance' : 'Exit Interview' } breadcrumbs={[['Exit Clearance & Interview', '/documentation/exit-clearance'], ['Detail']]}></PageTitle>
            </div>
            {
                (questions) ? 
                <div>
                    <Card bordered  className="mb-4">
                        <Text strong>{ exitId === 1 ? 'EXIT CLEARANCE' : 'EXIT INTERVIEW' } DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{authData.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Employee Code</th><td>{authData.employee_code}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{authData.division ? authData.division.division_name : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{authData.position ? authData.position.position_name : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Join Date</th><td>{toFullDate(authData.join_date)}</td>
                                        </tr>
                                        <tr>
                                            <th>Exit Date</th><td>{toFullDate(authData.exit_date)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    { (exitId === '1') ?
                        <Form form={form} onFinish={submitExitClearance}>
                            <Card bordered className="mb-4">
                                <Text strong>NOTIFICATION</Text>
                                <Row className="mb-4 mt-1">
                                    <Col span={18}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    questions.filter(ar => ar.category ==="NOTIFICATION").map((el, key)=>(
                                                        <tr>
                                                            <th>
                                                                <Form.Item valuePropName="checked" name={'field_'+el.exit_clearance_question_id}>
                                                                    <Checkbox
                                                                        style={{
                                                                        lineHeight: '32px',
                                                                        }}
                                                                    >
                                                                        {el.question}
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </th>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>


                            <Card bordered className="mb-4">
                                <Text strong>ACCESS SYSTEM</Text>
                                <Row className="mb-4 mt-1">
                                    <Col span={18}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    questions.filter(ar => ar.category ==="ACCESS").map((el, key)=>(
                                                        <tr>
                                                            <th>
                                                                <Form.Item valuePropName="checked" name={'field_'+el.exit_clearance_question_id}>
                                                                    <Checkbox
                                                                        style={{
                                                                        lineHeight: '32px',
                                                                        }}
                                                                    >
                                                                        {el.question}
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </th>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>

                            <Card bordered className="mb-4">
                                <Text strong>ASSET / SECURITY</Text>
                                <Row className="mb-4 mt-1">
                                    <Col span={18}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    questions.filter(ar => ar.category ==="ASSET").map((el, key)=>(
                                                        <tr>
                                                            <th>
                                                                <Form.Item valuePropName="checked" name={'field_'+el.exit_clearance_question_id}>
                                                                    <Checkbox
                                                                        style={{
                                                                        lineHeight: '32px',
                                                                        }}
                                                                    >
                                                                        {el.question}
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </th>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={18} className="text-right mt-4">
                                        <SubmitButton loading={saving}/>
                                    </Col>
                                </Row>
                            </Card>

                        </Form>
                    : 

                        <Form form={form} onFinish={submitExitInterview}>
                            <Card bordered className="mb-4">
                                <Text strong>REASON</Text>
                                <Row className="mb-4 mt-1">
                                    <Col span={18}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    questions.filter(ar => ar.category ==="REASON").map((el, key)=>(
                                                        <tr>
                                                            <th>{el.question}</th>
                                                            <td style={{ width: '50%', paddingTop: '30px'}}>
                                                                {el.multiple_choice ?
                                                                    <Form.Item name={'field_'+el.exit_interview_question_id} rules={[{required:true}]} style={{ width: '100%'}}>
                                                                        <Select placeholder="Select option">
                                                                            {JSON.parse(el.multiple_choice).map((item, i) => (
                                                                                <Option value={item}>{item}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                :
                                                                    <Form.Item name={'field_'+el.exit_interview_question_id} rules={[{required:true}]} style={{ width: '100%'}}>
                                                                        <Input/>
                                                                    </Form.Item>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>


                            <Card bordered className="mb-4">
                                <Text strong>ABOUT YOU</Text>
                                <Row className="mb-4 mt-1">
                                    <Col span={18}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    questions.filter(ar => ar.category ==="ABOUT").map((el, key)=>(
                                                        <tr>
                                                            <th>{el.question}</th>
                                                            <td style={{ width: '50%', paddingTop: '30px'}}>
                                                                {el.multiple_choice ?
                                                                    <Form.Item name={'field_'+el.exit_interview_question_id} rules={[{required:true}]} style={{ width: '100%'}}>
                                                                        <Select placeholder="Select option">
                                                                            {JSON.parse(el.multiple_choice).map((item, i) => (
                                                                                <Option value={item}>{item}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                :
                                                                    <Form.Item name={'field_'+el.exit_interview_question_id} rules={[{required:true}]} style={{ width: '100%'}}>
                                                                        <Input/>
                                                                    </Form.Item>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>

                            <Card bordered className="mb-4">
                                <Text strong>ABOUT COMPANY</Text>
                                <Row className="mb-4 mt-1">
                                    <Col span={18}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    questions.filter(ar => ar.category ==="COMPANY").map((el, key)=>(
                                                        <tr>
                                                            <th>{el.question}</th>
                                                            <td style={{ width: '50%', paddingTop: '30px'}}>
                                                                {el.multiple_choice ?
                                                                    <Form.Item name={'field_'+el.exit_interview_question_id} rules={[{required:true}]} style={{ width: '100%'}}>
                                                                        <Select placeholder="Select option">
                                                                            {JSON.parse(el.multiple_choice).map((item, i) => (
                                                                                <Option value={item}>{item}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                :
                                                                    <Form.Item name={'field_'+el.exit_interview_question_id} rules={[{required:true}]} style={{ width: '100%'}}>
                                                                        <Input/>
                                                                    </Form.Item>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={18} className="text-right mt-4">
                                        <SubmitButton loading={saving}/>
                                    </Col>
                                </Row>
                            </Card>

                        </Form>
                    }
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
