import { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Descriptions } from 'antd'
import { PageTitle, NewButton } from '../../../components'
import BusinessTripRequest from './BusinessTripRequest'
import authData from '../../../helpers/authData'
import { SentralModel } from '../../../models/SentralModel'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Index = () => {
    const [businessTrips, setBusinessTrips] = useState(null)
    const [businessTripInfo, setBusinessTripInfo] = useState(null);
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchBusinessTrips = () => {
        setLoaded(false)
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionBusinessTrip', payload).then(res => {
            setBusinessTrips(res.data)
            setLoaded(true)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', { filter: [['code', 'SBBT']] }, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const fetchBusinessTripInfo = () => {
        SentralModel.action('Submission', 'getMonthlyBusinessTrip', null, null).then((res) => {
            setBusinessTripInfo(res.data[0])
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', { category: 'BUSINESSTRIP', division_id: authData.division_id }).then((res) => {
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchBusinessTrips()
        fetchBusinessTripInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Business Trip" breadcrumbs={[['Business Trip']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Request Business Trip</Text>
                        <NewButton disabled={!available} right to="/business-trip/create" title={(available) ? "Request Business Trip" : 'Approval not available'} />
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Business Trip This Month</Text>}>
                                    {
                                        (businessTripInfo) ? businessTripInfo.total_submission : '0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Total Reimbursed</Text>}>
                                    {
                                        (businessTripInfo) ? toIdr(businessTripInfo.total_amount) : 'Rp 0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br />
                {
                    (loaded) ?
                        <BusinessTripRequest data={businessTrips.filter(el => el.status_realization === 'WAITING' || el.status_realization === 'APPROVED' || el.status_realization === 'REJECTED')} /> : null
                }

            </Card>
        </div>
    );
}

export default Index;
