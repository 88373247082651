import React from 'react';
import {Typography, Descriptions, Tag} from 'antd'
import {formatDateTime} from '../../utils/dateFormat'

const {Text} = Typography

const getStatus = (status) => {
    if(status==="WAITING"){
        return <Tag style={{color:'#808080',width:120}}>Waiting Approval</Tag>
    }else if(status==="APPROVED"){
        return <Tag color="green" style={{width:120}}>APPROVED</Tag>
    }else if(status==="REJECTED"){
        return <Tag color="red" style={{width:120}}>REJECTED</Tag>
    }
}

const DetailHeader = (props) => {
    const {pr=false} = props
    return (
        <Descriptions className="mb-4" size="middle" column={(pr)?4:3} bordered layout="vertical">
            <Descriptions.Item label={<div className="text-center"><Text strong>Requested By</Text></div>}>
                <div className="text-center">{props.name}</div>
            </Descriptions.Item>
            <Descriptions.Item label={<div className="text-center"><Text strong>Requested At</Text></div>}>
                <div className="text-center">{formatDateTime(props.date)}</div>
            </Descriptions.Item>
            <Descriptions.Item label={<div className="text-center"><Text strong>Request Status</Text></div>}>
                <div className="text-center">{getStatus(props.status)}</div>
            </Descriptions.Item>
            {
                (pr)?
                <Descriptions.Item label={<div className="text-center"><Text strong>PR Category</Text></div>}>
                    <div className="text-center">
                        {
                            (pr==='0') ? <Tag color="green">ON BUDGET</Tag> : <Tag color="red">OVER BUDGET</Tag>
                        }
                    </div>
                </Descriptions.Item>
                : null
            }
        </Descriptions>
    );
}

export default DetailHeader;
