import React, {useState} from 'react';
import {Card, Button, Form, Input, DatePicker, Typography, message} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton, ShowButton} from '../../../../components'
import {PlusOutlined} from '@ant-design/icons'
import {SentralModel} from '../../../../models/SentralModel'
import moment from 'moment'

const {Text} = Typography
const Index = (props) => {
    const [objectiveForm] = Form.useForm()
    const [objectiveItemForm] = Form.useForm()
    const [modalObjectiveTitle, setModalObjectiveTitle]= useState(null)
    const [formModalObjective, setFormModalObjective] = useState(false)
    const [modalItemTitle, setModalItemTitle]= useState(null)
    const [formModalItem, setFormModalItem] = useState(false)
    const [saving, setSaving] = useState(false)

    // Objective
    const addObjective = () => {
        setModalObjectiveTitle('Add Objective')
        objectiveForm.setFieldsValue({
            objective_id: 0,
            year: moment(),
            description: ''
        })
        setFormModalObjective(true)
    }

    const editObjective = (v) => {
        setModalObjectiveTitle('Edit Objective')
        objectiveForm.setFieldsValue({
            objective_id: v.objective_id,
            year: moment(v.year, 'YYYY'),
            description: v.description,
        })
        setFormModalObjective(true)
    }

    const saveObjective = (v) => {
        setSaving(true)
        let payload={
            year: moment(v.year, 'YYYY').format('YYYY'),
            description: v.description,
        }
        SentralModel.save('Objective', payload, v.objective_id).then((res) => {
            setFormModalObjective(false)
            props.onFetch();
            message.success('Objective saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteObjective = (id) =>{
        message.loading('Deleting objective item')
        SentralModel.deleteById('Objective', id).then((res) => {
            message.destroy();
            message.success('Objective deleted', 2);
            props.onFetch();
        })
    }

    // Objective Item
    const addObjectiveItem=(v)=>{
        setModalItemTitle('Add Objective Item')
        objectiveItemForm.setFieldsValue({
            objective_item_id: 0,
            objective_id: v,
            description: ''
        })
        setFormModalItem(true)
    }

    const editObjectiveItem = (v) => {
        setModalItemTitle('Edit Objective Item')
        objectiveItemForm.setFieldsValue({
            objective_item_id: v.objective_item_id,
            objective_id: v.objective_id,
            description: v.description,
        })
        setFormModalItem(true)
    }

    const saveObjectiveItem = (v) => {
        setSaving(true)
        let payload={
            objective_id: v.objective_id,
            description: v.description,
        }
        SentralModel.save('ObjectiveItem', payload, v.objective_item_id).then((res)=>{
            setFormModalItem(false)
            props.onFetch();
            message.success('Objective Item saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteObjectiveItem = (id) =>{
        message.loading('Deleting objective item')
        SentralModel.deleteById('ObjectiveItem', id).then((res) => {
            message.destroy();
            message.success('Objective Item deleted', 2);
            props.onFetch();
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Objective List</Text>
                    <AddButton right onAdd={addObjective} title="New Objective"/>
                </div> }>

                <table className="table-collapse table-default" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Year</th>
                            <th>Description</th>
                            <th>Objective</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map((objective, index) => (
                                <tr key={index} style={{verticalAlign: "top"}}>
                                    <td className="text-center" width="5%"> {index + 1} </td>
                                    <td className="text-center"> {objective.year} </td>
                                    <td> {objective.description} </td>
                                    <td style={{padding:0}}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    objective.items.map((item, row) => (
                                                        <tr key={"item" + row}>
                                                            <td>{item.description}</td>
                                                            <td className="text-center" style={{width:'30%'}}>
                                                                <EditButton onEdit={() => editObjectiveItem(item)} />
                                                                &nbsp;
                                                                <DeleteButton onConfirm={() => deleteObjectiveItem(item.objective_item_id)} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr key={"add_" + index}>
                                                    <td colSpan="2">
                                                        <Button type="link" size="small" shape="circle" onClick={() => addObjectiveItem(objective.objective_id)} icon={<PlusOutlined />}>Add Objective</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="text-center" width="10%">
                                        <EditButton onEdit={() => editObjective(objective)} />
                                        &nbsp;
                                        <DeleteButton onConfirm={() => deleteObjective(objective.objective_id)} />
                                        <ShowButton link to={'/softdev/objective/' + objective.objective_id} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </Card>

            {
                (formModalObjective) ?
                <FormModal form={objectiveForm} title={modalObjectiveTitle} submitForm={(v)=>saveObjective(v)} width={400} showModal={formModalObjective} onCloseModal={() => setFormModalObjective(false)} saving={saving}>
                    <Form.Item name="objective_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Year" name="year" rules={[{required:true}]}>
                        <DatePicker picker="year" format="YYYY" style={{width:'100%'}}/>
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input placeholder="Description" />
                    </Form.Item>
                </FormModal>
                : null
            }

            {
                (formModalItem) ?
                <FormModal form={objectiveItemForm} title={modalItemTitle} submitForm={(v)=>saveObjectiveItem(v)} width={400} showModal={formModalItem} onCloseModal={() => setFormModalItem(false)} saving={saving}>
                    <Form.Item name="objective_item_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item name="objective_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input placeholder="Description" />
                    </Form.Item>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
