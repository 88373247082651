import React from 'react';
import { Card, Tabs } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { DataTable, ShowButton } from '../../../components'
import { formatDateTime } from '../../../utils/dateFormat'

const { TabPane } = Tabs;

const Index = (props) => {
    const sttData = props.data !== null ? [
        ...props.data?.map((el) => {
            return {
                id: el?.request_id,
                name: el?.name,
                division_name: el?.division_name,
                request_date: formatDateTime(el?.created_at),
                created_at: el?.created_at,
                status: el?.status
            }
        })
    ] : []

    const sttColumn = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
        align: 'center',
    }, {
        title: 'Nama Karyawan',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Divisi',
        dataIndex: 'division_name',
        key: 'division_name'
    }, {
        title: 'Request Date',
        dataIndex: 'request_date',
        key: 'request_date',
    }, {
        title: 'Action',
        width: '10%',
        render: (row) => (
            <div className="text-center">
                <ShowButton link to={'/submission-list/office-stationery/' + row.id} />
            </div>
        )
    }
    ];

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={sttColumn} dataSource={sttData.filter(el => el.status === "WAITING")} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={sttColumn} dataSource={sttData.filter(el => el.status === "APPROVED")} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={sttColumn} dataSource={sttData.filter(el => el.status === "REJECTED")} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
