import React, {useEffect, useState} from 'react';
import {Card, Row, Col, DatePicker, Space, Descriptions, Button} from 'antd';
import {Bar, Doughnut} from 'react-chartjs-2'
import DashboardItem from './DashboardItem'
import {PageTitle, Spinner} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'
import {ZoomInOutlined} from '@ant-design/icons'
import moment from 'moment'

const {RangePicker} = DatePicker

const Admin = () => {
    const [loaded, setLoaded] = useState(true);
    const [loadedBarData, setLoadedBarData] = useState(false)
    const [attendances, setAttendances] = useState({});
    const [barData, setBarData] = useState(null)
    const [employees, setEmployees] = useState({});
    const [selectedDate, setSelectedDate] = useState([null,null])

    const fetchAttendance = () => {
        setLoaded(false)
        SentralModel.demo('Dashboard', 'attendance', {}, 0).then((res) => {
            setAttendances(res.data)
            setLoaded(true)
        })
    }
    const fetchEmployee = () => {
        setLoaded(false)
        SentralModel.demo('Dashboard', 'employee', {}, 0).then((res) => {
            setEmployees(res.data)
            setLoaded(true)
        })
    }

    const fetchPeriodAttendance = (date) => {
        setLoadedBarData(false)
        if(date){
            SentralModel.demo('Dashboard', 'attendanceChartHR', {from:date.from,to:date.to}, 0).then((res) => {
                setBarData(res.data)
                setLoadedBarData(true)
            })
        }else{
            SentralModel.demo('Dashboard', 'attendanceChartHR', {from:'2021-01-01',to:'2021-01-10'}, 0).then((res) => {
                setBarData(res.data)
                setLoadedBarData(true)
            })
        }
    }

    useEffect(() => {
        fetchAttendance()
        fetchPeriodAttendance({from:moment().startOf('month').format('YYYY-MM-DD'),to:moment().endOf('month').format('YYYY-MM-DD')})
        fetchEmployee()
        setSelectedDate([moment().startOf('month'),moment().endOf('month')])
    }, []);

    const data= {
        labels: (barData) ? barData.days : [],
        datasets: [{
            label: 'Kehadiran',
            data: (barData) ? barData.presences : [],
            backgroundColor: 'rgba(0, 255, 0, 0.3)',
            borderColor: '#2ecc71',
            borderWidth: 1
        },{
            label: 'Alpha',
            data: (barData) ? barData.alphas : [],
            backgroundColor:'rgba(255, 99, 132, 0.3)',
            borderColor:'#ff7675',
            borderWidth: 1
        },{
            label: 'Leave',
            data: (barData) ? barData.leaves : [],
            backgroundColor:'rgba(255, 255, 0, 0.3)',
            borderColor:'#f1c40f',
            borderWidth: 1
        }]
    }
    const counterStyle={
        fontSize:'40px',
        color:'#000',
        fontWeight:'bold'
    }

    const backgroundColor= [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
    ];
    const borderColor =[
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
    ];
    const chartStatus= {
        labels: employees.by_status && employees.by_status.map(v => (v.description)),
        datasets: [{
            label: '',
            data: employees.by_status && employees.by_status.map(v => (v.total)),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
        }]
    }

    const chartGender= {
        labels: employees.by_gender && employees.by_gender.map(v => (v.description)),
        datasets: [{
            label: '',
            data: employees.by_gender && employees.by_gender.map(v => (v.total)),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
        }]
    }
    
    const chartAge= {
        labels: employees.by_age && employees.by_age.map(v => (v.description)),
        datasets: [{
            label: '',
            data: employees.by_age && employees.by_age.map(v => (v.total)),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
        }]
    }

    const filterPeriodAttendance = () =>{
        fetchPeriodAttendance({from:moment(selectedDate[0]).format('YYYY-MM-DD'),to:moment(selectedDate[1]).format('YYYY-MM-DD')})
    }


    return (
        <Card className="content-container">
            <PageTitle title="Absensi" breadcrumbs={[['Absensi']]}></PageTitle>
            {
                loaded ?
                <div>
                    <Row>
                        <Col span={24} className="p-1">
                            <Row className="mb-3" gutter={[16, 16]}>
                                <Col className="gutter-row" lg={{span: 6}} sm={{span: 6}} xs={{span: 6}}>
                                    <DashboardItem title="Kehadiran Hari Ini" height={50}>
                                        <span style={counterStyle}>{attendances.today_attendance}</span>
                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                    </DashboardItem>
                                </Col>
                                <Col className="gutter-row" lg={{span: 6}} sm={{span: 6}} xs={{span: 6}}>
                                    <DashboardItem title="Cuti Hari Ini" height={50}>
                                        <span style={counterStyle}>{attendances.today_leave}</span>
                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                    </DashboardItem>
                                </Col>
                                <Col className="gutter-row" lg={{span: 6}} sm={{span: 6}} xs={{span: 6}}>
                                    <DashboardItem title="Alpha Hari Ini" height={50}>
                                        <span style={counterStyle}>{attendances.today_alpha}</span>
                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                    </DashboardItem>
                                </Col>
                                <Col className="gutter-row" lg={{span: 6}} sm={{span: 6}} xs={{span: 6}}>
                                    <DashboardItem title="Total Karyawan" height={50}>
                                        <span style={counterStyle}>{attendances.total_employee}</span>
                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                    </DashboardItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col span={24} className="p-1">
                            <DashboardItem title={
                                <div>
                                    Grafik Kehadiran
                                    <Space style={{float:'right'}}>
                                        <RangePicker onChange={(v) =>setSelectedDate(v)} style={{width:'300px', float:'right'}} defaultValue={selectedDate}/>
                                        <Button icon={<ZoomInOutlined />} loading={!loadedBarData} onClick={() => filterPeriodAttendance()} type="primary">
                                            Show
                                        </Button>
                                    </Space>
                                </div>
                            } height={400}>
                            {
                                (loaded) ? 
                                <Bar
                                    data={data}
                                    width={100}
                                    height={320}
                                    options={{ maintainAspectRatio: false }}
                                    />
                                : <Spinner/>
                            }
                            </DashboardItem>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col span={8} className="p-1">
                            <DashboardItem title="Employment Status" height={450}>
                                <Row className="pt-2">
                                    <Col span={24}>
                                        <Doughnut
                                            data={chartStatus}
                                            height={200}
                                            options={{ maintainAspectRatio: false, legend: {display: false} }}
                                            />
                                    </Col>
                                    <Col span={24} className="mt-2">
                                        <Descriptions bordered size="small" layout="horizontal" column={1}>
                                            {
                                                employees.by_status && employees.by_status.map((status, index) => (
                                                    <Descriptions.Item key={'status_' + index} label={status.description}><div style={{width:20,height:10, backgroundColor:backgroundColor[index],border:`1px solid ${borderColor[index]}`, display:'inline-flex'}}></div> {status.total}</Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </DashboardItem>
                        </Col>
                        <Col span={8} className="p-1">
                            <DashboardItem title="Employee by Gender" height={450}>
                                <Row className="pt-2">
                                    <Col span={24}>
                                        <Doughnut
                                            data={chartGender}
                                            height={200}
                                            options={{ maintainAspectRatio: false, legend: {display: false} }}
                                            />
                                    </Col>
                                    <Col span={24} className="mt-2">
                                        <Descriptions bordered size="small" layout="horizontal" column={1}>
                                            {
                                                employees.by_gender && employees.by_gender.map((gender, index) => (
                                                    <Descriptions.Item key={'gender_' + index} label={gender.description}><div style={{width:20,height:10, backgroundColor:backgroundColor[index],border:`1px solid ${borderColor[index]}`, display:'inline-flex'}}></div> {gender.total}</Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </DashboardItem>
                        </Col>
                        <Col span={8} className="p-1">
                            <DashboardItem title="Employee by Ages" height={450}>
                                <Row className="pt-2">
                                    <Col span={24}>
                                        <Doughnut
                                            data={chartAge}
                                            height={200}
                                            options={{ maintainAspectRatio: false, legend: {display: false} }}
                                            />
                                    </Col>
                                    <Col span={24} className="mt-2">
                                        <Descriptions bordered size="small" layout="horizontal" column={1}>
                                            {
                                                employees.by_age && employees.by_age.map((age, index) => (
                                                    <Descriptions.Item key={'age_' + index} label={age.description}><div style={{width:20,height:10, backgroundColor:backgroundColor[index],border:`1px solid ${borderColor[index]}`, display:'inline-flex'}}></div> {age.total}</Descriptions.Item>
                                                ))
                                            }
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </DashboardItem>
                        </Col>
                    </Row>
                </div>
                : <Card><Spinner /></Card>
            }

        </Card>
    );
}

export default Admin;
