import React, { useState, useEffect } from 'react';
import { Card, Button, Input, Select, message, Popconfirm, Row, Col, Descriptions, Typography } from 'antd';
import { useParams } from 'react-router-dom'
import { CloseCircleOutlined, PlusCircleOutlined, CheckCircleOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { DataTable, PageTitle, Spinner } from '../../../components'
import { toIdr, extractNumber } from '../../../utils/currencyHandler'
// import TaxCalculation from '../TaxCalculation'
import PaymentHeader from './PaymentHeader'
import { SentralModel } from '../../../models/SentralModel';
import ConfirmPayment from './ConfirmPayment'
import PaidDetail from './PaidDetail'
import { generateMonth } from '../../../helpers/generateMonth';
import moment from 'moment';

const { Option } = Select
const { Text } = Typography


function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Detail = () => {
    const { employeeCode, periodId } = useParams()
    const forceUpdate = useForceUpdate()
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmPaymentData, setConfirmPaymentData] = useState(null)
    const [allowances, setAllowances] = useState([])
    const [deductions, setDeductions] = useState([])
    const [benefits, setBenefits] = useState([])
    const [lates, setLates] = useState([])
    const [overtimes, setOvertimes] = useState([])
    const [employee, setEmployee] = useState(null)
    const [components, setComponents] = useState([])
    const [saving, setSaving] = useState(false)
    const [schemaId, setSchemaId] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [absentPenalty, setAbsentPenalty] = useState(0)
    const [totalAlpha, setTotalAlpha] = useState(0)
    const [employeeDailyIncentive, setEmployeeDailyIncentive] = useState(0)
    const [employeeWeeklyIncentive, setEmployeeWeeklyIncentive] = useState(0)
    const [dailyIncentive, setDailyIncentive] = useState(0)
    const [weeklyIncentive, setWeeklyIncentive] = useState(0)
    const [totalWorkingDays, setTotalWorkingDays] = useState(0)
    const [totalWorkingWeeks, setTotalWorkingWeeks] = useState(0)
    const [leave, setLeave] = useState({ paid: 0, unpaid: 0 })
    const [paymentId, setPaymentId] = useState(null)
    const [loanBill, setLoanBill] = useState({})
    const [ovtData, setOvtData] = useState([])
    const [ovtAmount, setOvtAmount] = useState(0)

    const fetchComponents = () => {
        SentralModel.list('PayrollComponent').then((res) => {
            setComponents(res.data)
        })
    }

    const fetchPaymentHeader = (employeeCode, periodId) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getEmployeePaymentHeader', { employee_code: employeeCode, period_id: periodId }, 0).then((res) => {
            setEmployee(res.data)
            setLoaded(true)
        })
    }

    const fetchIncentive = () => {
        SentralModel.action('EmployeeIncentive', 'getIncentiveEmp', { employee_code: 'ADM001' }, 0).then((res) => {
            res.data?.daily ? setEmployeeDailyIncentive(res.data?.daily) : setEmployeeDailyIncentive(0)
            res.data?.weekly ? setEmployeeWeeklyIncentive(res.data?.weekly) : setEmployeeWeeklyIncentive(0)
        })
    }

    const fetchSchema = (employeeCode, periodId) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'getSchemaPayment', { employee_code: employeeCode, period_id: periodId }, 0).then((res) => {
            setSchemaId(res.data?.schema?.employee_payroll_schema_id)
            setLates(res.data.late)
            setOvertimes(res.data.overtime)
            setAllowances(res.data?.schema?.allowances)
            setDeductions(res.data?.schema?.deductions)
            setBenefits(res.data?.schema?.benefits)
            setLoaded(true)
            setAbsentPenalty(res.data.absent_penalty)
            setTotalAlpha(res.data.total_alpha)
            setDailyIncentive(employeeDailyIncentive !== 0 ? (employeeDailyIncentive * res.data.total_working_days) : (res.data.daily_incentive * res.data.total_working_days))
            setTotalWorkingDays(res.data.total_working_days)
            setWeeklyIncentive(employeeWeeklyIncentive !== 0 ? (employeeWeeklyIncentive * res.data.total_working_weeks) : (res.data.weekly_incentive * res.data.total_working_weeks))
            setTotalWorkingWeeks(res.data.total_working_weeks)
            setLeave({ paid: res.data.paid_leave, unpaid: res.data.unpaid_leave })
            setPaymentId(res.data.payment_id)

        })
    }

    const fetchLoanBill = () => {
        SentralModel.action("Loan", "getLoanBill", null, null).then((res) => {
            setLoanBill(res.data)
        })
    }

    const fetchOvertimePeriod = (employeeCode, periodId) => {
        SentralModel.action("Overtime", "getOvertimeByPeriod", { employee_code: employeeCode }, periodId).then((res) => {
            const totalAmount = res.data.reduce((partialSum, a) => partialSum + a.total_value, 0)
            setOvtAmount(totalAmount)
            setOvtData(res.data)
        })
    }

    useEffect(() => {
        fetchComponents()
        fetchIncentive()
        fetchSchema(employeeCode, periodId)
        fetchPaymentHeader(employeeCode, periodId)
        fetchLoanBill()
        fetchOvertimePeriod(employeeCode, periodId)

    }, [employeeCode, periodId]);

    const ovtColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align: 'center',
            width: '4%'
        },
        {
            title: 'Date',
            key: 'date',
            render: (row) => (
                moment(row?.from_date).format("DD-MM-YYYY")
            ),
        },
        {
            title: 'Total Overtime',
            key: 'total_overtime',
            render: (row) => (
                toIdr(row?.total_value)
            )
        }
    ]

    const addAllowances = () => {
        let x = allowances;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setAllowances(x)
        forceUpdate()
    }

    const addDeductions = () => {
        let x = deductions;
        x.push({
            component_code: null,
            description: null,
            amount: null,
            type: null
        })
        setDeductions(x)
        forceUpdate()
    }

    const generatePayment = () => {
        setSaving(true)
        let l = [];
        lates.forEach(el => {
            if (el.total > 0 && el.amount > 0) {
                l.push({ total: el.total, amount: el.amount, description: el.description })
            }
        })
        let ov = [];
        ovtData.forEach(el => {
            ov.push({ sub_overtime_id: el.sub_overtime_id, total: el.total_value, description: el.description })
        })
        let all
        if (ovtAmount === 0) {
            all = allowances
        } else {
            all = allowances
            all.push({ amount: ovtAmount, component_code: "OVT", description: "Overtime", type: "OVT" })
        }
        const data = {
            employee_code: employeeCode,
            period_id: periodId,
            schema_id: schemaId,
            lates: l,
            overtimes: ov,
            allowances: all,
            deductions: deductions,
            benefits: benefits,
            total_alpha: totalAlpha,
            absent_penalty: absentPenalty,
            paid_leave: leave.paid,
            unpaid_leave: leave.unpaid,
            total_working_weeks: totalWorkingWeeks,
            weekly_incentive: weeklyIncentive,
            total_working_days: totalWorkingDays,
            daily_incentive: dailyIncentive,
            loan_bill: loanBill,
        }

        SentralModel.action('Payroll', 'generatePayment', data, 0).then(() => {
            message.success("Payment generated")
            setSaving(false)
            fetchSchema(employeeCode, periodId)
            fetchPaymentHeader(employeeCode, periodId)
        })
    }

    const openConfirmModal = () => {
        setConfirmModal(true)
        SentralModel.action('Payroll', 'finalDetail', { employee_code: employeeCode, period_id: periodId }, 0).then((res) => {
            setConfirmPaymentData(res.data)
        })
    }

    const closeConfirmModal = () => {
        setConfirmModal(false)
        setConfirmPaymentData(null)
    }

    const resetPayment = () => {
        let ov = [];
        ovtData.forEach(el => {
            ov.push({ sub_overtime_id: el.sub_overtime_id, total: el.total_value, description: el.description })
        })

        let payload = {
            employee_code: employeeCode,
            period_id: periodId,
            loan_bill: loanBill,
            overtimes: ov,
        }

        SentralModel.action('Payroll', 'resetPayment', payload, 0).then(() => {
            message.success("Payment Reset")
            setSaving(false)
            fetchSchema(employeeCode, periodId)
            fetchPaymentHeader(employeeCode, periodId)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Payment" breadcrumbs={[['Payroll Payment', '/payroll/payment'], ['Edit']]}></PageTitle>
                {
                    (loaded && employee) ?
                        <div>
                            <PaymentHeader employee={employee} />
                            {
                                (employee.payment !== 'PAID') ?
                                    <div>
                                        <div className="full-width mt-2 mb-2 text-right">
                                            {
                                                (employee.payment === 'GENERATED') ?
                                                    <Popconfirm title="Reset generated payment...?" okText="Yes" cancelText="No" onConfirm={() => resetPayment()}>
                                                        <Button size="middle" type="primary" icon={<UndoOutlined />}>Reset</Button> &nbsp;
                                                    </Popconfirm>
                                                    : null
                                            }
                                            <Button disabled={(employee.payment !== 'GENERATED')} size="middle" onClick={() => openConfirmModal()} type="danger" icon={<CheckCircleOutlined />}>Confirm Payment</Button>
                                        </div>

                                        <table className="table-default table-collapse" style={{ width: '100%', marginBottom: 15 }}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Allowances</th>
                                                    <th className="text-center">Deductions</th>
                                                    <th className="text-center">Benefits</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: "top" }}>
                                                        <table className="table-noborder" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '60%' }}>Component</th>
                                                                    <th>Value</th>
                                                                    <th style={{ width: '5%' }}>
                                                                        {
                                                                            (!employee.payment) ?
                                                                                <Button type={allowances ? "primary" : "ghost"} onClick={allowances ? addAllowances : null} icon={<PlusCircleOutlined />} />
                                                                                : null
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    allowances && allowances.filter((el) => el.component_code !== 'OVT').map((alw, key) => (
                                                                        <tr key={'allowance_item_' + key}>
                                                                            <td>
                                                                                <Select value={alw.component_code} style={{ width: '100%' }} placeholder="select component" onChange={(v, e) => {
                                                                                    let x = allowances
                                                                                    x[key].component_code = v
                                                                                    x[key].description = e.children
                                                                                    let f = components.find(a => a.component_code === v)
                                                                                    x[key].type = f.type
                                                                                    setAllowances(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category === "ALLOWANCE").map((el, key2) => (
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(alw.amount)} onChange={(v) => {
                                                                                    let x = allowances
                                                                                    x[key].amount = extractNumber(v.target.value)
                                                                                    setAllowances(x)
                                                                                    forceUpdate()
                                                                                }} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={() => {
                                                                                            let x = allowances
                                                                                            x.splice(key, 1);
                                                                                            setAllowances(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />} />
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    overtimes && overtimes.map((ovt, key) => (
                                                                        (ovt.total > 0 && ovt.amount > 0) ?
                                                                            <tr key={'late_item_' + key}>
                                                                                <td>
                                                                                    <Input readOnly value={'Overtime : ' + ovt.description + '(' + ovt.total + ')'} />
                                                                                </td>
                                                                                <td>
                                                                                    <Input value={toIdr(ovt.amount)} readOnly />
                                                                                </td>
                                                                                <td className="text-center"></td>
                                                                            </tr>
                                                                            : null
                                                                    ))
                                                                }
                                                                {
                                                                    (dailyIncentive > 0) ?
                                                                        <tr>
                                                                            <td>
                                                                                <Input readOnly value={'Daily Incentive(' + totalWorkingDays + ')'} />
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(dailyIncentive)} readOnly />
                                                                            </td>
                                                                            <td className="text-center"></td>
                                                                        </tr>
                                                                        : null
                                                                }
                                                                {
                                                                    (weeklyIncentive > 0) ?
                                                                        <tr>
                                                                            <td>
                                                                                <Input readOnly value={'Weekly Incentive(' + totalWorkingWeeks + ')'} />
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(weeklyIncentive)} readOnly />
                                                                            </td>
                                                                            <td className="text-center"></td>
                                                                        </tr>
                                                                        : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{ verticalAlign: "top" }}>
                                                        <table className="table-noborder" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '60%' }}>Component</th>
                                                                    <th>Value</th>
                                                                    <th style={{ width: '5%' }}>
                                                                        {
                                                                            (!employee.payment) ?
                                                                                <Button type={deductions ? "primary" : "ghost"} onClick={deductions ? addDeductions : null} icon={<PlusCircleOutlined />} />
                                                                                : null
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    deductions && deductions.filter((el) => el.component_code !== "LOANPAYMENT").map((ded, key) => (
                                                                        <tr key={'deduction_item_' + key}>
                                                                            <td>
                                                                                <Select value={ded.component_code} style={{ width: '100%' }} placeholder="select component" onChange={(v, e) => {
                                                                                    let x = deductions
                                                                                    x[key].component_code = v
                                                                                    x[key].description = e.children
                                                                                    setDeductions(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category === "DEDUCTION").map((el, key2) => (
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(ded.amount)} onChange={(v) => {
                                                                                    let x = deductions
                                                                                    x[key].amount = extractNumber(v.target.value)
                                                                                    setDeductions(x)
                                                                                    forceUpdate()
                                                                                }} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={() => {
                                                                                            let x = deductions
                                                                                            x.splice(key, 1);
                                                                                            setDeductions(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />} />
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    lates.map((late, key) => (
                                                                        (late.total > 0 && late.amount > 0) ?
                                                                            <tr key={'late_item_' + key}>
                                                                                <td>
                                                                                    <Input readOnly value={'Late : ' + late.description + ' (' + late.total + ')'} />
                                                                                </td>
                                                                                <td>
                                                                                    <Input value={toIdr(late.amount * late.total)} readOnly />
                                                                                </td>
                                                                                <td className="text-center"></td>
                                                                            </tr>
                                                                            : null
                                                                    ))
                                                                }
                                                                {
                                                                    (absentPenalty) ?
                                                                        <tr key={'absent_penalty_item_'}>
                                                                            <td>
                                                                                <Input readOnly value={'Alpha & Unpaid Leave (' + totalAlpha + ')'} />
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(absentPenalty)} readOnly />
                                                                            </td>
                                                                            <td className="text-center"></td>
                                                                        </tr>
                                                                        : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{ verticalAlign: "top" }}>
                                                        <table className="table-noborder" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '60%' }}>Component</th>
                                                                    <th>Value</th>
                                                                    <th style={{ width: '5%' }}>
                                                                        {
                                                                            (!employee.payment) ?
                                                                                <Button type={benefits ? "primary" : "ghost"} onClick={deductions ? addDeductions : null} icon={<PlusCircleOutlined />} />
                                                                                : null
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    benefits && benefits.map((ded, key) => (
                                                                        <tr key={'benefit_item_' + key}>
                                                                            <td>
                                                                                <Select value={ded.component_code} style={{ width: '100%' }} placeholder="select component" onChange={(v, e) => {
                                                                                    let x = benefits
                                                                                    x[key].component_code = v
                                                                                    x[key].description = e.children
                                                                                    setBenefits(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category === "BENEFIT").map((el, key2) => (
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(ded.amount)} onChange={(v) => {
                                                                                    let x = benefits
                                                                                    x[key].amount = extractNumber(v.target.value)
                                                                                    setBenefits(x)
                                                                                    forceUpdate()
                                                                                }} />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={() => {
                                                                                            let x = benefits
                                                                                            x.splice(key, 1);
                                                                                            setBenefits(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />} />
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* <Row justify='space-between'>
                                            <Col span={11}>
                                                <Card title="Loan Bill">
                                                    <Descriptions className="mb-3" size="middle" column={2} bordered>
                                                        <Descriptions.Item span={3} label={<div className="text-center"><Text strong>Payment Bill</Text></div>}>
                                                            <div className="text-center">{toIdr(loanBill.total_payment)}</div>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<div className="text-center"><Text strong>Period</Text></div>}>
                                                            <div className="text-center">{generateMonth(loanBill.month_period)}</div>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Col>
                                            <Col span={11}>
                                                <Card title="Overtime">
                                                    <div style={{ marginBottom: 10 }}>
                                                        <DataTable dataSource={ovtData} columns={ovtColumns} bordered pagination={false} search={false} size="small" />
                                                    </div>
                                                    <Descriptions bordered>
                                                        <Descriptions.Item span={3} label="Total Overtime Value">
                                                            {toIdr(ovtData.reduce((partialSum, a) => partialSum + a.total_value, 0))}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Col>
                                        </Row> */}

                                        <div className="full-width text-right mt-2 mb-2">
                                            <Button disabled={employee.payment === 'GENERATED' || employee.payment === 'PAID'} loading={saving} type="primary" onClick={generatePayment} icon={<SaveOutlined />} size="large">Generate Payment</Button>
                                        </div>
                                    </div>
                                    : <PaidDetail data={{ allowances: allowances, deductions: deductions }} />
                            }
                            {/* {
                                (employee.payment) ?
                                    <TaxCalculation data={{allowances:allowances, deductions:deductions, lates:lates, overtimes:overtimes}}/>
                                : null
                            } */}
                        </div>
                        :
                        <Spinner />
                }
                <br />
            </Card>

            <ConfirmPayment data={confirmPaymentData} paymentId={paymentId} employee={employee} setModal={(v) => closeConfirmModal(v)} showModal={confirmModal} />
        </div>

    );
}

export default Detail;
