import { useEffect, useState, useRef, useCallback } from 'react'
import { Row, Col, Typography, Button, Form, Select, Input, message } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import { Spinner, FormModal } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import { distance } from '../../../helpers/distance';
import authData from '../../../helpers/authData'
import moment from 'moment'
import Webcam from "react-webcam";

const { Title } = Typography
const { Option } = Select

const Absence = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState(null)
    const [modalTitle, setModalTitle] = useState(null)
    const [saving, setSaving] = useState(false)
    const [dateTime, setDateTime] = useState(moment().format('DD MMMM YYYY, HH:mm:ss'))
    const [divisions, setDivision] = useState({})
    const [attendances, setAttendance] = useState([])
    const [workLocation, setWorkLocation] = useState([])
    const [location, setLocation] = useState(false)
    const [locationAddress, setLocationAddress] = useState(null)
    const [urlImage, setUrlImage] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [isTrueRadius, setIsTrueRadius] = useState(false)
    const [clockStatus, setClockStatus] = useState("In")
    const webcamRef = useRef(null);

    const fetchDivision = () => {
        setLoaded(false)
        SentralModel.list('Division', { filter: [["division_id", authData.division_id]] }).then(res => {
            setLoaded(true);
            setDivision(res.data[0]);
        })
    }

    const fetchAttendance = () => {
        SentralModel.action('Attendance', 'getTodayAttendance', { attendance_id: authData.attendance_id }, 0).then(res => {
            setAttendance(res.data);
        })
    }

    const fetchEmployeeArea = (v) => {
        SentralModel.action("Area", "getEmployeeAttendanceArea", null, null).then((res) => {
            const employeeArea = res.data.employee_area.length
            const divisionArea = res.data.division_area.length
            // let radius = 0;

            let radius = false;

            if (employeeArea > 0) {
                res.data.employee_area.forEach((el) => {
                    const dis = distance(el.area.latitude, el.area.longitude, v.lat, v.long)
                    if (dis <= el.area.radius) {
                        // radius++
                        radius = true;
                    }
                })
            }
            if (divisionArea > 0) {
                res.data.division_area.forEach((el) => {
                    const dis = distance(el.area.latitude, el.area.longitude, v.lat, v.long)
                    if (dis <= el.area.radius) {
                        // radius++
                        radius = true;
                    }
                })
            }

            // if (radius !== 0) {
            if (radius === true) {
                setIsTrueRadius(true)
            } else {
                setIsTrueRadius(false)
            }
        })
    }

    useEffect(() => {
        fetchDivision();
        fetchAttendance();

        const getUserLocation = (pos) => {
            let crd = pos.coords
            if (!locationAddress) {
                let payload = {
                    lat: crd.latitude,
                    long: crd.longitude,
                }

                fetchEmployeeArea(payload);
                SentralModel.action('Area', 'locationAddress', payload, 0).then((res) => {
                    setLocationAddress(res.data)
                })
            }
            localStorage.setItem('userLocation', JSON.stringify({ lat: crd.latitude, long: crd.longitude }))
        }

        if (navigator.geolocation) {
            if (navigator.permissions) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then(function (result) {
                        if (result.state === "granted") {
                            navigator.geolocation.getCurrentPosition(getUserLocation);
                            setLocation(true)
                        } else if (result.state === "prompt") {
                            navigator.geolocation.getCurrentPosition(getUserLocation);
                            setLocation(false)
                        }
                    });
            } else {
                navigator.geolocation.getCurrentPosition(getUserLocation);
                setLocation(false)
            }
        } else {
            alert("Sorry Not available!");
        }

        const interval = setInterval(() => {
            setDateTime(moment().format('DD MMMM YYYY, HH:mm:ss'));
        }, 1000);
        return () => clearInterval(interval);

    }, [locationAddress])

    const addAbsence = (v) => {
        setClockStatus(v)
        setModalTitle(v === 'In' ? 'Clock In' : 'Clock Out')
        setDefaultValues({
            work_location: 'WFH',
            information: v,
            remarks: null
        })
        setFormModal(true)
        setWorkLocation('WFH');
    }

    const absence = (v) => {
        setSaving(true)

        let attendance = {
            attendance_id: authData.attendance_id,
            source: 'Web',
            attendance_date: moment().format('YYYY-MM-DD'),
            attendance_time: moment().format('HH:mm:ss'),
            information: workLocation,
            remarks: v.remarks,
            photo: photo,
            division_id: authData.division_id,
            clock_status: clockStatus
        }
        
        console.log(attendance)

        let userLocation = localStorage.getItem('userLocation')
        if (userLocation) {
            attendance.latitude = JSON.parse(userLocation).lat
            attendance.longitude = JSON.parse(userLocation).long
            attendance.location = locationAddress
        }

        if (!photo) {
            setSaving(false)
            message.error('Please capture your photo', 2);
        } else {
            if (location) {
                if (workLocation === "WFO" && !isTrueRadius) {
                    setSaving(false)
                    message.error('Location Outside Radius', 2)
                } else {
                    SentralModel.action('Attendance', 'saveAbsence', attendance, 0).then((res) => {
                        fetchAttendance()
                        setFormModal(false)
                        setSaving(false)
                        setPhoto(null)
                        setUrlImage(null)
                        message.success('Absence Success', 2)
                    }).catch((err) => {
                        setSaving(false)
                        message.warning('Absence Failed')
                    })
                }
            } else {
                setSaving(false)
                message.error('Please activate your location', 2)
            }
        }
    }

    const changeLocation = e => {
        setWorkLocation(e)
    }

    const videoConstraints = {
        width: 500,
        height: 300,
        facingMode: "user"
    };

    const capturePhoto = useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setUrlImage(imageSrc);
        const file = DataURIToBlob(imageSrc);

        SentralModel.upload('attendance', file, 'image.jpg').then(res => {
            setPhoto(res.message);
        })
    }, [webcamRef]);

    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    return (
        <div>
            {
                loaded ?
                    <Row justify='center'>
                        <Col xs={18} sm={20} md={24} style={{ borderRadius: 10, border: '2px solid #eaeaea' }}>
                            <div className="full-width" style={{ borderBottom: '1px solid #ededed', padding: 15 }}>
                                <Row>
                                    <Col span={12}>
                                        <Title level={4}>
                                            Office Hours
                                        </Title>
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ float: 'right', fontSize: 20 }}>
                                            {dateTime}
                                        </span>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ fontSize: 40, textAlign: 'center', padding: 30 }}>
                                {divisions ? moment(divisions.daily_in, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(divisions.daily_out, 'HH:mm:ss').format('HH:mm') : ''}
                            </div>

                            <div style={{ fontSize: 16, textAlign: 'center', padding: 10 }}>
                                {locationAddress ? locationAddress : null}
                            </div>

                            <div className="full-width" style={{ borderTop: '1px solid #ededed', padding: 20 }}>
                                <Row justify="space-around">
                                    <Col span={7} className="text-center">
                                        <Button block type="primary" size="large" icon={<RightCircleOutlined />} onClick={() => addAbsence('In')} disabled={attendances[0] !== '-'}>Check In</Button>
                                        {
                                            attendances &&
                                            <span style={{ fontSize: 20 }}>
                                                {attendances ? attendances[0] : '-'}
                                            </span>
                                        }
                                    </Col>
                                    <Col span={7} className="text-center">
                                        <Button block type="danger" size="large" icon={<LeftCircleOutlined />} onClick={() => addAbsence('Out')}>Check Out</Button>
                                        {
                                            attendances &&
                                            <span style={{ fontSize: 20 }}>
                                                {attendances ? attendances[1] : '-'}
                                            </span>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row >
                    : <Spinner />
            }

            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => (absence(v))} width={700} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving} disabled={false}>
                        <Form.Item name="information" hidden>
                            <Input placeholder="Information" />
                        </Form.Item>
                        <Form.Item label="Work Location" name="work_location" rules={[{ required: true }]}>
                            <Select placeholder="Select category" style={{ width: "100%" }} onChange={changeLocation}>
                                <Option value="WFH">Work From Home</Option>
                                <Option value="WFO">Work From Office</Option>
                            </Select>
                        </Form.Item>

                        <span>Photo</span>
                        {
                            !urlImage ?
                                <div className='mb-3'>
                                    <Webcam
                                        ref={webcamRef}
                                        audio={false}
                                        height={300}
                                        screenshotFormat="image/jpeg"
                                        width={'100%'}
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                                :
                                <div style={{ marginTop: 15, marginBottom: 30 }}>
                                    <img src={urlImage} alt="Screenshot" />
                                </div>
                        }

                        <Row justify="space-around" className='mb-3'>
                            <Col span={10} className="text-center">
                                <Button block type="primary" onClick={capturePhoto}>Capture</Button>
                            </Col>
                            <Col span={10} className="text-center">
                                <Button block type='danger' onClick={() => setUrlImage(null)}>Reset</Button>
                            </Col>
                        </Row>
                        <Form.Item label="Remarks" name="remarks">
                            <Input.TextArea placeholder="Remarks" />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div >
    );
}

export default Absence;
