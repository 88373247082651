import React, { useState, useEffect } from 'react';
import { Card, Select, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { Option } = Select

const Index = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [employeeData, setEmployeeData] = useState([])
    const [divisions, setDivisions] = useState([])
    const [employeeStatus, setEmployeeStatus] = useState([])
    const [filter, setFilter] = useState({})

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'medicalHistory', ft, 0).then((res) => {
            setEmployeeData(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ES']] }, 0).then((res) => {
            setEmployeeStatus(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchEmployeeStatus()
        fetchReport(null)
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ? v.division_id : '',
            status: v.status ? v.status : [],
        }
        fetchReport(ft)
        setFilter(ft)
        setShowFilter(false)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/medicalHistory?division_id=${filter.division_id}&status=${filter.status}`)
    }

    const generateRows = () => {
        let rows = [];
        employeeData.forEach((el, key) => {
            el.medicals.forEach((el2, index) => {
                let x = {};
                if (index === 0) {
                    x = {
                        name: el.name,
                        title: el2.title,
                        from_period: el2.from_period,
                        to_period: el2.to_period,
                        quantity: el2.quantity,
                        description: el2.description,
                        is_hospitalization: el2.is_hospitalization,
                        attachment: el2.attachment,
                        first_flag: 1
                    }
                } else {
                    x = {
                        title: el2.title,
                        from_period: el2.from_period,
                        to_period: el2.to_period,
                        quantity: el2.quantity,
                        description: el2.description,
                        is_hospitalization: el2.is_hospitalization,
                        attachment: el2.attachment,
                        first_flag: 0
                    }
                }

                rows.push(x)
            })
        })
        return rows;
    }

    const employeeColumn = [
        // {
        //     title: 'No',
        //     dataIndex: 'no',
        //     key: 'name',
        //     className: 'text-top',
        //     fixed: 'left',
        // },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            className: 'text-top',
            fixed: 'left',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Period',
            render: (row) => (
                moment(row.from_period).format('DD MMM YYYY') + ' - ' + moment(row.to_period).format('DD MMM YYYY')
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Hospitalized',
            dataIndex: 'is_hospitalization',
            key: 'is_hospitalization',
            render: (text) => (
                <div className="text-center">
                    {text === '1' ? 'Yes' : 'No'}
                </div>
            )
        },
        {
            title: 'Attachment',
            dataIndex: 'attachment',
            key: 'attachment',
            render: (text) => (
                <div className="text-center">
                    {
                        text !== null ?
                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + text}>
                                Show Attachment
                            </a>
                            : '-'
                    }
                </div>
            )
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Medical History Report" breadcrumbs={[['Medical History Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>

                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable size="small" columns={employeeColumn} dataSource={generateRows()} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select style={{ width: '100%' }} placeholder="Select Division" allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="status" label="Employee Status">
                        <Select style={{ width: '100%' }} placeholder="Employee Category" mode="multiple" allowClear>
                            {
                                employeeStatus.map((el, key) => (
                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Index;
