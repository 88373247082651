import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../../models/SentralModel';
import {toFullDate} from '../../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {stockId} = useParams()
    const [stock, setStock] = useState(null)

    const fetchStock = (id) => {
        SentralModel.get('AssetStock', {}, id).then((res)=>{
            setStock(res.data)
        })
    }

    useEffect(() => {
        fetchStock(stockId)
    }, [stockId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/asset-stock/' + stockId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Stock Detail" breadcrumbs={[['Asset'],['Stock', '/asset/stock'], ['Detail']]}></PageTitle>
            </div>
            {
                (stock) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <Card bordered className="mb-4" title={<div className="full-width"><Text strong style={{float:'left'}}>Item</Text></div>}>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th style={{width:'15%'}}>Item Name</th><td>{stock.item.item_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Quantity</th><td>{stock.quantity}</td>
                                        </tr>
                                        <tr>
                                            <th>UoM</th><td>{stock.item.uom}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered title={<div className="full-width"><Text strong style={{float:'left'}}>History</Text></div> }>
                        
                        <div>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{width:'5%'}}>No</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">Quantity</th>
                                        <th className="text-center">Serial Number</th>
                                        <th className="text-center">Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        stock.histories.map((el, key) => (
                                            <tr key={key}>
                                                <td className="text-center">{key+1}</td>
                                                <td className="text-center">{toFullDate(el.date)}</td>
                                                <td className="text-center">{el.quantity}</td>
                                                <td>{el.serial_number ?? '-'}</td>
                                                <td className="text-center">{el.information}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
