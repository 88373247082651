import React, { useEffect, useState } from 'react';
import { Card, Space, Tag, Button, Select, Form, Drawer } from 'antd'
import { CustomButton, PageTitle, Spinner, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'
import { ZoomInOutlined, FilterOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons'

const { Option } = Select

const Vacancy = () => {
    const [vacancies, setVacancies] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [divisions, setDivisions] = useState([]);

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const fetchReportVacancies = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'recruitmentVacancy', ft, 0).then((res) => {
            setVacancies(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchReportVacancies()
        fetchDivision()
    }, []);

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/vacancy/' + selectedDivision)
    }

    const exportExcel = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/vacancyExcel/${selectedDivision}`);
    }

    const dataSource = [
        ...vacancies.map(el => {
            return {
                id: el.rec_vacancy_id,
                division: el.division !== null ? el.division.division_name : '-',
                position: el?.position?.position_name,
                subject: el.subject,
                from_date: el.from_date,
                to_date: el.to_date,
                work_location: el.location?.province_name,
                quota: el.quota,
                total_applicant: el.total_applicant,
                status: el.to_date > moment().format('YYYY-MM-DD') ? 'Active' : 'Expired'
            }
        })
    ];

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id,
        }
        fetchReportVacancies(ft)
        setShowFilter(false)
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: '15%',
    },
    {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        width: '15%'
    },
    {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: '20%'
    },
    {
        title: 'From Date',
        dataIndex: 'from_date',
        key: 'from_date',
        width: '10%',
        render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'To Date',
        dataIndex: 'to_date',
        key: 'to_date',
        width: '10%',
        render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'Work Location',
        dataIndex: 'work_location',
        key: 'work_location',
        width: '15%',
        align: 'center'
    },
    {
        title: 'Quota',
        dataIndex: 'quota',
        key: 'quota',
        align: 'center',
        width: '5%',
        render: (text) => (
            <div className="text-center">
                {text}
            </div>
        )
    },
    {
        title: 'Applicant',
        dataIndex: 'total_applicant',
        key: 'total_applicant',
        width: '5%',
        render: (text) => (
            <div className="text-center">
                {text}
            </div>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '5%',
        render: (text) => (
            <div className="text-center">
                <Tag color={text === 'Active' ? "green" : "red"}>{text}</Tag>
            </div>
        )
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <CustomButton icon={<ZoomInOutlined />} link to={"/report/vacancy/detail/" + row.id} />
                </Space>
            </div>
        )
    },
    ];

    return (
        <div>
            <Card>
                <PageTitle title="Report Vacancy" breadcrumbs={[['Report Vacancy']]}></PageTitle>
                <div className="text-right mb-2">
                    <Space>
                        <Button type="primary" onClick={() => exportReport()} icon={<FilePdfOutlined />}>Print PDF</Button>
                        <Button type="primary" onClick={() => exportExcel()} icon={<FileExcelOutlined />}>Print Excel</Button>
                        <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                    </Space>
                </div>

                {
                    (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                        : <Spinner />
                }

                <Drawer
                    title="Filter Report"
                    width={400}
                    placement="right"
                    closable={false}
                    onClose={() => setShowFilter(false)}
                    visible={showFilter}>
                    <Form layout="vertical" onFinish={filterData}>
                        <Form.Item name="division_id" label="Division">
                            <Select
                                style={{ width: '100%' }}
                                onChange={
                                    (v) => {
                                        setSelectedDivision(v)
                                    }
                                } placeholder="Select Division" allowClear>
                                {
                                    divisions.map((el, key) => (
                                        <Option
                                            key={key}
                                            value={el.division_id}
                                        >{el.division_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <div className="text-right">
                                <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Drawer>
            </Card>
        </div>
    );
}

export default Vacancy;
