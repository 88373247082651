import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Tooltip, Button } from 'antd';
import DashboardItem from './DashboardItem'
import { BasicModal, DataTable, PageTitle, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import { SearchOutlined } from '@ant-design/icons'

const Employee = () => {
    const [loaded, setLoaded] = useState(true);
    const [loadedGender, setLoadedGender] = useState(true);
    const [loadedDivision, setLoadedDivision] = useState(true);
    const [employee, setEmployee] = useState([]);
    const [employeeGender, setEmployeeGender] = useState([]);
    const [employeeDivision, setEmployeeDivision] = useState([]);
    const [detail, setDetail] = useState([]);
    const [loadedDetail, setLoadedDetail] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [detailTitle, setDetailTitle] = useState(null);
    const [showDivision, setShowDivision] = useState(false);

    const fetchAllEmployee = () => {
        setLoaded(false)
        SentralModel.action('Dashboard', 'allEmployee', {}, 0).then((res) => {
            setEmployee(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployeeGender = () => {
        setLoadedGender(false)
        SentralModel.action('Dashboard', 'employeeByGender', {}, 0).then((res) => {
            setEmployeeGender(res.data)
            setLoadedGender(true)
        })
    }

    const fetchEmployeeDivision = () => {
        setLoadedDivision(false)
        SentralModel.action('Dashboard', 'employeeByDivision', {}, 0).then((res) => {
            setEmployeeDivision(res.data)
            setLoadedDivision(true)
        })
    }

    useEffect(() => {
        fetchAllEmployee();
        fetchEmployeeGender();
        fetchEmployeeDivision();
    }, []);

    const getDetail = (divisionId, divisionName) => {
        setDetailModal(true);
        setDetailTitle(divisionName ?? 'All Employee');
        setShowDivision(divisionId === 0 ? true : false);

        setLoadedDetail(false);
        SentralModel.action('Dashboard', 'employeeByDivisionList', {division_id: divisionId}, 0).then((res) => {
            setDetail(res.data);
            setLoadedDetail(true);
        })
    }

    const getDetailGender = (detail_code, description) => {
        setDetailModal(true);
        setDetailTitle(description);
        setShowDivision(true);

        setLoadedDetail(false);
        SentralModel.action('Dashboard', 'employeeByGenderList', {gender: detail_code}, 0).then((res) => {
            setDetail(res.data);
            setLoadedDetail(true);
        })
    }

    const counterStyle = {
        fontSize: '50px',
        color: '#000',
        fontWeight: 'bold'
    }

    const detailColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%'
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '40%'
        },
        {
            title: 'Status',
            dataIndex: 'employee_status',
            key: 'employee_status',
            width: '15%'
        }
    ];

    const detailColumn2 = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '55%'
        },
        {
            title: 'Status',
            dataIndex: 'employee_status',
            key: 'employee_status',
            width: '40%'
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Employee" breadcrumbs={[['Employee']]}></PageTitle>
                <div>
                    <Row justify="space-between">
                        {
                            loaded ?
                                <Col span={8} className="p-1">
                                    <Row className="mb-3">
                                        {
                                            <Col span={24} className="p-1">
                                                <DashboardItem title="All Employee">
                                                    <Row justify="space-between" align="middle">
                                                        <Col>
                                                            <span style={counterStyle}>{employee.total}</span>
                                                            <span style={{fontSize:'15px'}}> Karyawan</span>
                                                            </Col>
                                                        <Col>
                                                            <Tooltip title="Detail employee">
                                                                <Button shape="circle" icon={<SearchOutlined style={{color:'#808080'}} />} 
                                                                    onClick={() => getDetail(0, null)} 
                                                                    style={{ backgroundColor: 'rgba(0, 255, 0, 0.3)', borderColor: '#2ecc71', borderWidth: 1 }} 
                                                                />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </DashboardItem>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            : <Spinner />
                        }
                        {
                            loadedGender ?
                                <Col span={16} className="p-1">
                                    <Row className="mb-3">
                                        {
                                            employeeGender.map((el, index) => (
                                                <Col key={'key_' + index} span={12} className="p-1">
                                                    <DashboardItem title={el.description}>
                                                        <Row justify="space-between" align="middle">
                                                            <Col>
                                                                <span style={counterStyle}>{el.total}</span>
                                                                <span style={{fontSize:'15px'}}> Karyawan</span>
                                                            </Col>
                                                            <Col>
                                                                <Tooltip title="Detail employee">
                                                                    <Button shape="circle" icon={<SearchOutlined style={{color:'#808080'}} />} 
                                                                        onClick={() => getDetailGender(el.detail_code, el.description)} 
                                                                        style={{ backgroundColor: 'rgba(255, 255, 0, 0.3)', borderColor: '#f1c40f', borderWidth: 1 }} 
                                                                    />
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    </DashboardItem>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            : <Spinner />
                        }
                    </Row>
                    <Row>
                        {
                            loadedDivision ?
                                <Col span={24} className="p-1">
                                    <DashboardItem title="Employee by Division">
                                        <Row className="mb-3">
                                            {
                                                employeeDivision.map((el, index) => (
                                                    <Col key={'key_' + index} span={8} className="p-1">
                                                        <DashboardItem title={el.division_name} height={70}>
                                                            <Row justify="space-between" align="middle">
                                                                <Col>
                                                                    <span style={counterStyle}>{el.total}</span>
                                                                    <span style={{fontSize:'15px'}}> Karyawan</span>
                                                                </Col>
                                                                <Col>
                                                                    <Tooltip title="Detail employee">
                                                                        <Button shape="circle" icon={<SearchOutlined style={{color:'#808080'}} />} 
                                                                            onClick={() => getDetail(el.division_id, el.division_name)} 
                                                                            style={{ backgroundColor: 'rgba(45, 200, 255, 0.3)', borderColor: '#4ca3ff', borderWidth: 1 }} 
                                                                        />
                                                                    </Tooltip>
                                                                </Col>
                                                            </Row>
                                                        </DashboardItem>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </DashboardItem>
                                </Col>
                            : <Spinner />
                        }
                    </Row>
                </div>
            </Card>

            <BasicModal width={1000} onCloseModal={() => setDetailModal(false)} showModal={detailModal} onCancel={() => setDetailModal(false)} title={detailTitle}>
                {
                    loadedDetail ?
                        <DataTable bordered dataSource={detail} columns={showDivision ? detailColumn : detailColumn2} size="small"/>
                    : <Spinner />
                }
            </BasicModal>
        </div>
    );
}

export default Employee;