import React, {useEffect, useState} from 'react';
import {Card, Empty, Tabs} from 'antd'
import { PageTitle, Spinner} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import authData from '../../../helpers/authData'

const {TabPane} = Tabs;

const RankingEvaluator = () => {
    const [appraisals, setAppraisals] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [activeYear, setActiveYear] = useState('')

    const fetchAppraisals = () =>{
        setLoaded(false)
        SentralModel.action('Appraisal', 'getRankingEvaluator', {evaluator_code : authData.employee_code}).then(res=>{
            setAppraisals(res.data)
            setActiveYear(res.data.year)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAppraisals()
    }, []);
    
    return (
        <div>
            <Card className="content-container">
                <PageTitle title={'Ranking Evaluator ' + activeYear} breadcrumbs={[['Appraisal','/management/appraisal'],['Ranking Evaluator']]}></PageTitle>
                <Card 
                    // title={
                    // <div className="full-width">
                    //     <Text strong style={{float:'left'}}>Ranking</Text>
                    // </div> }
                >
                        <div>
                            {
                                (loaded) ?
                                <div>
                                    <Tabs defaultActiveKey="appraisal" type="card">
                                        <TabPane tab={<span>Best Employee</span>} key="best">
                                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th width='10%'>Rank</th>
                                                        <th width='30%'>Employee Name</th>
                                                        <th width='40%'>Division Name</th>
                                                        <th width='20%'>KPI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (appraisals.best.length !== 0) ?
                                                            appraisals.best.map((el, i)=>(
                                                                <tr key={i}>
                                                                    <td style={{verticalAlign:'top', textAlign:'center'}}>{i + 1}</td>
                                                                    <td style={{verticalAlign:'top'}}>{el.name}</td>
                                                                    <td style={{verticalAlign:'top'}}>{el.division_name ?? ''}</td>
                                                                    <td className="text-center">{el.kpi_value ?? 0}</td>
                                                                </tr>
                                                            ))
                                                        : <tr><td colSpan={4}><Empty/></td></tr>
                                                    }
                                                </tbody>

                                            </table>
                                        </TabPane>

                                        <TabPane tab={<span>Rookie</span>} key="rookie">
                                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th width='10%'>Rank</th>
                                                        <th width='30%'>Employee Name</th>
                                                        <th width='40%'>Division Name</th>
                                                        <th width='20%'>KPI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (appraisals.rookie.length !== 0) ?
                                                            appraisals.rookie.map((el, i)=>(
                                                                <tr key={i}>
                                                                    <td style={{verticalAlign:'top', textAlign:'center'}}>{i + 1}</td>
                                                                    <td style={{verticalAlign:'top'}}>{el.name}</td>
                                                                    <td style={{verticalAlign:'top'}}>{el.division_name ?? ''}</td>
                                                                    <td className="text-center">{el.kpi_value ?? 0}</td>
                                                                </tr>
                                                            ))
                                                        : <tr><td colSpan={4}><Empty/></td></tr>
                                                    }
                                                </tbody>

                                            </table>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                :
                                <Spinner/>
                            }
                        </div>
                        
                </Card>
            </Card>
        </div>
    );
}

export default RankingEvaluator;
