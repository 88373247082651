import React, {useEffect, useState} from 'react';
import {Card, Form, Typography, Row, Col, Input, Descriptions, message} from 'antd'
import {PageTitle, Spinner, SubmitButton} from '../../../components'
import {useParams} from 'react-router-dom';
import {SentralModel} from '../../../models/SentralModel'
import { toFullDate } from '../../../utils/dateHandler';
import authData from '../../../helpers/authData'
import {useHistory} from 'react-router-dom'
import moment from 'moment'

const {Text} = Typography
const {TextArea} = Input;

const Evaluation = () => {
    const {trainingId} = useParams();
    const [form] = Form.useForm();
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [training, setTraining] = useState([])
    const [trainingParticipant, setTrainingParticipant] = useState(null)
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);

    const fetchTraining = (trainingId) => {
        setLoaded(false)
        SentralModel.get('Training', {}, trainingId).then(res => {
            setTraining(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchQuestion = () => {
        SentralModel.list('TrainingEvaluationQuestion', {filter: [['is_active', 1]]}).then((res) => {
            setLoaded(false);
            setQuestions(res.data);

            const result = [];
            const map = new Map();
            for (const question of res.data) {
                if (!map.has(question.category)) {
                    map.set(question.category, true);
                    result.push(
                        question.category
                    );
                }
            }
            setCategories(result);

        }).finally(() => {
            setLoaded(true);
        })
    }

    const fetchParticipant = (trainingId) => {
        SentralModel.action('Training', 'getTrainingParticipant', { training_id: trainingId, employee_code: authData.employee_code }, 0).then((res) => {
            setTrainingParticipant(res.data);
        });
    }

    useEffect(() => {
        fetchTraining(trainingId);
        fetchQuestion();
        fetchParticipant(trainingId);
    }, [trainingId]);

    const saveEvaluation = (v) => {
        setSaving(true);

        let data = {
            training_id: trainingId,
            training_participant_id: trainingParticipant.training_participant_id,
        }

        questions.forEach(element => {
            if (element.category === 'TRAINER') {
                training.trainers.map((el, i) => {
                    return data[element.question + (i + 1)] = v[element.question + (i + 1)];
                });
            } else {
                data[element.question] = v[element.question];
            }
        });

        // console.log(data);

        SentralModel.action('Training', 'saveEvaluation', data, trainingId).then((res) => {
            message.destroy();
            message.success('Evaluation Saved', 1);
            setSaving(false);
            setTimeout(() => {
                history.push('/request-training');
            }, 1000);
        }).catch(() => {
            message.destroy();
            message.error('Oops, something went wrong', 2);
            setSaving(false);
        });
    }

    return (
        <Card className="content-container">
            <PageTitle title="Training and Certification Evaluation" breadcrumbs={[['Training and Certification', '/request-training'], ['Evaluation']]}></PageTitle>
            <Card>
                {
                    (loaded) ?
                        <div>
                            <Row justify='space-between'>
                                <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{width:"60%"}}> 
                                    <Descriptions.Item label="Judul">{training.title}</Descriptions.Item>
                                    <Descriptions.Item label="Tanggal">{toFullDate(training.training_date)}</Descriptions.Item>
                                    <Descriptions.Item label="Waktu">{moment(training.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(training.end_time, 'HH:mm:ss').format('HH:mm')}</Descriptions.Item>
                                    <Descriptions.Item label="Trainer">
                                        <ol style={{ paddingLeft: 20 }}>
                                            {
                                                training.trainers?.map((el, key) => (
                                                    <li key={key}>{el.name}</li>
                                                ))
                                            }
                                        </ol>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Row>

                            <Row justify='space-around' style={{backgroundColor: '#f1c40f', padding: 10, marginBottom: 20}}>
                                <Text strong style={{display:'block'}}>SK : Sangat Kurang</Text>
                                <Text strong style={{display:'block'}}>K : Kurang</Text>
                                <Text strong style={{display:'block'}}>C : Cukup</Text>
                                <Text strong style={{display:'block'}}>B : Baik</Text>
                                <Text strong style={{display:'block'}}>SB : Sangat Baik</Text>
                            </Row>

                            <Form size="middle" form={form} onFinish={saveEvaluation}>
                                {
                                    categories?.map((cat, i) => (
                                        <div key={'category_' + i}>
                                            {
                                                cat === 'TRAINER' ?
                                                training.trainers?.map((trainer, k) => (
                                                    <div key={'trainer_' + k}>
                                                        <Text strong style={{display:'block'}}>{'TRAINER (' + trainer.name + ')'}</Text>
                                                        <hr />
                                                        {
                                                            questions.filter(question => question.category === cat)?.map((ques, j) => (
                                                                <Form.Item key={'question_' + j + k} label={ques.question} name={ques.question + (k + 1)} labelCol={{span: 14}} wrapperCol={{span: 10}} labelAlign="left" rules={[{required: true}]}>
                                                                    {
                                                                        <fieldset id={"group_" + i + j + k}>
                                                                            <input type="radio" value="1" name={"group_" + i + j + k} />
                                                                            <label style={{marginLeft: 20, marginRight: 40}}>SK</label>
                                                                            <input type="radio" value="2" name={"group_" + i + j + k} />
                                                                            <label style={{marginLeft: 20, marginRight: 40}}>K</label>
                                                                            <input type="radio" value="3" name={"group_" + i + j + k} />
                                                                            <label style={{marginLeft: 20, marginRight: 40}}>C</label>
                                                                            <input type="radio" value="4" name={"group_" + i + j + k} />
                                                                            <label style={{marginLeft: 20, marginRight: 40}}>B</label>
                                                                            <input type="radio" value="5" name={"group_" + i + j + k} />
                                                                            <label style={{marginLeft: 20, marginRight: 40}}>SB</label>
                                                                        </fieldset>
                                                                    }
                                                                </Form.Item>
                                                            ))
                                                        }
                                                    </div>
                                                )) :
                                                cat === 'SUGGESTION' ?
                                                <div>
                                                    <Text strong style={{display: 'block'}}>PENDAPAT</Text>
                                                    <hr />
                                                    {
                                                        questions.filter(question => question.category === cat)?.map((ques, j) => (
                                                            <>
                                                                <Row style={{marginBottom: '5px'}}>
                                                                    <Text style={{display: 'block', color: '#ed5b56'}}>*</Text>
                                                                    <Text style={{display: 'block', marginLeft: '5px'}}>{ques.question}</Text>
                                                                </Row>
                                                                <Form.Item key={'question_'+j} name={ques.question} labelAlign="left" rules={[{required: true}]}>
                                                                    {
                                                                        ques.multiple_choice === null ?
                                                                        <TextArea rows={2} placeholder="Answer..."/> :
                                                                        <fieldset id={"group_" + i + j}>
                                                                            <input type="radio" value="a" name={"group_" + i + j} />
                                                                            <label style={{marginLeft: 20}}>Pengajar</label><br />
                                                                            <input type="radio" value="b" name={"group_" + i + j} />
                                                                            <label style={{marginLeft: 20}}>Peserta</label><br />
                                                                            <input type="radio" value="c" name={"group_" + i + j} />
                                                                            <label style={{marginLeft: 20}}>Pengajar dan peserta</label>
                                                                        </fieldset>
                                                                    }
                                                                </Form.Item>
                                                            </>
                                                        ))
                                                    }
                                                </div> :
                                                <div>
                                                    <Text strong style={{display: 'block'}}>{cat === 'PARTICIPANT' ? 'PESERTA PELATIHAN' : cat === 'SUBJECT' ? 'MATERI & PROGRAM ' : 'PENDAPAT'}</Text>
                                                    <hr />
                                                    {
                                                        questions.filter(question => question.category === cat)?.map((ques, j) => (
                                                            <Form.Item key={'question_'+j} label={ques.question} name={ques.question} labelCol={{ span: 14 }} wrapperCol={{ span: 10 }} labelAlign="left" rules={[{required: true}]}>
                                                                <fieldset id={"group_" + i + j}>
                                                                    <input type="radio" value="1" name={"group_" + i + j} />
                                                                    <label style={{marginLeft: 20, marginRight: 40}}>SK</label>
                                                                    <input type="radio" value="2" name={"group_" + i + j} />
                                                                    <label style={{marginLeft: 20, marginRight: 40}}>K</label>
                                                                    <input type="radio" value="3" name={"group_" + i + j} />
                                                                    <label style={{marginLeft: 20, marginRight: 40}}>C</label>
                                                                    <input type="radio" value="4" name={"group_" + i + j} />
                                                                    <label style={{marginLeft: 20, marginRight: 40}}>B</label>
                                                                    <input type="radio" value="5" name={"group_" + i + j} />
                                                                    <label style={{marginLeft: 20, marginRight: 40}}>SB</label>
                                                                </fieldset>
                                                            </Form.Item>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                                <Row justify="space-around">
                                    <Col span={24} className="text-right">
                                        <SubmitButton loading={saving}/>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    : <Spinner />
                }
            </Card>
        </Card>
    )
}

export default Evaluation;
