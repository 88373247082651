import React, { useEffect, useState } from 'react'
import { Row, Typography, Divider, Empty, Form, Input, message } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner, Uploader } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import { ImageViewer } from '../../components/ImageViewer';
import { useParams } from 'react-router-dom'
import authData from '../../helpers/authData'

const { Title } = Typography

const Certification = (props) => {
    const { employeeCode } = useParams()
    const [empCertifications, setEmpCertifications] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fetchEmpCertifications = () => {
        setLoaded(false)
        SentralModel.list('EmployeeTrainingCertification', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpCertifications(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)
    }, []);

    const addCertification = () => {
        setModalTitle('Add Certification')
        setDefaultValues({
            emp_training_certification_id: 0
        })
        setFormModal(true)
    }

    const editCertification = (v) => {
        setModalTitle('Edit Certification')
        setDefaultValues({
            emp_training_certification_id: v.emp_training_certification_id,
            description: v.description,
            year: v.year,
            certificate: v.certificate
        })
        setAttachmentFile(v.certificate)
        setFormModal(true)
    }

    const saveCertification = (v) => {
        setSaving(true)
        let payload = {
            emp_training_certification_id: v.emp_training_certification_id,
            employee_code: props.emp_code,
            description: v.description,
            year: v.year,
            certificate: attachmentFile
        }
        SentralModel.save('EmployeeTrainingCertification', payload, v.emp_training_certification_id).then((res) => {
            setFormModal(false)
            fetchEmpCertifications()
            message.success('Certification saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteCertification = (id) => {
        message.loading('deleting certification')
        SentralModel.deleteById('EmployeeTrainingCertification', id).then((res) => {
            message.destroy()
            message.success('Certification deleted', 2)
            let tmp = empCertifications
            let index = tmp.findIndex(el => el.emp_training_certification_id === id)
            tmp.splice(index, 1);
            setEmpCertifications([])
            setEmpCertifications(tmp)
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    return (
        <div>
            <div className="text-right">
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                        <AddButton onAdd={addCertification}>Add data</AddButton>
                        : null
                }
            </div>
            {
                loaded ?
                    (empCertifications.length > 0) ?
                        empCertifications.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key + 1}.</Title></Divider>
                                {
                                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                                        <ActionButton editable deleteable onEdit={() => editCertification(el)} onDelete={() => deleteCertification(el.emp_training_certification_id)} /> : null
                                }
                                <Row>
                                    <ProfileItem title="Description">{el.description}</ProfileItem>
                                    <ProfileItem title="Year">{el.year}</ProfileItem>
                                    <ProfileItem image span={24} title="Certificate">
                                        <div style={{ width: 200, maxWidth: '100%', maxHeight: 150, border: '1px solid #ededed' }}>
                                            <ImageViewer previewable={el.certificate ? true : false} src={el.certificate === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + el.certificate} alt="certificate" />
                                        </div>
                                    </ProfileItem>
                                </Row>
                            </div>
                        ))
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveCertification(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving} loading={uploading}>
                        <Form.Item name="emp_training_certification_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                            <Input placeholder="Description" />
                        </Form.Item>
                        <Form.Item label="Year" name="year" rules={[{ required: true }]}>
                            <Input placeholder="Year" />
                        </Form.Item>
                        <Form.Item label="Attachment" name="certificate" rules={[{ required: false }]}>
                            <Uploader dragger folder="certificate" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default Certification;
