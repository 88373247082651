import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const Job = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [jobData, setJobData] = useState([])
    const [measurements, setMeasurements] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }
    const fetchMeasurement = () => {
        SentralModel.list('Measurement', { orderBy: 'title', order: 'ASC' }, 0).then((res) => {
            setMeasurements(res.data);
        })
    }
    useEffect(() => {
        fetchDivision()
        fetchMeasurement()
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id,
            status: v.status,
            category: v.category,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'job', ft, 0).then((res) => {
            setJobData(res.data)
            setLoaded(true)
        })
    }

    const jobColumn = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Employee Name',
        dataIndex: 'name',
        width: '200px',
        fixed: 'left',
        render: (text, row, index) => {
            const obj = {
                children: text,
                props: {
                    style: {
                        verticalAlign: 'top'
                    }
                },
            };
            if (row.jobs && row.jobs.length > 0) {
                obj.props.rowSpan = row.jobs.length
            } else if (row.status) {
                obj.props.rowSpan = 0
            }
            return obj
        }
    }, {
        title: 'Division',
        dataIndex: 'division_name',
        width: '200px',
        fixed: 'left',
        render: (text, row, index) => {
            const obj = {
                children: text,
                props: {
                    style: {
                        verticalAlign: 'top'
                    }
                },
            };
            if (row.jobs && row.jobs.length > 0) {
                obj.props.rowSpan = row.jobs.length
            } else if (row.status) {
                obj.props.rowSpan = 0
            }
            return obj
        }
    }, {
        title: 'Position',
        dataIndex: 'position_name',
        width: '200px',
        fixed: 'left',
        render: (text, row, index) => {
            const obj = {
                children: text,
                props: {
                    style: {
                        verticalAlign: 'top'
                    }
                },
            };
            if (row.jobs && row.jobs.length > 0) {
                obj.props.rowSpan = row.jobs.length
            } else if (row.status) {
                obj.props.rowSpan = 0
            }
            return obj
        }
    }, {
        title: 'Date',
        dataIndex: 'do_date',
        width: '180px',
        fixed: 'left',
        render: (text, row, index) => {
            const obj = {
                children: toFullDate(text),
                props: {
                    style: {
                        verticalAlign: 'top'
                    }
                },
            };
            if (row.jobs && row.jobs.length === 0) {
                obj.children = null
            }
            if (row.rowSpan) {
                obj.props.rowSpan = row.rowSpan
            }
            if (row.status && row.first_flag === 0) {
                obj.props.rowSpan = 0
            }
            return obj
        }
    }, {
        title: 'Job',
        dataIndex: 'description',
        width: '400px',
        key: 'description'
    }, {
        title: 'Category',
        dataIndex: 'category',
        width: '200px',
        key: 'category'
    }, {
        title: 'Company',
        dataIndex: 'company',
        width: '230px',
        key: 'company'
    }, {
        title: 'Time',
        align: 'center',
        width: '200px',
        render: (row) => (
            (row.time_start) ? row.time_start + '-' + row.time_end : ''
        )
    }, {
        title: 'Status',
        dataIndex: 'status',
        width: '100px',
        align: 'center',
        key: 'status'
    }
    ];

    const getDateRowspan = (jobs, date) => {
        let r = 0;
        if (jobs && date) {
            jobs.forEach(el => {
                if (el.do_date === date) {
                    r++
                }
            })
        }
        return (r > 0) ? r : 1

    }

    const generateRows = () => {
        let rows = [];
        jobData.forEach(el => {
            if (el.jobs.length === 0) {
                rows.push(el)
            } else {
                el.jobs.forEach((el2, key) => {
                    let x = {};
                    if (key === 0) {
                        x = {
                            ...el,
                            do_date: el2.do_date,
                            description: el2.description,
                            time_start: el2.time_start,
                            time_end: el2.time_end,
                            status: el2.status,
                            category: el2.measurement.title,
                            company: el2.company === null ? '' : el2.company.company_name,
                            first_flag: 1
                        }
                    } else {
                        x = {
                            do_date: (el2.do_date !== el.jobs[key - 1].do_date) ? el2.do_date : null,
                            description: el2.description,
                            time_start: el2.time_start,
                            time_end: el2.time_end,
                            status: el2.status,
                            category: el2.measurement.title,
                            company: el2.company === null ? '' : el2.company.company_name,
                            first_flag: (el2.do_date !== el.jobs[key - 1].do_date) ? 1 : 0,
                        }
                    }
                    x.rowSpan = getDateRowspan(el.jobs, x.do_date)
                    rows.push(x)
                })
            }
        })
        return rows;
    }

    const changeDateRange = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/jobs?division_id=' + selectedDivision + '&status=' + selectedStatus.toString() + '&category=' + selectedCategory.toString() + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }
    return (
        <Card className="content-container">
            <PageTitle title="Job Report" breadcrumbs={[['Job Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1500 }} pagination={false} size="small" columns={jobColumn} dataSource={generateRows()} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            onChange={(v) => {
                                setSelectedDivision(v)
                            }} allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="status" label="Job Status" rules={[{ required: true, message: 'Please select job status' }]}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Status"
                            mode="multiple"
                            onChange={(v) => {
                                setSelectedStatus(v)
                            }} allowClear>
                            <Option value="In Progress">In Progress</Option>
                            <Option value="Finished">Finished</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select job category' }]}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Category"
                            mode="multiple"
                            onChange={(v) => {
                                setSelectedCategory(v)
                            }} allowClear>
                            {
                                measurements.map((el, key) => (
                                    <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range" rules={[{ required: true, message: 'Please select date range' }]}>
                        <RangePicker onChange={(v) => changeDateRange(v)} />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Job;
