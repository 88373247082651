import React from 'react';
import OvertimeRequest from './request/Index'

const Index = (props) => {
    return (
        <div>
            <OvertimeRequest data={props.data} requestNotif={props.requestNotif} />
        </div>
    );
}

export default Index;
