
import React from 'react';
import { Card, Tabs } from 'antd'
import { PageTitle } from '../../../components'
import Component from './Component'
import Regulation from './Regulation'
import Period from './Period'
import LateCategory from './LateCategory'
import BasicSalary from './BasicSalary';
import Prorate from './Prorate';
import Incentive from './Incentive';
const { TabPane } = Tabs

const Index = () => {
    return (
        <Card className="content-container">
            <PageTitle title="Payroll Setup" breadcrumbs={[['Payroll Setup']]}></PageTitle>
            <Tabs defaultActiveKey="period" tabPosition="left" >
                <TabPane tab="Period" key="period">
                    <Period />
                </TabPane>
                <TabPane tab="Component" key="component">
                    <Component />
                </TabPane>
                <TabPane tab="Regulations" key="regulation">
                    <Regulation />
                </TabPane>
                <TabPane tab="Late Category" key="late">
                    <LateCategory />
                </TabPane>
                <TabPane tab="Basic Salary" key="basic_salary">
                    <BasicSalary />
                </TabPane>
                <TabPane tab="Prorate" key="prorate">
                    <Prorate />
                </TabPane>
                <TabPane tab="Incentive" key="incentive">
                    <Incentive />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
