import React, { useEffect, useState } from 'react';
import { Card, Typography, message, Space } from 'antd'
import { NewButton, EditButton, DeleteButton, PageTitle, Spinner, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const { Text } = Typography
const Index = () => {
    const [generals, setGenerals] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchGenerals = () => {
        SentralModel.list('General', { orderBy: 'description', order: 'asc' }).then(res => {
            setLoaded(false)
            setGenerals(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchGenerals()
    }, []);

    const dataSource = [
        ...generals.map(el => {
            return {
                general_code: el.general_code,
                description: el.description
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Code',
        dataIndex: 'general_code',
        key: 'general_code',
        width: '10%'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '90%'
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton link to={"/setting/general/detail/" + row.general_code} />
                    <DeleteButton onConfirm={() => deleteGenerals(row.general_code)} />
                </Space>
            </div>
        )
    },
    ];

    const deleteGenerals = (general_code) => {
        message.loading('deleting General')
        SentralModel.deleteById('General', general_code).then((res) => {
            message.destroy()
            message.success('General deleted', 2)
            let tmp = generals
            let index = tmp.findIndex(el => el.general_code === general_code)
            tmp.splice(index, 1);
            setGenerals([])
            setGenerals(tmp)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Generals" breadcrumbs={[['General']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>General List</Text>
                        <NewButton right to="/setting/general/detail/new" title="New General" />
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            : <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
