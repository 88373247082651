import React, {useState} from 'react';
import {Card, Select, Button, Col, Row, DatePicker, message} from 'antd';
import {FileExcelOutlined} from '@ant-design/icons'
import { PageTitle, DataTable, Spinner} from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler';
import moment from 'moment';

const {Option} = Select;

const Payroll = () => {
    const [loaded, setLoaded] = useState(true)
    const [payrollData, setPayrollData] = useState([])
    const [periods, setPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(0);

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, {orderBy: 'from_date', order: 'asc'}).then(res=>{
            setLoaded(true)
            setPeriods(res.data)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (v) => {
        setLoaded(false)
        SentralModel.action('Payroll', 'reportPayrollOperational', {period_id: v}, 0).then(res => {
            setPayrollData(res.data)
            setSelectedPeriod(v)
            setLoaded(true)
        })
    }

    const disabledDate = (current) =>{
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    const exportReport = () => {
        if (selectedPeriod === 0) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL +`/report/payroll?period_id=${selectedPeriod}`)
        }
    }

    const dataSource = [
        ...payrollData.map( el => {
            return {
                year: el.period.year,
                month: el.period.month,
                employee_code: el.employee_code,
                name: el.employee.name,
                division_name: el.employee.division.division_name,
                position_name: el.employee.position.position_name,
                working_days: el.working_days,
                weekly_incentives: el.weekly,
                overtime_in_the_morning: el.overtime_in_the_morning,
                overtime_in_the_night: el.overtime_in_the_night,
                overtime_on_holiday: el.overtime_on_holiday,
                overtime_more_than_20: el.overtime_more_than_20,
                late_1: el.late_1,
                late_2: el.late_2,
                total_late: el.total_late,
                visit_it: el.visit_it,
                claim_medical: el.claim_medical,
                remarks: el.remarks,
            }
        })
    ];

    const payrollColumn = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            width:'100px',
            fixed: 'left',
            render: (text) => (
                <div className="text-center">
                    {text}
                </div>
            )
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            width:'100px',
            fixed: 'left',
            render: (text) => (
                <div className="text-center">
                    {text}
                </div>
            )
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            width:'250px',
            fixed: 'left',
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width:'250px',
        },
        {
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name',
            width:'250px',
        },
        {
            title: 'Attendance',
            dataIndex: 'working_days',
            key: 'working_days',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Weekly Incentives',
            dataIndex: 'weekly_incentives',
            key: 'weekly_incentives',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Overtime in the Morning',
            dataIndex: 'overtime_in_the_morning',
            key: 'overtime_in_the_morning',
            width: 180,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Overtime in the Night',
            dataIndex: 'overtime_in_the_night',
            key: 'overtime_in_the_night',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Overtime on Holiday',
            dataIndex: 'overtime_on_holiday',
            key: 'overtime_on_holiday',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Overtime more than 20:00',
            dataIndex: 'overtime_more_than_20',
            key: 'overtime_more_than_20',
            width: 200,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Visit (IT)',
            dataIndex: 'visit_it',
            key: 'visit_it',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Late 1 (<= 30 minutes)',
            dataIndex: 'late_1',
            key: 'late_1',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Late 2 (> 30 minutes)',
            dataIndex: 'late_2',
            key: 'late_2',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === 0 ? '' : text}
                </div>
            )
        },
        {
            title: 'Claim Medical',
            dataIndex: 'claim_medical',
            key: 'claim_medical',
            width: 170,
            render: (text) => (
                <div className="text-center">
                    {text === '0.00' ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            width: 200,
        }
    ];
    
    return (
        <Card className="content-container">
            <PageTitle title="Payroll Report" breadcrumbs={[['Payroll Report']]}></PageTitle>

            <Row justify="end" className="mb-3">
                <Col span={24} >
                    <span style={{float:'right'}}>
                        <Select placeholder="Select Period" style={{ width: 200, marginRight:10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                        <Button type="primary" onClick={() => exportReport()}  color="#ededed" icon={<FileExcelOutlined />}>
                            Export
                        </Button>
                    </span>
                    <span style={{float:'right', marginRight: '20px'}}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year"/>
                    </span>
                </Col>
            </Row>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1500 }} pagination={false} size="small" columns={payrollColumn} dataSource={dataSource} bordered/>
                :<Spinner/>
            }
        </Card>
    );
}

export default Payroll;
