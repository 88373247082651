import React, {useState, useEffect} from 'react';
import {Typography, Form, Input, message} from 'antd'
import { FormModal, DataTable, Spinner, EditButton, AddButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
const {Title} = Typography

const LateCategory = () => {
    const [lateForm] = Form.useForm()
    const [formModal, setFormModal] = useState(false)
    const [lateCategories, setLateCategories] = useState([])
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [formState, setFormState] = useState('add')

    const fetchLateCategories = () =>{
        setLoaded(false)
        SentralModel.list('LateCategory',{orderBy:'limit',order:'asc'}).then(res=>{
            setLateCategories(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLateCategories()
    }, []);

    const editComponent =(row) => {
        setFormState('edit')
        lateForm.setFieldsValue({
            late_category_id:row.late_category_id,
            description:row.description,
            limit: row.limit,
            fine:toIdr(row.fine)
        })
        setFormModal(true)
    }

    const columns= [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },{
            title: 'Limit',
            dataIndex: 'limit',
            align:'center',
            render: (text)=>(
                text + ' minutes'
            )
        },{
            title: 'Fine',
            dataIndex: 'fine',
            render: (text)=>(
                toIdr(text)
            )
        },{
            title: 'Action',
            align:'center',
            width:'10%',
            render: (row) => (
                <EditButton onEdit={() => editComponent(row)}/>
            )
        },
    ];

    const addComponent = () =>{
        setFormState('add')
        lateForm.resetFields()
        lateForm.setFieldsValue({late_category_id:0})
        setFormModal(true)
    }


    const saveCategory = (v) =>{
        setSaving(true)
        let data={
            late_category_id:v.late_category_id,
            description:v.description,
            limit: v.limit,
            fine:extractNumber(v.fine)
        }
        SentralModel.save('LateCategory', data, v.late_category_id).then(()=>{
            fetchLateCategories()
            message.success('Late Category saved');
            setSaving(false)
            setFormModal(false)
        })
    }

    const changeFine = (v) =>{
        if(v.target.value){
            lateForm.setFieldsValue({fine: toIdr(extractNumber(v.target.value))})
        }else{
            lateForm.setFieldsValue({fine: 0})
        }
    }

    return (
        <div>
            <Title level={4}>Late Category</Title>
            <div className="text-right mb-2">
                <AddButton onAdd={addComponent} title="Add Late Category"/>
            </div>
            {
                loaded ?
                <DataTable bordered size="small" columns={columns} dataSource={lateCategories}/>
                : <Spinner/>
            }

            <FormModal form={lateForm} title={(formState==="add")? 'Add new Late Category' : 'Edit Late Category'} 
                showModal={formModal} 
                onCloseModal={() => setFormModal(false)} 
                submitForm={(v)=> saveCategory(v)}
                saving={saving}>
                <Form.Item name="late_category_id" hidden>
                    <Input/>
                </Form.Item>
                <Form.Item name="description" label="Late Description">
                    <Input/>
                </Form.Item>
                <Form.Item name="limit" label="Late Limit">
                    <Input addonAfter="minutes"/>
                </Form.Item>
                <Form.Item name="fine" label="Fine">
                    <Input onChange={(v) => changeFine(v)}/>
                </Form.Item>
            </FormModal>
        </div>
    );
}

export default LateCategory;
