import React, { useEffect, useState } from 'react';
import { Card, Typography, message, Space, Tag } from 'antd'
import { NewButton, EditButton, DeleteButton, PageTitle, Spinner, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const { Text } = Typography
const Index = () => {
    const [announcements, setAnnouncements] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchAnnouncements = () => {
        setLoaded(false)
        SentralModel.list('Announcement', { orderBy: 'end_date', order: 'desc' }).then(res => {
            setAnnouncements(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchAnnouncements()
    }, []);

    const dataSource = [
        ...announcements.map(el => {
            return {
                id: el.announcement_id,
                subject: el.subject,
                announcement: el.announcement,
                start_date: el.start_date,
                end_date: el.end_date,
                status: el.end_date >= moment().format('YYYY-MM-DD') ? 'Active' : 'Expired'
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        width: '70px',
        key: 'name',
    },
    {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: '40%'
    },
    {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        width: '20%',
        render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'End Date',
        dataIndex: 'end_date',
        key: 'end_date',
        width: '20%',
        render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        render: (text) => (
            <div className="text-center">
                <Tag color={text === 'Active' ? "green" : "red"}>{text}</Tag>
            </div>
        )
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton link to={"/management/announcement/detail/" + row.id} />
                    <DeleteButton onConfirm={() => deleteAnnouncements(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    const deleteAnnouncements = (id) => {
        message.loading('deleting Announcement')
        SentralModel.deleteById('Announcement', id).then((res) => {
            message.destroy()
            message.success('Announcements deleted', 2)
            let tmp = announcements
            let index = tmp.findIndex(el => el.announcement_id === id)
            tmp.splice(index, 1);
            setAnnouncements([])
            setAnnouncements(tmp)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Announcements" breadcrumbs={[['Announcement']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Announcement List</Text>
                        <NewButton right to="/management/announcement/detail/0" title="New Announcement" />
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            : <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
