import moment from "moment";

export const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
        dates.push(moment(date).format('YYYY-MM-DD'));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}