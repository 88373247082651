import { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler'
import moment from 'moment';

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [loan, setLoan] = useState(null)

    const fetchLoan = (id) => {
        SentralModel.get('SubmissionLoan', {}, id).then((res) => {
            setLoan(res.data)
        })
    }

    useEffect(() => {
        fetchLoan(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setLoan(null)
        fetchLoan(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Loan Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (loan) ?
                    <div>
                        <DetailHeader
                            name={loan?.employee.name}
                            date={loan?.created_at}
                            status={loan?.status} />
                        <Card bordered className="mb-4">
                            <Row justify='space-between'>
                                <Col>
                                    <Text strong>LOAN DETAIL</Text>
                                </Col>
                            </Row>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{loan?.employee?.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{loan?.employee?.division?.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{loan?.employee?.position?.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Loan Amount</th><td>{toIdr(loan?.total_amount)}</td>
                                            </tr>
                                            <tr>
                                                <th>Loan Period</th><td>{`${loan?.loan_period} Month`}</td>
                                            </tr>
                                            <tr>
                                                <th>Start Period</th><td>{moment(loan?.start_period).format("DD MMMM YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <th>End Period</th><td>{moment(loan?.end_period).format("DD MMMM YYYY")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog
                                category="LOAN"
                                requestId={loan.sub_loan_id}
                                approvers={loan.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;
