import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs, Badge } from 'antd'
import {
    EnvironmentOutlined, ReconciliationOutlined, PlusSquareOutlined, FieldTimeOutlined,
    ScissorOutlined, WalletOutlined, MoneyCollectOutlined, AimOutlined
} from '@ant-design/icons'
import { setSessionStorage, getSessionStorage } from '../../helpers/sessionStorage'
import { PageTitle, Spinner } from '../../components'
import BusinessTrip from './businessTrip/Index'
import Leave from './leave/Index'
import Medical from './medical/Index'
import Overtime from './overtime/Index'
import OfficeStationery from './officeStationery/Index'
import PurchaseRequest from './purchaseRequest/Index'
import Reimbursement from './reimbursement/Index'
import Attendance from './attendance/Index'
import LoanPayment from './loanPayment/Index'
import Loan from './loan/Index'
import authData from '../../helpers/authData'
import { SentralModel } from '../../models/SentralModel';

const { TabPane } = Tabs;

const Index = () => {
    if (authData.approval === null) window.location.href = '/notfound404'

    const [leaves, setLeaves] = useState(null)
    const [overtimes, setOvertimes] = useState(null)
    const [medicals, setMedicals] = useState(null)
    const [reimbursements, setReimbursements] = useState(null)
    const [purchaseRequests, setPurchaseRequests] = useState(null)
    const [businessTrips, setBusinessTrips] = useState(null)
    const [stationeries, setStationeries] = useState(null)
    const [attendances, setAttendances] = useState(null)
    const [loans, setLoans] = useState(null)
    const [loanPayments, setLoanPayments] = useState([])
    const [notifications, setNotifications] = useState([])
    const defaultSelectedTab = getSessionStorage('selectedSubmissionTab')

    const fetchSubmission = (category) => {
        SentralModel.action('Submission', 'getSubmissionList', { category: category }, 0).then((res) => {
            if (category === 'LEAVE') {
                setLeaves(res.data)
            } else if (category === "OVERTIME") {
                setOvertimes(res.data)
            } else if (category === "MEDICAL") {
                setMedicals(res.data)
            } else if (category === "REIMBURSEMENT") {
                setReimbursements(res.data)
            } else if (category === "PURCHASEREQUEST") {
                setPurchaseRequests(res.data)
            } else if (category === "BUSINESSTRIP") {
                setBusinessTrips(res.data)
            } else if (category === "STATIONERY") {
                setStationeries(res.data)
            } else if (category === "ATTENDANCE") {
                setAttendances(res.data)
            } else if (category === 'LOAN') {
                setLoans(res.data)
            } else if (category === 'LOANPAYMENT') {
                setLoanPayments(res.data)
            }
        })
    }

    const fetchNotification = () => {
        SentralModel.action('Submission', 'getNotification', {}, 0).then((res) => {
            setNotifications(res.data)
        })
    }

    useEffect(() => {
        fetchNotification()
        if (defaultSelectedTab) {
            fetchSubmission(defaultSelectedTab.toUpperCase())
        } else {
            fetchSubmission('LEAVE')
        }
    }, [defaultSelectedTab]);

    const changeTab = (v) => {
        setSessionStorage('selectedSubmissionTab', v)
        if (v === 'leave' && !leaves) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'overtime' && !overtimes) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'medical' && !medicals) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'reimbursement' && !reimbursements) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'purchaseRequest' && !purchaseRequests) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'businessTrip' && !businessTrips) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'stationery' && !stationeries) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'attendance' && !attendances) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'loan' && !loans) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'loanPayment' && !loanPayments) { // loanPayments or loanPayment?
            fetchSubmission(v.toUpperCase())
        }
    }

    return (
        <Card className="content-container">
            <PageTitle title="Submission List" breadcrumbs={[['Submission List']]}></PageTitle>
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey={defaultSelectedTab} type="card" tabPosition="left" onChange={changeTab} onTabClick={changeTab} /* className="submission-tab" */>

                        {authData.approval.leave || authData.approval.leave_cancellation ?
                            <TabPane tab={
                                (notifications.includes("LEAVE") || notifications.includes("LEAVECANCELLATION")) ?
                                    <Badge dot className="blinking-badge">
                                        <ReconciliationOutlined /> Leave
                                    </Badge> :
                                    <span><ReconciliationOutlined /> Leave</span>
                            } key="leave">
                                {
                                    (leaves) ?
                                        <Leave data={leaves} requestNotif={notifications.includes("LEAVE")} cancellationNotif={notifications.includes("LEAVECANCELLATION")} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.overtime ?
                            <TabPane tab={
                                (notifications.includes("OVERTIME")) ?
                                    <Badge dot className="blinking-badge">
                                        <FieldTimeOutlined /> Overtime
                                    </Badge> :
                                    <span><FieldTimeOutlined /> Overtime</span>
                            } key="overtime">
                                {
                                    (overtimes) ?
                                        <Overtime data={overtimes} requestNotif={notifications.includes("OVERTIME")} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.reimbursement ?
                            <TabPane tab={
                                (notifications.includes("REIMBURSEMENT")) ?
                                    <Badge dot className="blinking-badge">
                                        <MoneyCollectOutlined /> Reimbursement
                                    </Badge> :
                                    <span><MoneyCollectOutlined /> Reimbursement</span>
                            } key="reimbursement">
                                {
                                    (reimbursements) ?
                                        <Reimbursement data={reimbursements} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.medical ?
                            <TabPane tab={
                                (notifications.includes("MEDICAL")) ?
                                    <Badge dot className="blinking-badge">
                                        <PlusSquareOutlined /> Medical
                                    </Badge> :
                                    <span><PlusSquareOutlined /> Medical</span>
                            } key="medical">
                                {
                                    (medicals) ?
                                        <Medical data={medicals} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.stationery ?
                            <TabPane tab={
                                (notifications.includes("STATIONERY")) ?
                                    <Badge dot className="blinking-badge">
                                        <ScissorOutlined /> Office Stationery
                                    </Badge> :
                                    <span><ScissorOutlined /> Office Stationery</span>

                            } key="stationery">
                                {
                                    (stationeries) ?
                                        <OfficeStationery data={stationeries} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.business_trip ?
                            <TabPane tab={
                                (notifications.includes("BUSINESSTRIP") || notifications.includes("BUSINESSTRIPREALIZATION")) ?
                                    <Badge dot className="blinking-badge">
                                        <EnvironmentOutlined /> Business Trip
                                    </Badge> : <span><EnvironmentOutlined /> Business Trip</span>
                            }
                                key="businessTrip">
                                {
                                    (businessTrips) ?
                                        <BusinessTrip data={businessTrips} realizationNotif={notifications.includes("BUSINESSTRIPREALIZATION")} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.purchase_on_budget || authData.approval.purchase_over_budget ?
                            <TabPane tab={
                                (notifications.includes("PURCHASEONBUDGET") || notifications.includes("PURCHASEOVERBUDGET")) ?
                                    <Badge dot className="blinking-badge">
                                        <WalletOutlined /> Purchase Request
                                    </Badge> :
                                    <span><WalletOutlined /> Purchase Request</span>
                            } key="purchaseRequest">
                                {
                                    (purchaseRequests) ?
                                        <PurchaseRequest data={purchaseRequests} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.attendance ?
                            <TabPane tab={
                                (notifications.includes("ATTENDANCE")) ?
                                    <Badge dot className="blinking-badge">
                                        <EnvironmentOutlined /> Attendance
                                    </Badge> : <span><AimOutlined /> Attendance</span>
                            }
                                key="attendance">
                                {
                                    (attendances) ?
                                        <Attendance data={attendances} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.attendance ?
                            <TabPane tab={
                                (notifications.includes("LOAN")) ?
                                    <Badge dot className="blinking-badge">
                                        <ReconciliationOutlined /> Loan
                                    </Badge> : <span><ReconciliationOutlined /> Loan</span>
                            }
                                key="loan">
                                {
                                    (loans) ?
                                        <Loan data={loans} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }

                        {authData.approval.attendance ?
                            <TabPane tab={
                                (notifications.includes("LOANPAYMENT")) ?
                                    <Badge dot className="blinking-badge">
                                        <ReconciliationOutlined /> Loan Payment
                                    </Badge> : <span><ReconciliationOutlined /> Loan Payment</span>
                            }
                                key="loanpayment">
                                {
                                    (loanPayments) ?
                                        <LoanPayment data={loanPayments} /> : <Spinner />
                                }
                            </TabPane>
                            : null
                        }
                    </Tabs>
                </Col>
            </Row>
        </Card>
    );
}

export default Index;
