import React, { useEffect, useState } from 'react';
import { Card, Col, Descriptions, Row, message } from 'antd';
import { PageTitle } from '../../../components'
import RequestForm from './RequestForm'
import moment from 'moment'
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData'
import { useHistory } from 'react-router-dom'
import { toIdr } from '../../../utils/currencyHandler';

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [validityHour, setValidityHour] = useState({});
    const [date, setDate] = useState(null)
    const [ovtCompensation, setOvtCompensation] = useState({})
    const [ovtMultiplier, setOvtMultiplier] = useState([])
    const [basicSalary, setBasicSalary] = useState(null)
    const [ovtHours, setOvtHours] = useState(0)
    const [ovtValue, setOvtValue] = useState(0)

    const fetchValidityHour = () => {
        SentralModel.action('Submission', 'getOvertimeHour').then(res => {
            setValidityHour(res.message);
        })
    }

    const fetchOvertimeCompensation = () => {
        SentralModel.action('Overtime', 'overtimeCompensationActive', null, null).then((res) => {
            setOvtCompensation(res.data)
        })
    }

    const fetchBasicSalary = () => {
        SentralModel.action('EmployeeBasicSalary', 'getSalaryEmp', { employee_code: authData.employee_code }, null).then((res) => {
            setBasicSalary(res.data.basic_salary)
        })
    }

    const fetchOvertimeMultiplier = () => {
        SentralModel.action('Overtime', 'getOvertimeMultiplier', null, null).then((res) => {
            setOvtMultiplier(res.data)
        })
    }

    useEffect(() => {
        fetchValidityHour()
        fetchOvertimeCompensation()
        fetchOvertimeMultiplier()
        fetchBasicSalary()

    }, []);

    const generateOvtTime = (v) => {
        let category;
        if (date !== null) {
            if (new Date(date).getDay() === 0 || new Date(date).getDay() === 6) {
                category = "HOLIDAY"
            } else {
                category = "WORKINGDAYS"
            }
        }

        const hours = Math.round((moment(v[1]) - moment(v[0])) / 3600000)
        setOvtHours(hours)

        let total = 0
        for (let i = 1; i <= hours; i++) {
            let multiplier
            if (i === 1) {
                multiplier = ovtMultiplier.filter(
                    (el) => el.first_hour === 1 && el.second_hour === 1 && el.category === category)
            } else {
                multiplier = ovtMultiplier.filter(
                    (el) => i >= el.first_hour && el.second_hour >= i && el.category === category)
            }

            if (ovtCompensation.category === 'VALUE') {
                total += multiplier[0]?.multiply * ovtCompensation.value
            } else {
                total += multiplier[0]?.multiply * (basicSalary / ovtCompensation.value)
            }
        }

        setOvtValue(total)
    }

    const submitOvertime = (v) => {
        if (moment(v.time[0], 'HH:mm').format('HH:mm:ss') < validityHour.start_time) {
            message.error('You cannot apply for overtime below ' + moment(validityHour.start_time, 'HH:mm:ss').format('HH:mm'), 2);
        } else if (moment(v.time[1], 'HH:mm').format('HH:mm:ss') > validityHour.end_time) {
            message.error('You cannot apply for overtime after ' + moment(validityHour.end_time, 'HH:mm:ss').format('HH:mm'), 2);
        } else {
            message.loading('Saving Data', 0)
            setSaving(true)
            let data = {
                employee_code: authData.employee_code,
                from_date: moment(v.date).format('YYYY-MM-DD'),
                to_date: moment(v.date).format('YYYY-MM-DD'),
                description: v.necessity,
                from_time: moment(v.time[0], 'HH:mm').format('HH:mm:ss'),
                to_time: moment(v.time[1], 'HH:mm').format('HH:mm:ss'),
                overtime_type: v.location,
                total_hours: ovtHours,
                total_value: ovtValue,
                payment_status: "WAITING"
            }
            SentralModel.action('Submission', 'checkOvertime', data, 0).then((res) => {
                if (res.message === 'ok') {
                    SentralModel.saveWithApproval('SubmissionOvertime', 'OVERTIME', data, 0).then(() => {
                        setSaving(false)
                        message.destroy()
                        message.success('Overtime submitted', 1)
                        setTimeout(() => {
                            history.push('/overtime');
                        }, 1000);
                    }).catch((err) => {
                        setSaving(false)
                        message.destroy()
                        message.error(err.message, 2)
                    })
                } else {
                    setSaving(false)
                    message.destroy()
                    message.error("Can't apply for overtime on the same day", 2)
                }
            })
        }
    }

    return (
        <Card className="content-container">
            <PageTitle title="Overtime Submission" breadcrumbs={[['Overtime', '/overtime'], ['Create']]}></PageTitle>
            <div style={{ marginBottom: 10 }}>
                <RequestForm onSubmit={(v) => submitOvertime(v)} saving={saving} onChangeDate={(v) => setDate(v)} onChangeTime={(v) => generateOvtTime(v)} defaultDate={date} />
            </div>
            <Row justify='space-between'>
                <Col span={11}>
                    {
                        ovtHours === 0 && ovtValue === 0 ? null :
                            <Card>
                                <Descriptions title="Overtime Info" bordered >
                                    <Descriptions.Item span={3} label="Total Hours">{ovtHours} Hours</Descriptions.Item>
                                    <Descriptions.Item span={3} label="Total Value">{toIdr(ovtValue)}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                    }
                </Col>
                <Col span={11}>
                </Col>
            </Row>
        </Card>
    );
}

export default Create;
