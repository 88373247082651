import React, {useState} from 'react';
import {Card, Form, Input, Typography, Space, message} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton, DataTable, CustomButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {SyncOutlined} from '@ant-design/icons'

const {Text} = Typography
const Index = (props) => {
    const [generateForm] = Form.useForm()
    const [letterForm] = Form.useForm()
    const [modalTitle, setModalTitle]= useState(null)
    const [documentFormModal, setDocumentFormModal] = useState(false)
    const [letterFormModal, setLetterFormModal] = useState(false)
    const [saving, setSaving] = useState(false)

    const dataSource = [
        ...props.data.map( el => {
            return {
                id: el.letter_master_id,
                letter_id: el.letter_id,
                label: el.label,
                prefix: el.prefix,
                description: el.description
            }
        })
    ];

    const dataColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Prefix',
            dataIndex: 'prefix',
            key: 'prefix',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editDocument(row)} />
                        <DeleteButton onConfirm={() => deleteDocument(row.id)} />
                        <CustomButton icon={<SyncOutlined />} onEdit={() => generateDocument(row.id)} />
                    </Space>
                </div>
            )
        }
    ];


    const addDocument = () => {
        setModalTitle('Add Document Letter')
        generateForm.setFieldsValue({
            letter_master_id: 0,
            letter_id: props.id,
            label: '',
            prefix: '',
            description: ''
        })
        setDocumentFormModal(true)
    }

    const editDocument = (v) => {
        setModalTitle('Edit Document Letter')
        generateForm.setFieldsValue({
            letter_master_id: v.id,
            letter_id: props.id,
            label: v.label,
            prefix: v.prefix,
            description: v.description,
        })
        setDocumentFormModal(true)
    }

    const saveDocument = (v) => {
        setSaving(true)
        let payload = {
            letter_id: v.letter_id,
            label: v.label,
            prefix: v.prefix,
            description: v.description,
        }
        SentralModel.save('LetterMaster', payload, v.letter_master_id).then((res) => {
            setDocumentFormModal(false)
            props.onFetch();
            message.success('Document saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteDocument = (id) =>{
        message.loading('Deleting document')
        SentralModel.deleteById('LetterMaster', id).then((res) => {
            message.destroy();
            message.success('Document deleted', 2);
            props.onFetch();
        })
    }

    const generateDocument = (v) => {
        setModalTitle('Generate New Letter Number')
        letterForm.setFieldsValue({
            letter_document_id: 0,
            letter_master_id: v,
            description: ''
        })
        setLetterFormModal(true)
    }

    const saveLetter = (v) => {
        setSaving(true)
        let payload = {
            letter_master_id: v.letter_master_id,
            description: v.description,
        }
        SentralModel.action('Letter', 'generateNumber', payload, v.letter_document_id).then((res)=>{
            setLetterFormModal(false)
            props.onGenerate(res.message.document_no);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Document List</Text>
                    <AddButton right onAdd={addDocument} title="New Document Letter"/>
                </div> }>

                <DataTable size="small" columns={dataColumn} dataSource={dataSource} bordered/>
            </Card>

            {
                (documentFormModal) ?
                <FormModal form={generateForm} title={modalTitle} submitForm={(v)=>saveDocument(v)} width={400} showModal={documentFormModal} onCloseModal={() => setDocumentFormModal(false)} saving={saving}>
                    <Form.Item name="letter_master_id" hidden>
                        <Input placeholder="ID" />
                    </Form.Item>
                    <Form.Item name="letter_id" hidden>
                        <Input placeholder="Letter ID" />
                    </Form.Item>
                    <Form.Item label="Label" name="label" rules={[{required:true}]}>
                        <Input placeholder="Label" />
                    </Form.Item>
                    <Form.Item label="Prefix" name="prefix" rules={[{required:true}]}>
                        <Input placeholder="Prefix" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                </FormModal>
                : null
            }

            {
                (letterFormModal) ?
                <FormModal form={letterForm} title={modalTitle} submitForm={(v)=>saveLetter(v)} width={400} showModal={letterFormModal} onCloseModal={() => setLetterFormModal(false)} saving={saving}>
                    <Form.Item name="letter_document_id" hidden>
                        <Input placeholder="ID" />
                    </Form.Item>
                    <Form.Item name="letter_master_id" hidden>
                        <Input placeholder="Letter Master ID" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
