import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Divider, Empty, Form, Input, Select, DatePicker, message } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import authData from '../../helpers/authData'

const { Title } = Typography
const { Option } = Select;

const Spouse = (props) => {
    const { employeeCode } = useParams()
    const [empSpouses, setEmpSpouses] = useState(props.spouse);
    const [empChildrens, setEmpChildrens] = useState(props.children);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [gender, setGender] = useState([]);
    const [education, setEducation] = useState([]);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedEducation, setSelectedEducation] = useState(null);

    const fetchEmpSpouses = () => {
        setLoaded(false)
        SentralModel.list('EmployeeSpouse', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpSpouses(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchEmpChildrens = () => {
        setLoaded(false)
        SentralModel.list('EmployeeChildren', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpChildrens(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchGender = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'G']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setGender(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchEducation = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ET']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setEducation(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchGender()
        fetchEducation()
    }, []);

    // Spouse
    const addSpouse = () => {
        setModalTitle('Add Spouse')
        setDefaultValues({
            source: 'Spouse',
            emp_spouse_id: 0
        })
        setFormModal(true)
    }

    const editSpouse = (v) => {
        setModalTitle('Edit Spouse')
        setDefaultValues({
            source: 'Spouse',
            emp_spouse_id: v.emp_spouse_id,
            name: v.name,
            gender: v.gender.detail_code,
            place_of_birth: v.place_of_birth,
            date_of_birth: moment(v.date_of_birth, 'YYYY-MM-DD'),
            education: v.education === null ? null : v.education.detail_code,
            job: v.job
        })
        setSelectedGender(v.gender.detail_code)
        setSelectedEducation(v.education === null ? null : v.education.detail_code)
        setFormModal(true)
    }

    const saveSpouse = (v) => {
        setSaving(true)
        let payload = {
            emp_spouse_id: v.emp_spouse_id,
            employee_code: props.emp_code,
            name: v.name,
            gender: selectedGender,
            place_of_birth: v.place_of_birth,
            date_of_birth: moment(v.date_of_birth, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            education: selectedEducation,
            job: v.job
        }
        SentralModel.save('EmployeeSpouse', payload, v.emp_spouse_id).then((res) => {
            setFormModal(false)
            fetchEmpSpouses()
            message.success('Spouse saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteSpouse = (id) => {
        message.loading('deleting spouse')
        SentralModel.deleteById('EmployeeSpouse', id).then((res) => {
            message.destroy()
            message.success('Spouse deleted', 2)
            let tmp = empSpouses
            let index = tmp.findIndex(el => el.emp_spouse_id === id)
            tmp.splice(index, 1);
            setEmpSpouses([])
            setEmpSpouses(tmp)
        })
    }

    // Children
    const addChildren = () => {
        setModalTitle('Add Children')
        setDefaultValues({
            source: 'Children',
            emp_children_id: 0
        })
        setFormModal(true)
    }

    const editChildren = (v) => {
        setModalTitle('Edit Children')
        setDefaultValues({
            source: 'Children',
            emp_children_id: v.emp_children_id,
            name: v.name,
            gender: v.gender.detail_code,
            place_of_birth: v.place_of_birth,
            date_of_birth: moment(v.date_of_birth, 'YYYY-MM-DD'),
            education: v.education === null ? null : v.education.detail_code,
            job: v.job
        })
        setSelectedGender(v.gender.detail_code)
        setSelectedEducation(v.education === null ? null : v.education.detail_code)
        setFormModal(true)
    }

    const saveChildren = (v) => {
        setSaving(true)
        let payload = {
            emp_children_id: v.emp_children_id,
            employee_code: props.emp_code,
            name: v.name,
            gender: selectedGender,
            place_of_birth: v.place_of_birth,
            date_of_birth: moment(v.date_of_birth, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            education: selectedEducation,
            job: v.job
        }
        SentralModel.save('EmployeeChildren', payload, v.emp_children_id).then((res) => {
            setFormModal(false)
            fetchEmpChildrens()
            message.success('Children saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteChildren = (id) => {
        message.loading('deleting child')
        SentralModel.deleteById('EmployeeChildren', id).then((res) => {
            message.destroy()
            message.success('Child deleted', 2)
            let tmp = empChildrens
            let index = tmp.findIndex(el => el.emp_children_id === id)
            tmp.splice(index, 1);
            setEmpChildrens([])
            setEmpChildrens(tmp)
        })
    }

    const changeGender = e => {
        setSelectedGender(e)
    }

    const changeEducation = e => {
        setSelectedEducation(e)
    }

    return (
        <div>
            <div style={{ float: "right", paddingLeft: 20 }}>
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' ?
                        <AddButton title="Add Child" onAdd={addChildren} />
                        : null
                }
            </div>
            <div style={{ float: "right" }}>
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' ?
                        <AddButton title="Add Spouse" onAdd={addSpouse} />
                        : null
                }
            </div>

            {
                loaded ?
                    (empSpouses.length > 0) ?
                        <div>
                            {
                                empSpouses.map((el, key) => (
                                    <div key={key}>
                                        <Divider orientation="left"><Title level={4}>{key === 0 ? 'Spouse' : null}</Title></Divider>
                                        {
                                            authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' ?
                                                <ActionButton editable deleteable onEdit={() => editSpouse(el)} onDelete={() => deleteSpouse(el.emp_spouse_id)} /> : null
                                        }
                                        <Row>
                                            <ProfileItem title="Name">{el.name}</ProfileItem>
                                            <ProfileItem title="Gender">{el.gender.description}</ProfileItem>
                                            <ProfileItem title="Place Of Birth">{el.place_of_birth}</ProfileItem>
                                            <ProfileItem title="Date Of Birth">{moment(el.date_of_birth).format('DD MMMM YYYY')}</ProfileItem>
                                            <ProfileItem title="Education">{el.education === null ? '-' : el.education.description}</ProfileItem>
                                            <ProfileItem title="Job">{el.job === null ? '-' : el.job}</ProfileItem>
                                        </Row>
                                    </div>
                                ))
                            }

                            <br />

                            {
                                empChildrens.map((el, key) => (
                                    <div key={key}>
                                        <Divider orientation="left"><Title level={4}>{key === 0 ? 'Children' : null}</Title></Divider>
                                        <ActionButton editable deleteable onEdit={() => editChildren(el)} onDelete={() => deleteChildren(el.emp_children_id)} />
                                        <Row>
                                            <ProfileItem title="Name">{el.name}</ProfileItem>
                                            <ProfileItem title="Gender">{el.gender.description}</ProfileItem>
                                            <ProfileItem title="Place Of Birth">{el.place_of_birth}</ProfileItem>
                                            <ProfileItem title="Date Of Birth">{moment(el.date_of_birth).format('DD MMMM YYYY')}</ProfileItem>
                                            <ProfileItem title="Education">{el.education === null ? '-' : el.education.description}</ProfileItem>
                                            <ProfileItem title="Job">{el.job === null ? '-' : el.job}</ProfileItem>
                                        </Row>
                                    </div>
                                ))
                            }
                        </div>
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => defaultValues.source === 'Spouse' ? saveSpouse(v) : saveChildren(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item name={defaultValues.source === 'Spouse' ? "emp_spouse_id" : "emp_children_id"} hidden>
                                    <Input placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                    <Input placeholder="Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Gender" name="gender" rules={[{ required: true }]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Gender" optionFilterProp="children" onChange={changeGender} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            gender.map((el, key) => (
                                                <Option key={key} value={el.detail_code}>{el.description}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Place Of Birth" name="place_of_birth" rules={[{ required: true }]}>
                                    <Input placeholder="Place Of Birth" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Date Of Birth" name="date_of_birth" rules={[{ required: true }]}>
                                    <DatePicker placeholder="Date Of Birth" format="DD MMMM YYYY" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Last Education" name="education" rules={[{ required: false }]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Last Education" optionFilterProp="children" onChange={changeEducation} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            education.map((el, key) => (
                                                <Option key={key} value={el.detail_code}>{el.description}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Job" name="job" rules={[{ required: false }]}>
                                    <Input placeholder="Job" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default Spouse;
