import React, { useState, useEffect } from 'react';
import { Card, Space } from 'antd';
import { PageTitle, DataTable, CustomButton, Spinner } from '../../../../components';
import { SentralModel } from '../../../../models/SentralModel';
import { ZoomInOutlined } from '@ant-design/icons'

const VoteResult = () => {
    const [loaded, setLoaded] = useState(true)
    const [vote, setVote] = useState([])

    const fetchVote = () => {
        setLoaded(false);
        SentralModel.list('Vote', { fields: 'vote_id,title', orderBy: 'vote_id', order: 'ASC' }, 0).then((res) => {
            setVote(res.data);
            setLoaded(true);
        })
    }

    useEffect(() => {
        fetchVote()
    }, []);

    const voteColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Vote Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <CustomButton icon={<ZoomInOutlined />} link to={"/report/vote-result/detail/" + row.vote_id} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Vote Result Report" breadcrumbs={[['Vote Result Report']]}></PageTitle>
            {
                (loaded) ?
                    <DataTable size="small" columns={voteColumn} dataSource={vote} bordered />
                    : <Spinner />
            }
        </Card>
    );
}

export default VoteResult;
