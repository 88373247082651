import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Select, DatePicker} from 'antd'
import {AddButton, EditButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {extractNumber, toIdr} from '../../../utils/currencyHandler'
import moment from 'moment'
import XLSX from 'xlsx';

const {Text} = Typography

const Index = () => {
    const [medicalForm] = Form.useForm()
    const [medicalAllowances, setMedicalAllowances] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [formImportModal, setFormImportModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [importAllowances, setImportAllowances] = useState([])

    const fetchMedicalAllowances = (year) =>{
        setLoaded(false)
        SentralModel.action('HumanResource','getMedicalAllowance',{year:year}).then(res=>{
            setMedicalAllowances(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchMedicalAllowances(new Date().getFullYear())
    }, []);
    
    const dataSource = [
        ...medicalAllowances.map( el => {
            return {
                id: el.emp_medical_allowance_id,
                employee_code: el.employee_code,
                employee_name: el.name,
                allowance: el.allowance
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            width:'60px',
            key: 'name',
        },
        {
          title: 'Employee Name',
          dataIndex: 'employee_name',
          key: 'employee_name',
        },
        {
          title: 'Allowance',
          dataIndex: 'allowance',
          key: 'allowance',
          render: (text) => (
            toIdr(text)
          )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editMedicalAllowance(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const editMedicalAllowance = (v) =>{
        setModalTitle('Edit Medical Allowance')
        medicalForm.setFieldsValue({
            employee_name: v.employee_name,
            allowance: toIdr(v.allowance),
        })
        setSelectedEmployee(v.employee_code)
        setFormModal(true)
    }

    const saveMedicalAllowance = (v) => {
        setSaving(true)
        let payload={
            employee_code: selectedEmployee,
            year:selectedYear,
            allowance: extractNumber(v.allowance)
        }
        SentralModel.action('HumanResource','setMedicalAllowance', payload).then((res)=>{
            setFormModal(false)
            fetchMedicalAllowances();
            message.success('Medical allowance saved',2);
            setSaving(false)
        })
    }

    const formatAllowance = (v) => {
        let f = toIdr(extractNumber(v.target.value))
        medicalForm.setFieldsValue({
            allowance:f
        })
    }

    const selectPeriod = (v) => {
        setSelectedYear(v)
        fetchMedicalAllowances(v)
    }

    // Import
    const addImport=()=>{
        setDefaultValues({
            emp_medical_allowance_id: 0,
        })
        setFormImportModal(true)
    }

    const handleFile = (file /*:File*/) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
        //   console.log(rABS, wb);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          /* Update state */
          setImportAllowances(data)
        //   console.log(data)
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const saveImportAllowance = (v) => {
        setSaving(true)
        let payload={
            emp_medical_allowance_id: v.emp_medical_allowance_id,
            year: moment(v.year).format('YYYY'),
            allowances: importAllowances
        }
        SentralModel.action('Medical', 'importMedicalAllowances', payload, v.emp_medical_allowance_id).then(()=>{
            fetchMedicalAllowances(new Date().getFullYear())
            message.success('Medical Allowance saved', 2)
            setFormImportModal(false)
            setSaving(false)
        }).catch(() => {
            message.destroy()
            message.error('Failed to add allowance', 2)
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Employee Medical Allowances" breadcrumbs={[['Medical Allowance']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Medical Allowance List</Text>
                        <AddButton right onAdd={addImport} title="Import Medical Allowance"/>
                    </div> }>
                    <div style={{float:'right'}} className="mb-3">
                        <Select style={{width:'200px'}} onChange={(v) => selectPeriod(v)} defaultValue={new Date().getFullYear()}>
                            {
                                [new Date().getFullYear(), new Date().getFullYear()-1].map((el, key) => (
                                    <Select.Option value={el} key={key}>{el}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                
                <FormModal form={medicalForm} title={modalTitle} submitForm={(v)=>saveMedicalAllowance(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="emp_medical_allowance_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Employee Name" name="employee_name" required rules={[{required:true}]}>
                        <Input placeholder="Employee Name" readOnly/>
                    </Form.Item>
                    <Form.Item label="Allowance" name="allowance" rules={[{required:true}]}>
                        <Input placeholder="Allowance" onChange={(v)=>formatAllowance(v)}/>
                    </Form.Item>
                </FormModal>

                {
                    (formImportModal) ?
                        <FormModal title='Import Medical Allowance' defaultValues={defaultValues} submitForm={(v)=>saveImportAllowance(v)} width={400} showModal={formImportModal} onCloseModal={() => setFormImportModal(false)} saving={saving}>
                            <Form.Item name="year" label="Year" rules={[{required:true}]}>
                                <DatePicker picker="year" style={{width:'100%'}} format='YYYY'/>
                            </Form.Item>
                            <Form.Item label="Excel File" name="excel_file">
                                <ImportFile handleFile={handleFile} />
                            </Form.Item>
                            <div style={{marginBottom: '10px'}}>
                                <a href={process.env.REACT_APP_API_FILE + "/template/f22c5bc5-266d-49ee-bb21-49dbd4e55c98.xlsx"} download>
                                    Click here to download template
                                </a>
                            </div>
                        </FormModal> 
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;

class ImportFile extends React.Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
      const files = e.target.files;
      if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
      return (
        <div className="form-group">
            <input
                type="file"
                className="form-control"
                id="file"
                accept=".xlsx"
                onChange={this.handleChange}
            />
        </div>
      );
    }
}