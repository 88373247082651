export const getQuery = (v) => {
    let query = window.location.search
    let loc = query.indexOf(v)
    if(loc === -1 ){
        return ''
    }else{
        query = query.slice(loc)
        let end = query.indexOf('&')
        if(end === -1){
            return query.slice(v.length+1)
        }else{
            return query.slice(v.length+1,end)
        }
    }
}