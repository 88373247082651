import React, { useEffect, useState } from 'react';
import { Card, Typography, Form, Input, Select, message, Space, Row, Col } from 'antd'
import { AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const { Text } = Typography
const { Option } = Select

const Index = () => {
    const [form] = Form.useForm()
    const [branches, setBranches] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);

    const fetchBranches = () => {
        SentralModel.list('Branch').then(res => {
            setLoaded(false)
            setBranches(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchCities = () => {
        SentralModel.list('City').then(res => {
            setLoaded(false)
            setCities(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchBranches()
        fetchCities()
    }, []);

    const dataSource = [
        ...branches.map(el => {
            return {
                id: el.branch_id,
                company_id: el.company_id,
                branch_name: el.branch_name,
                phone_no_1: el.phone_no_1,
                phone_no_2: el.phone_no_2,
                pic: el.pic,
                address: el.address,
                city_id: el.city_id,
                city_name: el.city.city_name
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Branch Name',
        dataIndex: 'branch_name',
        key: 'branch_name',
        //   width: '20%'
    },
    {
        title: 'Phone Number 1',
        dataIndex: 'phone_no_1',
        key: 'phone_no_1',
        //   width: '20%'
    },
    {
        title: 'Phone Number 2',
        dataIndex: 'phone_no_2',
        key: 'phone_no_2',
        //   width: '15%'
    },
    {
        title: 'PIC',
        dataIndex: 'pic',
        key: 'pic',
        //   width: '45%'
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        //   width: '45%'
    },
    {
        title: 'City',
        dataIndex: 'city_name',
        key: 'city_name',
        //   width: '45%'
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton onEdit={() => editBranches(row)} />
                    <DeleteButton onConfirm={() => deleteBranches(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    const addBranch = () => {
        setModalTitle('Add Branch')
        form.resetFields()
        form.setFieldsValue({ branch_id: 0 })
        setFormModal(true)
    }

    const editBranches = (v) => {
        setModalTitle('Edit Branch')
        form.setFieldsValue({
            branch_id: v.id,
            company_id: v.company_id,
            branch_name: v.branch_name,
            phone_no_1: v.phone_no_1,
            phone_no_2: v.phone_no_2,
            pic: v.pic,
            address: v.address,
            city_id: v.city_id
        })
        setFormModal(true)
    }

    const deleteBranches = (id) => {
        message.loading('deleting branch')
        SentralModel.deleteById('Branch', id).then((res) => {
            message.destroy()
            message.success('Branch deleted', 2)
            fetchBranches()
        })
    }

    const saveBranch = (v) => {
        setSaving(true)
        let payload = {
            branch_id: v.branch_id,
            company_id: v.company_id,
            branch_name: v.branch_name,
            phone_no_1: v.phone_no_1,
            phone_no_2: v.phone_no_2,
            pic: v.pic,
            address: v.address,
            city_id: selectedCity
        }
        SentralModel.save('Branch', payload, v.branch_id).then((res) => {
            setFormModal(false)
            fetchBranches()
            message.success('Branch saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeCity = e => {
        setSelectedCity(e)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Branches" breadcrumbs={[['Branch']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Company List</Text>
                        <AddButton right onAdd={addBranch} title="New Branch" />
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            : <Spinner />
                    }
                </Card>
                {
                    (formModal) ?
                        <FormModal title={modalTitle} form={form} submitForm={(v) => saveBranch(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item name="branch_id" hidden>
                                        <Input placeholder="input placeholder" />
                                    </Form.Item>
                                    <Form.Item label="Branch Name" name="branch_name" rules={[{ required: true }]}>
                                        <Input placeholder="Branch Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="City" name="city_id" rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select City" optionFilterProp="children" onChange={changeCity} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                cities.map((el, key) => (
                                                    <Option key={key} value={el.city_id}>{el.city_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Phone Number 1" name="phone_no_1" rules={[{ required: true }]}>
                                        <Input placeholder="Phone Number 1" />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Phone Number 2" name="phone_no_2" rules={[{ required: true }]}>
                                        <Input placeholder="Phone Number 2" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="PIC" name="pic" rules={[{ required: true }]}>
                                        <Input placeholder="PIC" />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Address" name="address" rules={[{ required: true }]}>
                                        <Input.TextArea placeholder="Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    );
}

export default Index;
