import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Switch, Tag, Select} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'

const {Text} = Typography
const {Option} = Select

const Index = () => {
    const [form] = Form.useForm()
    const [assets, setAssets] = useState([])
    const [categories, setCategories] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({asset_item_id: 0, category_id: '', item_code: '', item_name: '', uom: '', is_active: true})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [status, setStatus] = useState(false)
    const [nextNumber, setNextNumber] = useState('0001')

    const fetchCategories = () =>{
        SentralModel.list('AssetCategory').then(res=>{
            setCategories(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchAssets = () =>{
        SentralModel.list('AssetItem').then(res=>{
            setAssets(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAssets()
        fetchCategories()
    }, []);
    
    const dataSource = [
        ...assets.map( el => {
            return {
                id: el.asset_item_id,
                item_code: el.item_code,
                item_name: el.item_name,
                category_id: el.category_id,
                category_name: el.category ? el.category.name : null,
                uom: el.uom,
                is_active: el.is_active
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
          title: 'Item Code',
          dataIndex: 'item_code',
          key: 'item_code',
        },
        {
          title: 'Category',
          dataIndex: 'category_name',
          key: 'category_name',
        },
        {
          title: 'Name',
          dataIndex: 'item_name',
          key: 'item_name',
        },
        {
          title: 'UoM',
          dataIndex: 'uom',
          key: 'uom',
        },
        {
          title: 'Status',
          dataIndex: 'is_active',
          align:'center',
          key: 'is_active',
          render: (text) => (
            <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'Inactive'}</Tag>
          )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editItem(row)}/>
                        <DeleteButton onConfirm={() => deleteItem(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addItem=() => {
        setModalTitle('Add Item')
        let x = defaultValues
        x = {
            asset_item_id: 0,
            category_id: '',
            item_code: '',
            item_name: '',
            uom: '',
            is_active: true
        }
        form.setFieldsValue(x)
        setDefaultValues(x)

        setStatus(true)
        setFormModal(true)
    }

    const editItem = (v) => {
        setModalTitle('Edit Item')
        let x = defaultValues
        x = {
            asset_item_id: v.id,
            category_id: v.category_id,
            item_code: v.item_code,
            item_name: v.item_name,
            uom: v.uom,
            is_active: parseInt(v.is_active)
        }
        form.setFieldsValue(x)
        setDefaultValues(x)

        setStatus(v.is_active)
        setFormModal(true)
    }

    const deleteItem = (id) => {
        message.loading('deleting item')
        SentralModel.deleteById('AssetItem', id).then((res)=>{
            message.destroy()
            message.success('Item deleted', 2)
            let tmp = assets
            let index = tmp.findIndex(el => el.asset_item_id===id)
            tmp.splice(index, 1);
            setAssets([])
            setAssets(tmp)
        })
    }

    const saveItem = (v) => {
        setSaving(true)
        let payload={
            asset_item_id: v.id,
            category_id: v.category_id,
            item_code: v.item_code,
            item_name: v.item_name,
            uom: v.uom,
            is_active: status ? 1 : 0
        }
        SentralModel.save('AssetItem', payload, v.asset_item_id).then((res)=>{
            setFormModal(false)
            fetchAssets()
            message.success('Item saved',2);
            SentralModel.save('AssetCategory', {next_number: nextNumber}, v.category_id)
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeCategory = (v) =>{
        let prefix = categories.find(element => element.asset_category_id === v).code;
        let nextNumber = categories.find(element => element.asset_category_id === v).next_number;

        let x = defaultValues
        x.category_id = v
        x.item_code = prefix + nextNumber
        form.setFieldsValue(x)

        setNextNumber(("0000" + (parseInt(nextNumber) + 1)).slice(-4))
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Items" breadcrumbs={[['Asset'],['Item']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Item List</Text>
                        <AddButton right onAdd={addItem} title="New Item"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>

                {
                    (formModal) ?
                    <FormModal form={form} title={modalTitle} submitForm={(v)=>saveItem(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="asset_item_id" hidden>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Category" name="category_id" rules={[{required:true}]}>
                            <Select placeholder="Select Category" onChange={(v) => changeCategory(v)}>
                                {
                                    categories.map((el, key)=>(
                                        <Option key={key} value={el.asset_category_id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Item Code" name="item_code" rules={[{required:true}]}>
                            <Input placeholder="Item Code" readOnly/>
                        </Form.Item>
                        <Form.Item label="Name" name="item_name" rules={[{required:true}]}>
                            <Input placeholder="Name" onChange={(v) => {
                                let x = defaultValues
                                x.item_name = v.target.value
                                form.setFieldsValue(x)
                            }}/>
                        </Form.Item>
                        <Form.Item label="UoM" name="uom" rules={[{required:true}]}>
                            <Input placeholder="UoM" onChange={(v) => {
                                let x = defaultValues
                                x.uom = v.target.value
                                form.setFieldsValue(x)
                            }}/>
                        </Form.Item>
                        <Form.Item label="Status" name="is_active" rules={[{required:true}]}>
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={defaultValues.is_active} onChange={(v) => {
                                let x = defaultValues
                                x.is_active = v
                                form.setFieldsValue(x)
                                setStatus(v)
                            }}/>
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
