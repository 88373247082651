import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs, Typography, Descriptions, Space } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, Spinner, ShowButton } from '../../../../components'
import authData from '../../../../helpers/authData'
import { SentralModel } from '../../../../models/SentralModel'
import { toIdr } from '../../../../utils/currencyHandler'
// import moment from 'moment'

const { TabPane } = Tabs;
const { Text } = Typography

const Index = () => {
    const [medicals, setMedicals] = useState(null)
    const [medicalInfo, setMedicalInfo] = useState(null);
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)

    const fetchMedical = () => {
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionMedical', payload).then(res => {
            setMedicals(res.data)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', { filter: [['code', 'SBMD']] }, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const fetchMedicalInfo = () => {
        SentralModel.action('Submission', 'getMedicalBalance', null, null).then((res) => {
            setMedicalInfo(res.data[0])
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', { category: 'MEDICAL', division_id: authData.division_id }).then((res) => {
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchMedical()
        fetchMedicalInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);

    const medicalData = (medicals) ? [
        ...medicals.map(el => {
            return {
                id: el.sub_medical_id,
                description: el.description,
                medical_amount: toIdr(el.medical_amount),
                claimed_amount: toIdr(el.claimed_amount),
                attachment: el.attachment,
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ] : [];

    const medicalColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Necessity',
        dataIndex: 'description',
        key: 'description'
    }, {
        title: 'Medical Amount',
        dataIndex: 'medical_amount',
        key: 'medical_amount',
    }, {
        title: 'Claimed Amount',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount',
    }, {
        title: 'Attachment',
        dataIndex: 'attachment',
        render: (text) => (
            (text) ?
                <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + text}>
                    Show Attachment
                </a> : '-'
        )
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <Space>
                <ShowButton link to={'/medical/' + row.id} />
                {/* <ShowButton onShow={() => showRequestLog(row)}/> */}
            </Space>
        )
    }
    ];
    return (
        <div>
            <Card>
                <PageTitle title="Medical" breadcrumbs={[['Medical']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Request Medical</Text>
                        <NewButton disabled={!available || !medicalInfo || (medicalInfo && medicalInfo.balance <= 0)} right to="/medical/create" title={(!available) ? "Approval not available" : !medicalInfo ? "You don't have balance" : (medicalInfo && medicalInfo.balance <= 0) ? "You don't have balance" : "Request Medical"} />
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Medical Allowance</Text>}>
                                    {
                                        (medicalInfo) ? toIdr(medicalInfo.allowance) : 'Rp 0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Used</Text>}>
                                    {
                                        (medicalInfo) ? toIdr(medicalInfo.used) : 'Rp 0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Balance</Text>}>
                                    {
                                        (medicalInfo) ? toIdr(medicalInfo.balance > 0 ? medicalInfo.balance : 0) : 'Rp 0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br />
                {
                    (medicals) ?
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={medicalColumn} dataSource={medicalData.filter(el => el.status === 'WAITING')} bordered />
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                                <DataTable size="small" columns={medicalColumn} dataSource={medicalData.filter(el => el.status === 'APPROVED')} bordered />
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={medicalColumn} dataSource={medicalData.filter(el => el.status === 'REJECTED')} bordered />
                            </TabPane>
                        </Tabs>
                        : <Card><Spinner /></Card>
                }
            </Card>
        </div>
    );
}

export default Index;
