import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, Space, DatePicker, message} from 'antd'
import {AddButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable, CustomButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {toFullDate} from '../../../utils/dateHandler'
import {ZoomInOutlined} from '@ant-design/icons'
import moment from 'moment'

const {Text} = Typography
const Index = () => {
    const [votes, setVotes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)

    const fetchVotes = () =>{
        SentralModel.list('Vote').then(res=>{
            setVotes(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVotes()
    }, []);

    const dataSource = [
        ...votes.map( el => {
            return {
                id: el.vote_id,
                title: el.title,
                due_date: el.due_date,
                url: el.url
            }
        })
    ]

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
            width: '5%'
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
            title: 'Due Date',
            render: (row) =>(
                <div className="text-center">
                    <span>
                        {toFullDate(row.due_date)}
                    </span>
                </div>
            )
        },
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
        },
        {
            title: 'Action',
            width: 120,
            render:(row) => (
                <div className="text-center">
                    <Space>
                        <CustomButton icon={<ZoomInOutlined />} link to={"/management/vote/detail/" + row.id}/>
                        <DeleteButton onConfirm={() => deleteVote(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addVote = () => {
        setModalTitle('Add Voting')
        setDefaultValues({
            vote_id: 0,
            title: null,
            due_date: null
        })
        setFormModal(true)
    }

    const saveVote = (v) => {
        setSaving(true)

        var urlVoting = '';
        if (v.vote_id === 0) {
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 10; i++) {
                urlVoting += possible.charAt(Math.floor(Math.random() * possible.length));
            }
        }
        
        let payload = {
            vote_id: v.vote_id,
            title: v.title,
            due_date: moment(v.due_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            url: process.env.REACT_APP_APP_URL + "/voting/" + urlVoting
        }
        SentralModel.save('Vote', payload, v.vote_id).then((res)=>{
            setFormModal(false)
            fetchVotes();
            message.success('Vote saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteVote = (id) => {
        message.loading('Deleting voting')
        SentralModel.deleteById('Vote', id).then((res)=>{
            message.destroy()
            message.success('Voting deleted', 2)
            let tmp = votes
            let index = tmp.findIndex(el => el.vote_id === id)
            tmp.splice(index, 1);
            setVotes([])
            setVotes(tmp)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Votes" breadcrumbs={[['Vote']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Vote List</Text>
                        <AddButton right onAdd={addVote} title="New Vote"/>
                    </div> }>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner/>
                    }
                </Card>
                
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveVote(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="vote_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item label="Title" name="title" rules={[{required:true}]}>
                            <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item label="Due Date" name="due_date" rules={[{required:true}]}>
                            <DatePicker format="DD MMMM YYYY" style={{width:'100%'}}/>
                        </Form.Item>
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
