import React, { useState, useEffect } from 'react';
import {Card, Descriptions, Row, Col, Typography, Form,Input, InputNumber, Select, message} from 'antd'
import {PageTitle, EditButton,AddButton, FormModal, DeleteButton, Spinner} from '../../../components'
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler';
import {getQuery} from '../../../helpers/url'

const {Text} = Typography
const {Option} = Select


const Detail = () => {
    const [formModal, setFormModal] = useState(false)
    const [modalState, setModalState] = useState('add')
    const [saving, setSaving] = useState(false)
    const [schema, setSchema] = useState(null)
    const [approvers, setApprovers] = useState([])
    const [defaultValues, setDefaultValues] = useState({})
    const [employees, setEmployees] = useState([])
    const [loaded, setLoaded] = useState(false)
    
    const fetchApprovalData = (divisionId, schemaId) => {
        setLoaded(false)
        SentralModel.action('Approval', 'schemaDetail', 
            {schema_id: schemaId, division_id:divisionId}, 0)
            .then((res)=>{
                setSchema({
                    ...res.data.schema,
                    division:res.data.division
                })
                setApprovers(res.data.approver)
                let x=[]
                res.data.approver.forEach(el => {
                    x.push(el.employee_code)
                })
                setLoaded(true)
            })
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', {fields:"employee_code,name", filter:[['is_active','=',1]], orderBy: 'name', order: 'asc'}).then((res)=>{
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        const divisionId = getQuery('division')
        const schemaName = getQuery('schema')
        fetchEmployees()
        fetchApprovalData(divisionId, schemaName)
    }, []);

    const addApprover = () =>{
        setModalState('add')
        setDefaultValues({id:0,level : approvers.length+1, approver: null})
        setFormModal(true)
    }

    const editApprover = (v) => {
        setModalState('edit')
        setDefaultValues({id:v.approval_approver_id, level : v.level, approver: v.employee_code})
        setFormModal(true)
    }

    const updateApprover= (v) =>{
        setSaving(true)
        const approverData = {
            approval_schema_id:getQuery('schema'),
            division_id:getQuery('division'),
            employee_code: v.approver,
            level:v.level,
        }
        SentralModel.save('ApprovalApprover', approverData, v.id).then(()=>{
            message.success('Schema Updated', 2)
            setFormModal(false)
            setSaving(false)
            fetchApprovalData(getQuery('division'),getQuery('schema'))
        })
    }
    
    const removeApprover = (v) =>{
        setLoaded(false)
        SentralModel.action('Approval','removeApprover', {id:v.approval_approver_id}, 0).then(() => {
            message.success('Schema Updated', 2)
            fetchApprovalData(getQuery('division'),getQuery('schema'))
        })
    }


    return (
        <Card className="content-container">
            <PageTitle title="Edit Approval Schema" breadcrumbs={[['Approval Schema','/management/approval-schema'],['Detail']]}></PageTitle>
            {
                (loaded) ? 
                    <Row justify="space-between">
                        <Col span={8}>
                            <Descriptions layout="horizontal" column={1} bordered>
                                <Descriptions.Item label={<span className="text-bold">Schema Name</span>}>{(schema) ? schema.description : null}</Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Category</span>}>{(schema) ? schema.category : null}</Descriptions.Item>
                                {
                                    (schema && schema.limit1) ? 
                                    <Descriptions.Item label={<span className="text-bold">Range</span>}>{toIdr(schema.limit1)} - {toIdr(schema.limit2)}</Descriptions.Item>
                                    : null
                                }
                                <Descriptions.Item label={<span className="text-bold">Division</span>}>{(schema) ? schema.division.division_name : null}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={15}>
                            <Card title={
                                <div className="full-width">
                                    <Text strong style={{float:'left'}}>Schema Approver</Text>
                                    <AddButton right onAdd={() => addApprover()} title="Add Approver"/>
                                </div>
                            } bordered className="full-width">
                                <table className="table-default table-collapse">
                                    <thead>
                                        <tr>
                                            <th style={{width:'7%'}}>Level</th><th>Approver</th><th  style={{width:'7%'}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            approvers.map((el, key) => (
                                                <tr key={key}>
                                                    <td className="text-center text-top" style={{width:'5%'}}>{el.level}</td>
                                                    <td>{el.employee.name}</td>
                                                    <td className="text-center" style={{width:'10%'}}>
                                                        <EditButton onEdit={() => editApprover(el)} />
                                                        <DeleteButton onConfirm={() => removeApprover(el)} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </tbody>
                                </table>
                            
                            </Card>
                        </Col>
                    </Row>
                : <Spinner/>}

            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={(modalState==='add')?'Add Approver':'Edit Approver'} submitForm={(v)=>updateApprover(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="id" hidden>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Level" name="level" rules={[{required:true}]}>
                            <InputNumber min={1} max={approvers.length+1} placeholder="input placeholder" readOnly={modalState==='add'} style={{width:'100%'}}/>
                        </Form.Item>
                        <Form.Item label="Approver Name" name="approver" rules={[{required:true}]}>

                            <Select 
                                showSearch 
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                    employees.map((el, key) => (
                                        <Option key={key} value={el.employee_code}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal> 
                : null
            }

        </Card>
    );
}

export default Detail;
