import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Form, Input, Typography, Select, Button} from 'antd';
import {DeleteOutlined} from '@ant-design/icons'
import {SubmitButton, AddButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const {Option} = Select

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    const [itemStationery, setItemStationery] = useState([{item_id: null, quantity: null}])
    const [items, setItems] = useState([]);

    const fetchItems = () => {
        SentralModel.list('ItemStationery', {orderBy:"item_name", order:"asc"}).then(res=>{
            setItems(res.data)
        })
    }

    useEffect(() => {
        fetchItems()
    }, []);

    const addItemStationery = () =>{
        let x = itemStationery
        let key = x.length
        x.push({item_id: null, quantity: null})
        form.setFieldsValue({
            [`item_name[${key}]`]: null,
            [`item_qty[${key}]`]: null,
            [`item_uom[${key}]`]: null
        })
        setItemStationery(x)
        forceUpdate()
    }

    const changeItem = (v, key) => {
        if(v){
            let select = items.find(element => element.item_id === v);
            form.setFieldsValue({[`item_uom[${key}]`]: select.uom})

            let x=itemStationery
            x[key].item_id = v
            setItemStationery(x)
        }
    }

    const submitStationery = (v) =>{
        let item = {
            stationery: itemStationery
        }
        props.onSubmit(item)
    }

    const removeItem = (key) =>{
        let item = itemStationery
        item.splice(key,1)
        setItemStationery(item)
        forceUpdate()
    }

    return (
        <Form size="middle" layout="vertical" form={form} onFinish={submitStationery}>
            <Row justify="center">
                <Col span={20}>
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{float:'left'}}>Office Stationery Items</Text>
                                <AddButton right onAdd={() => addItemStationery()} title="Add Item"/>
                            </div>}>
                            
                            <table className="table-item" style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th style={{width:'50%'}}>Item</th>
                                        <th style={{width:'35%'}}>Quantity</th>
                                        <th style={{width:'15%'}}>UoM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemStationery.map((el, key) =>(
                                            <tr key={'atk_item'+key}>
                                                <td>
                                                    <Form.Item name={`item_name[${key}]`} rules={[{required:true}]}>
                                                        <Select showSearch style={{ width: "100%" }} placeholder="Select item" optionFilterProp="children" onChange={(v) => changeItem(v, key)} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {
                                                                items.map((el, key) => (
                                                                <Option key={key} value={el.item_id}>{el.item_name}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={`item_qty[${key}]`} rules={[{required:true}]}>
                                                        <Input type="number" onChange={(v) => {
                                                            let x=itemStationery
                                                            x[key].quantity = v.target.value
                                                            setItemStationery(x)
                                                        }}/>
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={`item_uom[${key}]`} rules={[{required:true}]}>
                                                        <Input readOnly/>
                                                    </Form.Item>
                                                </td>
                                                <td className="text-center" style={{width:'60px'}}>
                                                    {
                                                        ((key+1)===itemStationery.length && itemStationery.length>1) ? 
                                                        <Button type="text" danger onClick={() => removeItem(key)} icon={<DeleteOutlined/>}/>
                                                        : null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Card>
                    <br/>
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
