import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Form, Input, message} from 'antd';
import {PageTitle, SubmitButton} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'
import {useHistory, useParams} from 'react-router-dom'

const {TextArea} = Input

const Create = () => {
    const history = useHistory()
    const {testId} = useParams()
    const [saving, setSaving] = useState()
    const [form] = Form.useForm()
    const [questions, setQuestions] = useState([])

    const fetchQuestion = () =>{

        SentralModel.list('TestEssay', { filter:[['test_id', testId]], orderBy: 'question_number', order: 'asc'}).then(res=>{
            if(res.data.length === 0){
                let questions = [];
                for (var i = 1; i <= 10; i++) {
                    questions.push({
                        number: i,
                        question: ''
                    });
                }
                setQuestions(questions)
            } else {
                let questions = [];
                res.data.forEach((el, key) => {
                    questions.push({
                        number: el.question_number,
                        question: el.question,
                    });

                    form.setFieldsValue({
                        [`question[${key}]`]: el.question,
                    })
                })
                setQuestions(questions)
            }
        }).finally(()=>{
        })
    }

    const submitQuestion = (v) =>{
        let questions = [];
        for (var i = 1; i <= 10; i++) {
            questions.push({
                test_id: testId,
                question_number: i,
                question: form.getFieldValue(`question[${i-1}]`),
            });
        }

        setSaving(true)
        let payload = {
            test_id: testId,
            question: questions
        }
        SentralModel.action('Test', 'saveEssay', payload, 0).then(()=>{
            message.destroy()
            message.success('Test essay saved', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/management/test/essay');
            }, 1000);
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
            setSaving(false)
        })
    }
    
    useEffect(() => {
        fetchQuestion()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className="content-container">
            <PageTitle title="Test Essay" breadcrumbs={[['Test Essay','/management/test/essay'],['Create']]}></PageTitle>
            <Form form={form} size="middle" layout="vertical" onFinish={submitQuestion}>
                <Row justify="center">
                    {
                        questions.map((item, key) => (
                            <Col span={20} className="mb-2">
                                <Row justify="space-between">
                                    <Col span={24}>
                                        <Form.Item name={`question[${key}]`} label={'Question No .'+item.number} rules={[{required:true}]}>
                                            <TextArea autoSize={{minRows:2}}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                    <Col span={20} className="text-right">
                        <SubmitButton loading={saving}/>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default Create;
