import React from 'react';
import { Card, Tabs } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment';
import { DataTable, ShowButton } from '../../../../components'
import { toFullDate } from '../../../../utils/dateHandler'

const { TabPane } = Tabs;

const Index = (props) => {
    const overtimeColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Necessity',
        dataIndex: 'necessity',
        key: 'necessity'
    }, {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    }, {
        title: 'Time',
        align: 'center',
        render: (row) => (
            <span>{moment(row.from_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.to_time, 'HH:mm:ss').format('HH:mm')}</span>
        )
    }, {
        title: 'Action',
        width: '10%',
        render: (row) => (
            <div className="text-center">
                <ShowButton link to={'/submission-list/overtime/' + row.id} />
            </div>
        )
    }
    ];

    const requestData = props.data !== null ? [
        ...props.data?.map((el) => {
            return {
                id: el?.request_id,
                name: el?.name,
                necessity: el?.description,
                date: toFullDate(el?.from_date),
                from_time: el?.from_time,
                to_time: el?.to_time,
                division_name: el?.division_name,
                status: el?.status,
                realization_status: el?.realization_status,
                submitted_at: el?.submitted_at
            }
        })
    ] : []

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={overtimeColumn} dataSource={requestData.filter(el => el?.status === 'WAITING')} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={overtimeColumn} dataSource={requestData.filter(el => el?.status === 'APPROVED')} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={overtimeColumn} dataSource={requestData.filter(el => el?.status === 'REJECTED')} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
