import React, {useEffect, useState} from 'react';
import {Card, Row, Col, DatePicker, Space, Button} from 'antd';
import {Line} from 'react-chartjs-2'
import DashboardItem from './DashboardItem'
import {PageTitle, Spinner} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'
import {ZoomInOutlined} from '@ant-design/icons'
import moment from 'moment'

const {RangePicker} = DatePicker

const Late = () => {

    const [loaded, setLoaded] = useState(true);
    const [lates, setLates] = useState([]);
    const [selectedDate, setSelectedDate] = useState([null,null])

    const fetchPeriodAttendance = (date) => {
        setLoaded(false)
        if(date){
            SentralModel.action('Dashboard', 'late', {from:date.from,to:date.to}, 0).then((res) => {
                setLates(res.data)
                setLoaded(true)
            })
        }else{
            SentralModel.action('Dashboard', 'late', {from:'2021-01-01',to:'2021-01-10'}, 0).then((res) => {
                setLates(res.data)
                setLoaded(true)
            })
        }
    }

    const filterPeriodAttendance = () =>{
        fetchPeriodAttendance({from:moment(selectedDate[0]).format('YYYY-MM-DD'),to:moment(selectedDate[1]).format('YYYY-MM-DD')})
    }

    useEffect(() => {
        fetchPeriodAttendance({from:moment().startOf('month').format('YYYY-MM-DD'),to:moment().endOf('month').format('YYYY-MM-DD')})
        setSelectedDate([moment().startOf('month'),moment().endOf('month')])
    }, []);

    const data= {
        labels: lates.map(el => el.date),
        datasets: [{
            label: 'Total Late',
            data: lates.map(el => el.late),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2
        }]
    }

    return (
        <Card className="content-container">
            <PageTitle title="Late" breadcrumbs={[['Late']]}></PageTitle>
            {
                loaded ?
                <div>
                    <Row className="mb-3">
                        <Col span={24} className="p-1">
                            <DashboardItem title={
                                <div>
                                    Late Summary
                                    <Space style={{float:'right'}}>
                                        <RangePicker onChange={(v) =>setSelectedDate(v)} style={{width:'300px', float:'right'}} defaultValue={selectedDate}/>
                                        <Button icon={<ZoomInOutlined />} loading={!loaded} onClick={() => filterPeriodAttendance()} type="primary">
                                            Show
                                        </Button>
                                    </Space>
                                </div>
                            } height={400}>
                            <Line
                                data={data}
                                width={100}
                                height={320}
                                options={{ maintainAspectRatio: false }}
                                />
                            </DashboardItem>
                        </Col>
                    </Row>
                </div>
                : <Card><Spinner /></Card>
            }

        </Card>
    );
}

export default Late;
