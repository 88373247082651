import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, message, Space, Row, Col, Checkbox} from 'antd'
import {EditButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const CustomAttendanceArea = () => {
    const forceUpdate = useForceUpdate()
    const [employees, setEmployees] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [selectedArea, setSelectedArea] = useState([])
    const [areas, setAreas] = useState([])

    const fetchEmployees = () =>{
        setLoaded(false)
        SentralModel.action('Area', 'getEmployeeList',{}).then(res=>{
            setEmployees(res.data)
            setLoaded(true)
        })
    }

    const fetchAreas = () =>{
        SentralModel.list('Area', {orderBy:'area_name',order:'asc'},0).then((res)=>{
            setAreas(res.data)
        })
    }

    useEffect(() => {
        fetchEmployees()
        fetchAreas()
    }, []);
    
    const dataSource = [
        ...employees.map( el => {
            let area = '';
            el.attendance_area.map(item => area += item.area.area_name+',' );

            return {
                employee_code: el.employee_code,
                name: el.name,
                division_name: (el.division) ? el.division.division_name : '',
                position_name: (el.position) ? el.position.position_name : '',
                attendance_area:  el.attendance_area,
                area: area,
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
            width:'70px'
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },{
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
        },{
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name',
        },{
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
        },{
            title: 'Action',
            align:'center',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editAreas(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const editAreas = (v) =>{
        setSelectedEmployee(v.employee_code)
        let selected = []
        v.attendance_area.forEach(el => {
            selected.push(el.area_id)
        });
        setSelectedArea(selected)
        setFormModal(true)
    }

    const saveAttendanceArea = (v) => {
        setSaving(true)
        let payload={
            employee_code: selectedEmployee,
            areas : selectedArea
        }
        SentralModel.action('Area','SaveAttendanceArea', payload, v.area_id).then((res)=>{
            setFormModal(false)
            fetchEmployees()
            message.success('Area saved',2);
            setSaving(false)
        })
    }

    const selectArea = (e, v) => {
        let p = selectedArea
        if(e.target.checked){
            p.push(v)
        }else{
            p.splice(p.findIndex((el) => el===v), 1)
        }
        setSelectedArea(p)
        forceUpdate()
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Setup Attendance Area" breadcrumbs={[['Area','/setting/area'],['Attendance Area','/setting/division-attendance-area'],['Custom Attendance Area']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Setup Attendance Area</Text>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal title="Pilih Area" submitForm={(v)=>saveAttendanceArea(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        {
                            areas.map((area, i) => (
                                <div className="full-width" key={"area_"+i}>
                                    <Row>
                                        <Col span={21}>
                                            {area.area_name}
                                        </Col>
                                        <Col span={3} className="text-right">
                                            <Form.Item name={'select_'+area.area_id} style={{padding:0, margin:'0px'}}>
                                                <Checkbox checked={selectedArea.includes(area.area_id)} onChange={(v) => selectArea(v,area.area_id)}>
                                                    {area.description}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            ))
                        }
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default CustomAttendanceArea;
