import { Card, Tabs } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { DataTable, ShowButton } from '../../../components'
import { toFullDate } from '../../../utils/dateHandler'

const { TabPane } = Tabs;

const Index = (props) => {
    const attendanceColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        }, {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: 'Date',
            dataIndex: 'attendance_date',
            key: 'attendance_date',
        }, {
            title: 'Clock time',
            dataIndex: 'attendance_time',
            key: 'attendance_time',
        }, {
            title: 'Action',
            width: '10%',
            render: (row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/attendance/' + row.id} />
                </div>
            ),
            key: 'action'
        }
    ];

    const attendanceData = props.data !== null ? [
        ...props.data?.map((el) => {
            return {
                id: el?.request_id,
                name: el?.name,
                attendance_date: toFullDate(el?.attendance_date),
                attendance_time: el?.attendance_time,
                status: el?.status,
                submitted_at: el?.submitted_at
            }
        })
    ] : []

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData.filter(el => el.status === 'WAITING')} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData.filter(el => el.status === 'APPROVED')} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData.filter(el => el.status === 'REJECTED')} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
