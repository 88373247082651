import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, DatePicker, message } from 'antd';
import { PageTitle, SubmitButton, Uploader, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins"

const { RangePicker } = DatePicker
const Detail = () => {
    const history = useHistory()
    const { announcementId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()
    const [attachmentFile, setAttachmentFile] = useState([])
    const [uploading, setUploading] = useState(false)
    const [content, setContent] = useState('');

    const fetchAnnouncements = (announcementId, form) => {
        setLoaded(false)
        if (announcementId === '0') {
            form.resetFields();
        } else {
            SentralModel.list('Announcement', { filter: [['announcement_id', announcementId]], orderBy: 'end_date', order: 'desc' }).then(res => {
                form.setFieldsValue({
                    announcement_id: res.data[0].announcement_id,
                    subject: res.data[0].subject,
                    announcement: res.data[0].announcement,
                    date: [moment(res.data[0].start_date, 'YYYY-MM-DD'), moment(res.data[0].end_date, 'YYYY-MM-DD')],
                })
                setContent(res.data[0].announcement);
            })
        }
        setLoaded(true)
    }
    useEffect(() => {
        fetchAnnouncements(announcementId, form)
    }, [announcementId, form]);

    const saveAnnouncement = (v) => {
        setSaving(true)

        let data = {
            announcement_id: announcementId,
            subject: v.subject,
            announcement: content,
            start_date: moment(v.date[0], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            end_date: moment(v.date[1], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            attachments: attachmentFile
        }

        SentralModel.action('Announcement', 'saveAnnouncement', data, announcementId).then((res) => {
            message.success('Announcement saved', 2);
            setTimeout(() => {
                history.push('/management/announcement');
            }, 1000);
            setSaving(false)
        })
    }

    const handleEditorChange = (content) => {
        setContent(content);
    }

    const fileUploaded = (v) => {
        // console.log(v.message)
        attachmentFile.push(v.message)
        setAttachmentFile(attachmentFile)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Announcement" breadcrumbs={[['Announcement', '/management/announcement'], ['Detail']]}></PageTitle>
            {
                loaded ?
                    <Form size="middle" form={form} layout="vertical" onFinish={saveAnnouncement}>
                        <Form.Item name="announcement_id" hidden>
                            <Input placeholder="Announcement ID" />
                        </Form.Item>
                        <Form.Item label="Subject" name="subject" required rules={[{ required: true }]}>
                            <Input placeholder="Subject" />
                        </Form.Item>
                        <SunEditor setOptions={{
                            showPathLabel: false,
                            minHeight: "100vh",
                            maxHeight: "100vh",
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                        }} height={500} setContents={content} onChange={(value) => handleEditorChange(value)} />
                        {/* <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={content}
                            init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={(value) => handleEditorChange(value)}
                        /> */}
                        <Form.Item label="Date" name="date" required rules={[{ required: true }]}>
                            <RangePicker style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Attachment">
                            <Uploader dragger folder="announcement" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                        <Row justify="space-around">
                            <Col span={24} className="text-right">
                                <SubmitButton loading={saving || uploading} />
                            </Col>
                        </Row>
                    </Form>
                    : <Spinner />
            }
        </Card>
    );
}

export default Detail;
