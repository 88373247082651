import React, {useState} from 'react';
import {Form, Input, message} from 'antd';
import {FormModal} from '../../components';
import {SentralModel } from '../../models/SentralModel';

const ChangePassword = (props) => {
    const [saving, setSaving] = useState(false)
    const [formModal, setFormModal] = useState(true);

    const saveChangePassword = (v) => {
        setSaving(true)
        SentralModel.action('Profile', 'changePassword', v, 0).then((res)=>{
            message.destroy()
            message.success('Password has been changed', 1)
            setSaving(false)
            setFormModal(false);
            window.location.reload();
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
            setSaving(false)
        })
    }

    const closeModal = () => {
        setFormModal(false);
        window.location.reload();
    }

    return (
        <div>
            {
                (formModal) ?
                    <FormModal title={'Change Password'} submitForm={(v) => saveChangePassword(v)} width={500} showModal={formModal} onCloseModal={() => closeModal()} saving={saving}>
                        <Form.Item name="password" label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let error;
                                        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                        if (getFieldValue('password').length < 8) {
                                            error = 'Password minimum 8 character';
                                        } else if (regex.exec(getFieldValue('password')) == null) {
                                            error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                        } else {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(error))
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item name="confirm_password" label="Confirm Password" dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </FormModal> :
                null
            }
        </div>
    );
}

export default ChangePassword;
