import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, message, Space, Row, Col, Checkbox, Button, Select} from 'antd'
import {SettingOutlined} from '@ant-design/icons'
import {EditButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {Link} from 'react-router-dom'

const {Text} = Typography
const {Option} = Select;

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const AttendanceArea = () => {
    const forceUpdate = useForceUpdate()
    const [divisions, setDivisions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [type, setType] = useState('all')
    const [selectedArea, setSelectedArea] = useState([])
    const [areas, setAreas] = useState([])

    const fetchDivisions = () => {
        setLoaded(false)
        SentralModel.action('Area', 'getDivisionList',{}).then(res=>{
            setDivisions(res.data)
            setLoaded(true)
        })
    }

    const fetchAreas = () => {
        SentralModel.list('Area', {filter: [['area_name', '!=', 'All Area']], orderBy:'area_name', order:'asc'}, 0).then((res)=>{
            setAreas(res.data)
        })
    }

    useEffect(() => {
        fetchDivisions()
        fetchAreas()
    }, []);
    
    const dataSource = [
        ...divisions.map( el => {
            return {
                division_id: el.division_id,
                division_name: el.division_name,
                attendance_area: el.attendance_area
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            className:'text-top',
            key: 'name',
            width:'70px'
        },{
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            className:'text-top',
        },{
            title: 'Attendance Area',
            className:'text-top',
            render: (row) => (
                <ol style={{paddingLeft:'15px'}}>
                    {
                        row.attendance_area.map((el, key) => (
                            <li key={key}>{el.area.area_name}</li>
                        ))
                    }
                </ol>
            )
        },{
            title: 'Action',
            align:'center',
            className:'text-top',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editAreas(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const editAreas = (v) => {
        // console.log(v)
        setSelectedDivision(v.division_id)
        let selected = []
        v.attendance_area.forEach(el => {
            selected.push(el.area_id)
        });
        setSelectedArea(selected)
        selected.includes(1) === true ? setType('all') : setType('select')
        setFormModal(true)
    }

    const saveAttendanceArea = (v) => {
        setSaving(true)
        let payload={
            division_id: selectedDivision,
            areas : type === 'all' ? [1] : selectedArea
        }
        // console.log(payload)
        SentralModel.action('Area','SaveDivisionAttendanceArea', payload,null).then((res)=>{
            setFormModal(false)
            fetchDivisions()
            message.success('Area saved',2);
            setSaving(false)
        })
    }

    const selectArea = (e, v) => {
        let p = selectedArea
        if(e.target.checked){
            p.push(v)
        }else{
            p.splice(p.findIndex((el) => el===v), 1)
        }
        setSelectedArea(p)
        forceUpdate()
    }

    const changeArea = e => {
        setType(e)
        setSelectedArea([])
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Setup Attendance Area" breadcrumbs={[['Area','/setting/area'],['Attendance Area']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Setup Attendance Area</Text>
                        <Link to="/setting/custom-attendance-area" style={{float:'right'}}>
                            <Button type="primary" icon={<SettingOutlined />}>Custom Attendance Area</Button>
                        </Link>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal title="Pilih Area" submitForm={(v)=>saveAttendanceArea(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item label="Area">
                            <Select placeholder="Select category" defaultValue={type} style={{ width: "100%" }} onChange={changeArea}>
                                <Option value="all">All Area</Option>
                                <Option value="select">Select Area</Option>
                            </Select>
                        </Form.Item>

                        {type === 'select' ?
                            areas.map((area, i) => (
                                <div className="full-width" key={"area_"+i}>
                                    <Row>
                                        <Col span={21}>
                                            {area.area_name}
                                        </Col>
                                        <Col span={3} className="text-right">
                                            <Form.Item name={'select_'+area.area_id} style={{padding:0, margin:'0px'}}>
                                                <Checkbox checked={selectedArea.includes(area.area_id)} onChange={(v) => selectArea(v,area.area_id)}>
                                                    {area.description}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            ))
                            : null
                        }
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default AttendanceArea;
