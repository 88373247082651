import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { Line } from 'react-chartjs-2'
import DashboardItem from './DashboardItem'
import { PageTitle, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const Attendance = () => {

    const [loaded, setLoaded] = useState(true);
    const [attendances, setAttendances] = useState({});
    const [chartAttendance, setChartAttendance] = useState(null);

    const fetchAttendance = () => {
        setLoaded(false)
        SentralModel.demo('Dashboard', 'attendance', {}, 0).then((res) => {
            setAttendances(res.data)
            setLoaded(true)
        })
    }

    const fetchPeriodAttendance = (date) => {
        setLoaded(false)
        SentralModel.demo('Dashboard', 'attendanceChartHR', { from:'2024-01-01',to:'2024-01-30' }, 0).then((res) => {
            setChartAttendance(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAttendance();
        fetchPeriodAttendance({ from: moment().startOf('month').format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD') })
    }, []);

    const data = {
        labels: (chartAttendance) ? chartAttendance.days : [],
        datasets: [{
            label: 'Attendance Total',
            data: (chartAttendance) ? chartAttendance.presences : [],
            backgroundColor: 'rgba(0, 255, 0, 0.3)',
            borderColor: '#2ecc71',
            borderWidth: 1
        }]
    }

    const counterStyle = {
        fontSize: '50px',
        color: '#000',
        fontWeight: 'bold'
    }

    return (
        <Card className="content-container">
            <PageTitle title="Attendance" breadcrumbs={[['Attendance']]}></PageTitle>
            {
                loaded ?
                    <div>
                        <Row className="mb-3">
                            <Col span={18} className="p-1">
                                <DashboardItem title="Attendance Summary" height={400}>
                                    <Line
                                        data={data}
                                        width={100}
                                        height={320}
                                        options={{ maintainAspectRatio: false }}
                                    />
                                </DashboardItem>
                            </Col>
                            <Col span={6} className="p-1">
                                <Row className="mb-3" gutter={[16, 16]}>
                                    <Col className="gutter-row" lg={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <DashboardItem title="Today's Attendance" height={63}>
                                            <span style={counterStyle}>{attendances.today_attendance}</span>
                                            <span style={{ fontSize: '15px' }}>Employees</span>
                                        </DashboardItem>
                                    </Col>
                                    <Col className="gutter-row" lg={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <DashboardItem title="Today's Leave" height={63}>
                                            <span style={counterStyle}>{attendances.today_leave}</span>
                                            <span style={{ fontSize: '15px' }}>Employees</span>
                                        </DashboardItem>
                                    </Col>
                                    <Col className="gutter-row" lg={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <DashboardItem title="Total Employee" height={63}>
                                            <span style={counterStyle}>{attendances.total_employee}</span>
                                            <span style={{ fontSize: '15px' }}> Employees</span>
                                        </DashboardItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="p-1">
                                <DashboardItem title="Attendance by Division">
                                    <Row className="mb-3">
                                        {
                                            attendances.divisions && attendances.divisions.map((el, index) => (
                                                <Col key={'key_' + index} span={8} className="p-1">
                                                    <DashboardItem title={el.division_name} height={70}>
                                                        <span style={counterStyle}>{el.attend}</span>
                                                        <span style={{ fontSize: '15px' }}> of {el.total} Employees ({el.percent}%)</span>
                                                    </DashboardItem>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </DashboardItem>
                            </Col>
                        </Row>
                    </div>
                    : <Card><Spinner /></Card>
            }

        </Card>
    );
}

export default Attendance;
