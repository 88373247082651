import React, { useEffect, useState } from 'react';
import { Card, message, Typography } from 'antd';
import { PageTitle } from '../../../components'
import CreateForm from './Form'
import { SentralModel } from '../../../models/SentralModel';
import { useHistory, useParams } from 'react-router-dom'

const { Text } = Typography

const Edit = () => {
    const { rewardId } = useParams()
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [reward, setReward] = useState(null)

    const fetchReward = (rewardId) => {
        SentralModel.action("RewardPunishment", "getReward", {}, rewardId).then((res) => {
            setReward(res.data)
        })
    }

    useEffect(() => {
        fetchReward(rewardId)
    }, [rewardId]);

    const submitReward = (v) => {
        setSaving(true)
        SentralModel.action("RewardPunishment", "updateReward", v, rewardId).then((res) => {
            message.success("Reward updated", 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/management/punishment');
            }, 1000);
        }).catch((err) => {
            message.error(err.message, 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Reward" breadcrumbs={[['Reward', '/management/reward'], ['Create']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Form Reward</Text>
                </div>
            }>
                <CreateForm data={reward} onSubmit={(v) => submitReward(v)} saving={saving} />
            </Card>
        </Card>
    );
}

export default Edit;
