import React, { useState, useEffect } from 'react';
import { Card, Space } from 'antd';
import { PageTitle, DataTable, CustomButton, Spinner } from '../../../../components';
import { SentralModel } from '../../../../models/SentralModel';
import { ZoomInOutlined } from '@ant-design/icons'
import {toFullDate} from '../../../../utils/dateHandler'
import moment from 'moment'

const Index = () => {
    const [loaded, setLoaded] = useState(true)
    const [vote, setTrainings] = useState([])

    const fetchTrainings = () => {
        setLoaded(false)
        SentralModel.list('Training', { orderBy: 'training_id', order: 'DESC' }, 0).then((res) => {
            setTrainings(res.data);
            setLoaded(true);
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, []);

    const trainingColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Date',
            className:'text-top',
            render: (row) => (
                <div className="text-center">
                    {toFullDate(row.training_date)}
                </div>
            )
        },
        {
            title: 'Time',
            className:'text-top',
            render: (row) => (
                <div className="text-center">
                    <span>{moment(row.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.end_time, 'HH:mm:ss').format('HH:mm')}</span>
                </div>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <CustomButton icon={<ZoomInOutlined />} link to={"/report/training/detail/" + row.training_id} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Training Report" breadcrumbs={[['Training Report']]}></PageTitle>
            {
                (loaded) ?
                    <DataTable size="small" columns={trainingColumn} dataSource={vote} bordered />
                : <Spinner />
            }
        </Card>
    );
}

export default Index;
