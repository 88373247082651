import React, {useEffect, useState} from 'react';
import {Card, Descriptions, Row, Col, Button, Space, Typography} from 'antd';
import {FileExcelOutlined} from '@ant-design/icons'
import {PageTitle, AddButton, EditButton, DeleteButton, Spinner, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {useParams} from 'react-router-dom'

const {Text} = Typography
const Mom = () => {
    // const history = useHistory()
    const {momId} = useParams()
    const [loaded, setLoaded] = useState(false)
    // const [form] = Form.useForm()
    // const [saving, setSaving] = useState(false)
    const [mom, setMom] = useState({})
    const [attendanceDatas, setAttendanceDatas] = useState([])
    const [issueDatas, setIssueDatas] = useState([])
    const [actionDatas, setActionDatas] = useState([])

    const fetchMom = (momId) => {
        setLoaded(false)
        SentralModel.get('Mom', {}, momId).then(res=>{
            setLoaded(true)
            setMom(res.data)
            setAttendanceDatas([])
            setIssueDatas([])
            setActionDatas([])
        })
    }
    
    useEffect(() => {
        fetchMom(momId)
    }, [momId]);

    // const attendanceDatas = [
    //     ...mom.attendances.map( el => {
    //         return {
    //             id: el.mom_attendance_id,
    //             name: el.name,
    //             email: el.email,
    //             company: el.company,
    //             position: el.position
    //         }
    //     })
    // ]

    const attendanceColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editDivision(row)}/>
                        <DeleteButton onConfirm={() => deleteDivision(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const issueColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editDivision(row)}/>
                        <DeleteButton onConfirm={() => deleteDivision(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const actionColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'PIC',
            dataIndex: 'pic',
            key: 'pic',
        },
        {
            title: 'Targey',
            dataIndex: 'target',
            key: 'target',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editDivision(row)}/>
                        <DeleteButton onConfirm={() => deleteDivision(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const exportReport = () => {
        
    }

    const addDivision=()=>{
        // setModalTitle('Add Division')
        // setDefaultValues({
        //     division_id: 0,
        //     division_name: '',
        //     daily_in: '',
        //     daily_out: '',
        // })
        // setFormModal(true)
    }

    const editDivision = (v) =>{
        // setModalTitle('Edit Division')
        // setDefaultValues({
        //     division_id: v.id,
        //     division_name: v.division_name,
        //     daily_in: moment(v.daily_in, 'HH:mm'),
        //     daily_out: moment(v.daily_out, 'HH:mm'),
        // })
        // setFormModal(true)
    }

    const deleteDivision = (id) =>{
        // message.loading('deleting division')
        // SentralModel.deleteById('Division', id).then((res)=>{
        //     message.destroy()
        //     message.success('Division deleted', 2)
        //     let tmp = divisions
        //     let index = tmp.findIndex(el => el.division_id===id)
        //     tmp.splice(index, 1);
        //     setDivisions([])
        //     setDivisions(tmp)
        // })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Minutes of Meeting" breadcrumbs={[['Minutes of Meeting']]}></PageTitle>
            <Row justify="end" className="mb-3">
                <Col span={24} >
                    <span style={{float:'right'}}>
                        <Button type="primary" onClick={() => exportReport()}  color="#ededed" icon={<FileExcelOutlined />}>
                            Export
                        </Button>
                    </span>
                </Col>
            </Row>

            {
                (loaded) ?
                <div>
                    <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{columnSpan:24}}> 
                        <Descriptions.Item label="Title">{mom.title}</Descriptions.Item>
                        <Descriptions.Item label="MOM No.">{mom.mom_no}</Descriptions.Item>
                        <Descriptions.Item label="Location">{mom.location}</Descriptions.Item>
                        <Descriptions.Item label="Date">{mom.date}</Descriptions.Item>
                        <Descriptions.Item label="Time">{mom.start_time} - {mom.finish_time}</Descriptions.Item>
                    </Descriptions>

                    <Card title={
                        <div className="full-width">
                            <Text strong style={{float:'left'}}>Attendances</Text>
                            <AddButton right onAdd={addDivision} title="Add"/>
                        </div> }>

                        <DataTable size='small' search={false} bordered dataSource={attendanceDatas} columns={attendanceColumns}/>
                    </Card>

                    <Card title={
                        <div className="full-width">
                            <Text strong style={{float:'left'}}>Issues</Text>
                            <AddButton right onAdd={addDivision} title="Add"/>
                        </div> }>

                        <DataTable size='small' search={false} bordered dataSource={issueDatas} columns={issueColumns}/>
                    </Card>

                    <Card title={
                        <div className="full-width">
                            <Text strong style={{float:'left'}}>Actions</Text>
                            <AddButton right onAdd={addDivision} title="Add"/>
                        </div> }>

                        <DataTable size='small' search={false} bordered dataSource={actionDatas} columns={actionColumns}/>
                    </Card>
                </div>
                :
                <Spinner/>
            }
        </Card>
    );
}

export default Mom;
