import React, {useState, useEffect} from 'react';
import {Card, message} from 'antd';
import {PageTitle, Spinner} from '../../../components'
import TrainingForm from './TrainingForm'
import { SentralModel } from '../../../models/SentralModel';
import {useHistory, useParams} from 'react-router-dom'

const Create = () => {
    const {trainingId} = useParams()
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [training, setTraining] = useState(null)

    const fetchTraining = (trainingId) => {
        SentralModel.get('Training',{}, trainingId).then((res) => {
            setTraining(res.data)
        })
    }

    useEffect(() => {
        fetchTraining(trainingId)
    }, [trainingId]);

    const editTraining = (v)=>{
        message.loading('Saving Data', 0)
        setSaving(true)
        SentralModel.action('Training','updateTraining', v,0).then(()=>{
            setSaving(false)
            message.destroy()
            message.success('Training Updated', 1)
            setTimeout(() => {
                history.push('/training');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
            message.destroy()
            message.error(err.message, 2)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Pelatihan dan Sertifikasi" breadcrumbs={[['Pelatihan dan Sertifikasi','/training'],['Edit']]}></PageTitle>
            {
                (training) ?
                    <TrainingForm onSubmit={(v) => editTraining(v)} saving={saving} trainingData={training}/>
                : <Spinner/>
            }
        </Card>
    );
}

export default Create;
