import React, {useEffect, useState} from 'react';
import {Card, Typography, message, Space} from 'antd'
import {NewButton, DeleteButton, PageTitle, Spinner, DataTable, EditButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography
const Index = () => {
    const [rewards, setRewards] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchRewards = () =>{
        SentralModel.list('Reward').then(res=>{
            setRewards(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchRewards()
    }, []);

    const deleteReward = (id) =>{
        message.loading('deleting reward')
        SentralModel.deleteById('Reward', id).then((res)=>{
            message.destroy()
            message.success('Reward deleted', 2)
            let tmp = rewards
            let index = tmp.findIndex(el => el.reward_id===id)
            tmp.splice(index, 1);
            setRewards([])
            setRewards(tmp)
        })
    }

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
          title: 'Employee Name',
          dataIndex: 'employee_name',
          key: 'employee_name',
        },{
            title: 'Divisi',
            dataIndex: 'division_name',
            key: 'division_name',
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },{
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        {/* <ShowButton link to={'/management/reward/'+row.id}/> */}
                        <EditButton link to={'/management/reward/edit/'+row.id}/>
                        <DeleteButton onConfirm={() => deleteReward(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];
    const dataSource = [
        ...rewards.map( el => {
            return {
                id: el.reward_id,
                employee_name: el.employee.name,
                division_name:el.employee.division.division_name,
                date: toFullDate(el.date),
                type:el.type.description
            }
        })
    ]

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Rewards" breadcrumbs={[['Reward']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Reward List</Text>
                        <NewButton right link to="/management/reward/create" title="New Reward"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
