import React, {useState} from 'react';
import {Card, Typography, Space} from 'antd'
import {PageTitle, Spinner, DataTable, ViewButton} from '../../../components'

const {Text} = Typography
const Index = () => {
    const [loaded] = useState(true)

    const dataSource = [
        {
            id: 1,
            name: 'Exit Clearance',
        },
        {
            id: 2,
            name: 'Exit Interview',
        }
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text) => (
                <div className="text-center">
                    {text}
                </div>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            width: 100,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ViewButton to={'/documentation/exit-clearance/' + row.id} />
                    </Space>
                </div>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Exit Clearance & Interview" breadcrumbs={[['Exit Clearance & Interview']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Exit Clearance & Interview</Text>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
