import React, { useState, useEffect } from 'react'
import { Row, Select, DatePicker } from 'antd'
import ProfileItem from './ProfileItem';
import { EditButton } from '../../components'
import { SentralModel } from '../../models/SentralModel'
import moment from 'moment'
import { toFullDate } from '../../utils/dateHandler';
import authData from '../../helpers/authData'

const Employment = (props) => {
    const [employee, setEmployee] = useState(props.data)
    const [showEdit, setShowEdit] = useState(false)
    const [canEdit, setCanEdit] = useState(false)

    const fetchUser = () => {
        SentralModel.get('Employee', [], props.emp_code).then((res) => {
            setEmployee(res.data)
        })
    }

    useEffect(() => {
        if (authData.employee_code === 'SMI20200622202' || authData.employee_code === 'SMI20180322139' || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'ADM001') {
            setCanEdit(true)
        }
    }, []);

    const updatePersonalData = (v, field) => {
        let payload = {};
        payload[field] = v
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee[field] = v
            props.updateUser(employee)
            fetchUser();
        })
    }

    const submitDivision = (v, t) => {
        updatePersonalData(v, 'division_id')
        setShowEdit(null)
        let employee = props.data
        employee.division_id = v
        employee.division = {
            division_id: v,
            division_name: t.children
        }
        props.updateUser(employee)
    }

    const submitPosition = (v, t) => {
        updatePersonalData(v, 'position_id')
        setShowEdit(null)
        let employee = props.data
        employee.position_id = v
        employee.position = {
            position_id: v,
            position_name: t.children
        }
        props.updateUser(employee)
    }

    const submitIsActive = (v) => {
        let payload = {};
        payload['is_active'] = v
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee['is_active'] = v
            props.updateUser(employee)
            fetchUser();
            setShowEdit(false)
        })
    }

    const submitEmployeeStatus = (v) => {
        let payload = {};
        payload['employee_status'] = v
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee['employee_status'] = v
            props.updateUser(employee)
            fetchUser();
            setShowEdit(false)
        })
    }

    const submitCompany = (v) => {
        let payload = {};
        payload['company_id'] = v
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee.company_id = v
            props.updateUser(employee)
            fetchUser();
            setShowEdit(false)
        })
    }

    const submitEmployeeGrade = (v) => {
        let payload = {};
        payload['employee_grade'] = v
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee['employee_grade'] = v
            props.updateUser(employee)
            fetchUser();
            setShowEdit(false)
        })
    }

    const submitStation = (v) => {
        let payload = {};
        payload['station_id'] = v
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee['station_id'] = v
            props.updateUser(employee)
            fetchUser();
            setShowEdit(false)
        })
    }

    const submitResignDate = (v) => {
        let payload = {};
        payload['exit_date'] = moment(v).format('YYYY-MM-DD')
        SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
            let employee = props.data
            employee['exit_date'] = moment(v).format('YYYY-MM-DD')
            props.updateUser(employee)
            setShowEdit(false)
        })
    }

    return (
        <div>
            <Row>
                <ProfileItem title="Division">
                    {
                        (showEdit !== 'division' && employee.division) ?
                            props.renderItem(employee.division.division_name)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'division') ?
                                <Select
                                    showSearch
                                    defaultValue={employee.division_id}
                                    onChange={(v, t) => submitDivision(v, t)}
                                    style={{ width: '80%' }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={props.divisions} />
                                : <EditButton onEdit={() => setShowEdit('division')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Position">
                    {
                        (showEdit !== 'position' && employee.position) ?
                            props.renderItem(employee.position.position_name)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'position') ?
                                <Select
                                    showSearch
                                    defaultValue={employee.position_id}
                                    onChange={(v, t) => submitPosition(v)}
                                    style={{ width: '80%' }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={props.positions}
                                />
                                : <EditButton onEdit={() => setShowEdit('position')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Employee Status">
                    {
                        (showEdit !== 'employee_status') ?
                            (employee.status_detail) ?
                                employee.status_detail.description
                                : ''
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'employee_status') ?
                                <Select defaultValue={employee.employee_status} onChange={(v, t) => submitEmployeeStatus(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.statuses.map((el, i) => (
                                            <Select.Option value={el.detail_code} key={i}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('employee_status')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Account Status">
                    {
                        (showEdit !== 'is_active') ?
                            (employee.is_active) ? 'Aktif' : 'Tidak Aktif'
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'is_active') ?
                                <Select defaultValue={employee.is_active} onChange={(v, t) => submitIsActive(v, t)} style={{ width: '80%' }}>
                                    <Select.Option value={1}>Aktif</Select.Option>
                                    <Select.Option value={0}>Tidak Aktif</Select.Option>
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('is_active')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Company">
                    {
                        (showEdit !== 'company' && employee.company) ?
                            props.renderItem(employee.company.company_name)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'company') ?
                                <Select defaultValue={employee.company_id.toString()} onChange={(v, t) => submitCompany(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.companies.map((el, key) => (
                                            <Select.Option key={key} value={el.company_id}>{el.company_name}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('company')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Employee Grade">
                    {
                        (showEdit !== 'employee_grade') ?
                            (employee.grade_detail) ?
                                employee.grade_detail.detail_code + ' - ' + employee.grade_detail.description
                                : ''
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'employee_grade') ?
                                <Select defaultValue={employee.employee_grade} onChange={(v, t) => submitEmployeeGrade(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.grades.map((el, i) => (
                                            <Select.Option value={el.detail_code} key={i}>{el.detail_code + ' - ' + el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('employee_grade')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Station">
                    {
                        (showEdit !== 'station_id') ?
                            (employee.station) ?
                                employee.station.city_name
                                : ''
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'station_id') ?
                                <Select defaultValue={employee.station_id} onChange={(v, t) => submitStation(v, t)} style={{ width: '80%' }} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        props.stations.map((el, i) => (
                                            <Select.Option value={el.city_id} key={i}>{el.city_name}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('station_id')} />
                            : null
                    }
                </ProfileItem>
                {
                    (employee.employee_status === 'ESRS') ?
                        <ProfileItem title="Resign Date">
                            {
                                (showEdit !== 'resign_date') ?
                                    (employee.exit_date) ?
                                        toFullDate(employee.exit_date)
                                        : ''
                                    : null
                            }
                            {
                                canEdit ?
                                    (showEdit === 'resign_date') ?
                                        <DatePicker className="full-width" picker="date" onBlur={() => setShowEdit(false)} defaultValue={moment(employee.exit_date, 'YYYY-MM-DD')} onChange={(v) => submitResignDate(v)} />
                                        : <EditButton onEdit={() => setShowEdit('resign_date')} />
                                    : null
                            }
                        </ProfileItem>
                        : null
                }
                {
                    (employee.employee_status === 'ESRS') ?
                        <ProfileItem onEdited={canEdit ? (v) => updatePersonalData(v, 'reason_for_resign') : null} title="Resign Reason">{props.renderItem(employee.reason_for_resign)}</ProfileItem>
                        : null
                }
            </Row>
        </div>
    );
}

export default Employment;
