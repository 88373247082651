import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../../models/SentralModel';
import {toFullDate} from '../../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {goodsReceiptId} = useParams()
    const [goodsReceipt, setGoodsReceipt] = useState(null)

    const fetchGoodsReceipt = (id) => {
        SentralModel.get('AssetGoodsReceipt', {}, id).then((res)=>{
            setGoodsReceipt(res.data)
        })
    }

    useEffect(() => {
        fetchGoodsReceipt(goodsReceiptId)
    }, [goodsReceiptId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/asset-goods-receipt/' + goodsReceiptId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Goods Receipt" breadcrumbs={[['Asset'],['Goods Receipts', '/asset/goods-receipt'], ['Detail']]}></PageTitle>
            </div>
            {
                (goodsReceipt) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <Card bordered  className="mb-4">
                        <Text strong>GR DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>GR Number</th><td>{goodsReceipt.goods_receipt_number}</td>
                                        </tr>
                                        <tr>
                                            <th>PO Number</th><td>{goodsReceipt.purchase_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Vendor</th><td>{goodsReceipt.vendor.vendor_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Date</th> 
                                            <td>
                                                { toFullDate(goodsReceipt.date)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <div>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{width:'5%'}}>No</th>
                                        <th style={{width:'40%'}}>Item Name</th>
                                        <th className="text-center" style={{width:'30%'}}>Serial Number</th>
                                        <th className="text-center" style={{width:'25%'}}>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        goodsReceipt.goods_receipt_item.map((el, key) => (
                                            <tr key={key}>
                                                <td className="text-center">{key+1}</td>
                                                <td>{el.item.item_name}</td>
                                                <td>{el.serial_number}</td>
                                                <td>{el.quantity}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
