import React, {useState} from 'react'
import {Row, message, Select, DatePicker} from 'antd'
import moment from 'moment'
import ProfileItem from './ProfileItem';
import {EditButton, BasicModal, Uploader} from '../../../components'
import {ImageViewer} from '../../../components/ImageViewer'
import {SentralModel} from '../../../models/SentralModel'

const PersonalData = (props) => {
    const [showEditProfilePicture, setShowEditProfilePicture] = useState(false)
    const [uploadingProfilePicture, setUploadingProfilePicture] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const updatePersonalData = (v, field) =>{
        let payload= {};
        payload[field]=v
        SentralModel.save('RecruitmentApplicant', payload, props.data.rec_applicant_id).then(()=>{
            let applicant = props.data
            applicant[field] = v
            props.updateApplicant(applicant)
        })
    }   

    const profilePictureUploaded = (v) =>{
        if(uploadingProfilePicture){
            setUploadingProfilePicture(false)
        }
        SentralModel.action('Profile','changeProfilePicture', {photo: v.message, rec_applicant_id: props.data.rec_applicant_id},0).then(()=>{
            message.success('Profile Picture Updated', 2)
            let applicant = props.data
            if (applicant.document) {
                applicant.document.photo= v.message
            } else {
                applicant.document = {photo : v.message}
            }
            props.updateApplicant(applicant)
            setShowEditProfilePicture(false)
        })
    }

    const submitReligion = (v, t) => {
        updatePersonalData(v, 'religion')
        setShowEdit(null)
        let applicant = props.data
        applicant.religion = v
        applicant.religion_detail = {
            general_detail_code : v,
            description : t.children
        }
        props.updateApplicant(applicant)
    }

    const submitMaritalStatus = (v, t) => {
        updatePersonalData(v, 'marital_status')
        setShowEdit(null)
        let applicant=props.data
        applicant.marital_status = v
        applicant.marital_status_detail = {
            description : t.children,
            general_detail_code : v
        }
        props.updateApplicant(applicant)
    }

    const submitGender = (v, t) => {
        updatePersonalData(v, 'gender')
        setShowEdit(null)
        let applicant=props.data
        applicant.gender = v
        applicant.gender_detail = {
            description : t.children,
            general_detail_code : v
        }
        props.updateApplicant(applicant)
    }

    const submitBirthDate = (v, t) => {
        updatePersonalData(moment(v).format('YYYY-MM-DD'), 'date_of_birth')
        setShowEdit(null)
        let applicant = props.data
        applicant.date_of_birth = moment(v).format('YYYY-MM-DD')
        props.updateApplicant(applicant)
    }

    return (
        <div>
            <>
                <Row>
                    <ProfileItem image title="Profile Picture">
                        <div style={{width:200, border:'1px solid #ededed'}}>
                            <ImageViewer onEdit={() => setShowEditProfilePicture(true)} style={{width:'50%'}} previewable src={props.data.document === null ? process.env.PUBLIC_URL + '/images/noimage.png' : props.data.document.photo === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + props.data.document.photo}/>
                        </div>
                    </ProfileItem>
                </Row>
                <Row>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'name')} title="Name">{props.renderItem(props.data.name)}</ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'mobile_phone_no')} title="Phone Number">{props.renderItem(props.data.mobile_phone_no)}</ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'email')} title="Email">{props.renderItem(props.data.email)}</ProfileItem>
                    <ProfileItem title="Marital Status">
                        {
                            (showEdit!=='marital' && props.data.marital_status_detail)?
                                props.renderItem(props.data.marital_status_detail.description)
                            : null
                        }
                        {
                            (showEdit==='marital') ? 
                            <Select defaultValue={props.data.marital_status} onChange={(v, t) => submitMaritalStatus(v, t)} style={{width:'80%'}}>
                                {
                                    props.generals.filter(g => g.general_code==='MS').map((el, key) => (
                                        <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                    ))
                                }
                            </Select> : <EditButton onEdit={() => setShowEdit('marital')}/>
                        }
                    </ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'identity_card_address')} title="Identity Card Address">{props.renderItem(props.data.identity_card_address)}</ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'current_address')} title="Current Address">{props.renderItem(props.data.current_address)}</ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'parent_address')} title="Parent Address">{props.renderItem(props.data.parent_address)}</ProfileItem>
                    <ProfileItem title="Religion">
                        {
                            (showEdit!=='religion' && props.data.religion_detail)?
                                props.renderItem(props.data.religion_detail.description)
                            : null
                        }
                        {
                            (showEdit==='religion') ? 
                            <Select defaultValue={props.data.religion} onChange={(v, t) => submitReligion(v, t)} style={{width:'80%'}}>
                                {
                                    props.generals.filter(g => g.general_code==='R').map((el, key) => (
                                        <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                    ))
                                }
                            </Select> : <EditButton onEdit={() => setShowEdit('religion')}/>
                        }
                    </ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'place_of_birth')} title="Birth Place">{props.renderItem(props.data.place_of_birth)}</ProfileItem>
                    <ProfileItem title="Date of Birth">
                        {
                            (showEdit!=='birthdate' && props.data.date_of_birth)?
                                props.renderItem(props.data.date_of_birth)
                            : null
                        }
                        {
                            (showEdit==='birthdate') ? 
                            <DatePicker value={(props.data.date_of_birth) ? moment(props.data.date_of_birth) : null} onChange={(v)=>submitBirthDate(v)}/> : 
                            <EditButton onEdit={() => setShowEdit('birthdate')}/>
                        }
                    </ProfileItem>
                    <ProfileItem title="Gender">
                        {
                            (showEdit!=='gender' && props.data.gender_detail)?
                                props.renderItem(props.data.gender_detail.description)
                            : null
                        }
                        {
                            (showEdit==='gender') ? 
                            <Select defaultValue={props.data.gender} onChange={(v, t) => submitGender(v, t)} style={{width:'80%'}}>
                                {
                                    props.generals.filter(g => g.general_code==='G').map((el, key) => (
                                        <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                    ))
                                }
                            </Select> : <EditButton onEdit={() => setShowEdit('gender')}/>
                        }
                    </ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'identity_card_no')} title="KTP">{props.renderItem(props.data.identity_card_no)}</ProfileItem>
                    <ProfileItem onEdited={(v) => updatePersonalData(v, 'npwp_no')} title="NPWP">{props.renderItem(props.data.npwp_no)}</ProfileItem>
                </Row>
            </>
            
            <BasicModal title="Change Profile Picture" showModal={showEditProfilePicture} onCloseModal={()=>setShowEditProfilePicture(false)}>
                {
                    (showEditProfilePicture) ? 
                        <Uploader dragger folder="photo" onUploaded={(v)=> profilePictureUploaded(v)} isUploading={(v)=> setUploadingProfilePicture(v)}/>
                    : null
                }
            </BasicModal>
        </div>
    );
}

export default PersonalData;
