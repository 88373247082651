import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Select} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const {Option} = Select;
const Index = () => {
    const [cities, setCities] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [provinces, setProvinces] = useState([])
    const [selected, setSelected] = useState([])
    const fetchCities = () =>{
        SentralModel.list('City', {orderBy:"city_name", order:"asc"}).then(res=>{
            setCities(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }
    const fetchProvinces = () =>{
        SentralModel.list('Province', {orderBy:"province_name", order:"asc"}).then(res=>{
            setProvinces(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCities()
        fetchProvinces()
    }, []);
    
    const dataSource = [
        ...cities.map( el => {
            return {
                id: el.city_id,
                province_id: el.province_id,
                city_name: el.city_name,
                province_name: el.province.province_name
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
          title: 'City Name',
          dataIndex: 'city_name',
          key: 'city_name'
        },
        {
            title: 'Province Name',
            dataIndex: 'province_name',
            key: 'province_name'
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editCities(row)}/>
                        <DeleteButton onConfirm={() => deleteCities(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addCity=()=>{
        setModalTitle('Add City')
        setDefaultValues({
            city_id: 0,
            city_name: ''
        })
        setFormModal(true)
    }

    const editCities = (v) =>{
        setModalTitle('Edit Cities')
        setDefaultValues({
            city_id: v.id,
            province_id: v.province_id,
            city_name: v.city_name
        })
        setFormModal(true)
    }

    const deleteCities = (id) =>{
        message.loading('deleting city')
        SentralModel.deleteById('City', id).then((res)=>{
            message.destroy()
            message.success('Cities deleted', 2)
            let tmp = cities
            let index = tmp.findIndex(el => el.city_id===id)
            tmp.splice(index, 1);
            setCities([])
            setCities(tmp)
        })
    }

    const saveCity = (v) => {
        setSaving(true)
        let payload={
            city_id: v.id,
            province_id: selected,
            city_name: v.city_name
        }
        SentralModel.save('City', payload, v.city_id).then((res)=>{
            setFormModal(false)
            fetchCities()
            message.success('City saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeProvince = e => {
        setSelected(e)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Cities" breadcrumbs={[['City']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>City List</Text>
                        <AddButton right onAdd={addCity} title="New City"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveCity(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="city_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Province" name="province_id" required rules={[{required:true}]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select province" optionFilterProp="children" onChange={changeProvince} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    provinces.map((el, key) => (
                                        <Option key={key} value={el.province_id}>{el.province_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="City Name" name="city_name" required rules={[{required:true}]}>
                            <Input placeholder="City Name" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
