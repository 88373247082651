import React, { useEffect, useState } from 'react';
import { Card, Typography, Form, Input, message, Space, Switch, Tag } from 'antd'
import { AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const { Text } = Typography
const Index = () => {
    const [positions, setPositions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [status, setStatus] = useState(false)

    const fetchPositions = () => {
        SentralModel.list('Position', { orderBy: "is_active", order: "desc" }).then(res => {
            setPositions(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchPositions()
    }, []);

    const dataSource = [
        ...positions.map(el => {
            return {
                id: el.position_id,
                position_name: el.position_name,
                level: el.level,
                is_active: el.is_active
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Position Name',
        dataIndex: 'position_name',
        key: 'position_name',
        width: '60%'
    },
    {
        title: 'Level',
        dataIndex: 'level',
        align: 'center',
        key: 'level',
        width: '20%'
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        align: 'center',
        key: 'is_active',
        width: '20%',
        render: (text) => (
            <Tag color={text === '1' ? "green" : "red"}>{(text === '1') ? 'Active' : 'Inactive'}</Tag>
        )
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton onEdit={() => editPosition(row)} />
                    <DeleteButton onConfirm={() => deletePosition(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    const addPosition = () => {
        setModalTitle('Add Position')
        setDefaultValues({
            position_id: 0,
            position_name: '',
            level: '',
            is_active: true
        })
        setStatus(true)
        setFormModal(true)
    }

    const editPosition = (v) => {
        setModalTitle('Edit Position')
        setDefaultValues({
            position_id: v.id,
            position_name: v.position_name,
            level: v.level,
            is_active: parseInt(v.is_active)
        })
        setStatus(v.is_active)
        setFormModal(true)
    }

    const deletePosition = (id) => {
        message.loading('deleting position')
        SentralModel.deleteById('Position', id).then((res) => {
            message.destroy()
            message.success('Position deleted', 2)
            fetchPositions()
        })
    }

    const savePosition = (v) => {
        setSaving(true)
        let payload = {
            position_id: v.id,
            position_name: v.position_name,
            level: v.level,
            is_active: status
        }
        SentralModel.save('Position', payload, v.position_id).then((res) => {
            setFormModal(false)
            fetchPositions()
            message.success('Position saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeStatus = e => {
        setStatus(e)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Positions" breadcrumbs={[['Position']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Position List</Text>
                        <AddButton right onAdd={addPosition} title="New Position" />
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => savePosition(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="position_id" hidden>
                                <Input placeholder="Position ID" />
                            </Form.Item>
                            <Form.Item label="Position Name" name="position_name" required rules={[{ required: true }]}>
                                <Input placeholder="Position Name" />
                            </Form.Item>
                            <Form.Item label="Level" name="level" rules={[{ required: true }]}>
                                <Input placeholder="Level" />
                            </Form.Item>
                            <Form.Item label="Status" name="is_active" rules={[{ required: true }]}>
                                <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={changeStatus} defaultChecked={defaultValues.is_active} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    );
}

export default Index;
