import React, {useEffect, useState} from 'react';
import {Row, message} from 'antd'
import ProfileItem from './ProfileItem'
import {ImageViewer} from '../../components/ImageViewer'
import {BasicModal, Uploader, EditButton} from '../../components';
import {SentralModel} from '../../models/SentralModel'
import authData from '../../helpers/authData'

const Document = (props) => {
    const [empDocuments, setEmpDocuments] = useState(props.data);
    const [showEditDocument, setShowEditDocument] = useState(false)
    const [documentField, setDocumentField] = useState(null);
    const [documentFolder, setDocumentFolder] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [uploadingDocument, setUploadingDocument] = useState(false)
    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        if (authData.employee_code === props.emp_code || authData.employee_code === 'SMI20200622202' || authData.employee_code === 'SMI20180322139' || authData.employee_code === 'SMI20220323229') {
            setCanEdit(true)
        }
    }, [props]);

    const upload = (title, field, folder) => {
        setShowEditDocument(true);
        setModalTitle(title);
        setDocumentField(field);
        setDocumentFolder(folder)
    }

    const documentUploaded = (v, field) =>{
        if(uploadingDocument){
            setUploadingDocument(false)
        }
        SentralModel.action('Profile', 'changeDocument', {employee_code: props.emp_code, field: documentField, value: v.message},0).then(()=>{
            message.success('Document Updated', 2)
            let document = props.data !== null ? props.data : {photo: null, identity_card: null, family_card: null, npwp: null, bpjs_health: null, bpjs_employment: null, skck: null, education_certificate: null, signature: null, driver_license: null, covid_certificate: null}
            if (field === 'identity_card')
                document.identity_card = v.message
            else if (field === 'family_card')
                document.family_card = v.message
            else if (field === 'education_certificate')
                document.education_certificate = v.message
            else if (field === 'npwp')
                document.npwp = v.message
            else if (field === 'bpjs_health')
                document.bpjs_health = v.message
            else if (field === 'bpjs_employment')
                document.bpjs_employment = v.message
            else if (field === 'skck')
                document.skck = v.message
            else if (field === 'driver_license')
                document.driver_license = v.message
            else if (field === 'covid_certificate')
                document.covid_certificate = v.message
            setEmpDocuments(document)
            setShowEditDocument(false)
        })
    }

    return (
        <div>
            {
                empDocuments ?
                    <Row>
                        <ProfileItem image title="Identity Card">
                            <ImageViewer previewable={empDocuments.identity_card ? true : false} onEdit={canEdit ? () => upload('Identity Card', 'identity_card', 'identity-card') : null} style={{width:'100%'}} src={empDocuments.identity_card === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.identity_card}/>
                        </ProfileItem>
                        <ProfileItem image title="Family Card">
                            <ImageViewer previewable={empDocuments.family_card ? true : false} onEdit={canEdit ? () => upload('Family Card', 'family_card', 'family-card') : null} style={{width:'100%'}} src={empDocuments.family_card === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.family_card}/>
                        </ProfileItem>
                        <ProfileItem image title="Education Certificate">
                            <ImageViewer previewable={empDocuments.education_certificate ? true : false} onEdit={canEdit ? () => upload('Education Certificate', 'education_certificate', 'education-certificate') : null} style={{width:'100%'}} src={empDocuments.education_certificate === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.education_certificate}/>
                        </ProfileItem>
                        <ProfileItem image title="Tax ID Number">
                            <ImageViewer previewable={empDocuments.npwp ? true : false} onEdit={canEdit ? () => upload('Tax ID Number', 'npwp', 'npwp') : null} style={{width:'100%'}} src={empDocuments.npwp === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.npwp}/>
                        </ProfileItem>
                        <ProfileItem image title="BPJS Health">
                            <ImageViewer previewable={empDocuments.bpjs_health ? true : false} onEdit={canEdit ? () => upload('BPJS Health', 'bpjs_health', 'bpjs-health') : null} style={{width:'100%'}} src={empDocuments.bpjs_health === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.bpjs_health}/>
                        </ProfileItem>
                        <ProfileItem image title="BPJS Employment">
                            <ImageViewer previewable={empDocuments.bpjs_employment ? true : false} onEdit={canEdit ? () => upload('BPJS Employment', 'bpjs_employment', 'bpjs-employment') : null} style={{width:'100%'}} src={empDocuments.bpjs_employment === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.bpjs_employment}/>
                        </ProfileItem>
                        <ProfileItem image title="Statement of Police Record">
                            <ImageViewer previewable={empDocuments.skck ? true : false} onEdit={canEdit ? () => upload('Statement of Police Record', 'skck', 'skck') : null} style={{width:'100%'}} src={empDocuments.skck === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.skck}/>
                        </ProfileItem>
                        <ProfileItem image title="Driver License">
                            <ImageViewer previewable={empDocuments.driver_license ? true : false} onEdit={canEdit ? () => upload('Driver License', 'driver_license', 'driver-license') : null} style={{width:'100%'}} src={empDocuments.driver_license === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + empDocuments.driver_license}/>
                        </ProfileItem>
                        <ProfileItem image title="Covid-19 Vaccine Certificate">
                            {
                                empDocuments.covid_certificate !== null ?
                                    empDocuments.covid_certificate.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.covid_certificate}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={canEdit ? () => upload('Covid-19 Vaccine Certificate', 'covid_certificate', 'covid-certificate') : null}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.covid_certificate ? true : false} onEdit={canEdit ? () => upload('Covid-19 Vaccine Certificate', 'covid_certificate', 'covid-certificate') : null} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.covid_certificate}/> :
                                <ImageViewer previewable={empDocuments.covid_certificate ? true : false} onEdit={canEdit ? () => upload('Covid-19 Vaccine Certificate', 'covid_certificate', 'covid-certificate') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                    </Row>
                :
                    <Row>
                        <ProfileItem image title="Identity Card">
                            <ImageViewer onEdit={canEdit ? () => upload('Identity Card', 'identity_card', 'identity-card') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="Family Card">
                            <ImageViewer onEdit={canEdit ? () => upload('Family Card', 'family_card', 'family-card') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="Education Certificate">
                            <ImageViewer onEdit={canEdit ? () => upload('Education Certificate', 'education_certificate', 'education-certificate') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="Tax ID Number">
                            <ImageViewer onEdit={canEdit ? () => upload('Tax ID Number', 'npwp', 'npwp') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="BPJS Health">
                            <ImageViewer onEdit={canEdit ? () => upload('BPJS Health', 'bpjs_health', 'bpjs-health') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="BPJS Employment">
                            <ImageViewer onEdit={canEdit ? () => upload('BPJS Employment', 'bpjs_employment', 'bpjs-employment') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="Statement of Police Record">
                            <ImageViewer onEdit={canEdit ? () => upload('Statement of Police Record', 'skck', 'skck') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="Driver License">
                            <ImageViewer onEdit={canEdit ? () => upload('Driver License', 'driver_license', 'driver-license') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                        <ProfileItem image title="Covid-19 Vaccine Certificate">
                            <ImageViewer onEdit={canEdit ? () => upload('Covid-19 Vaccine Certificate', 'covid_certificate', 'covid-certificate') : null} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                        </ProfileItem>
                    </Row>
            }

            <BasicModal title={modalTitle} showModal={showEditDocument} onCloseModal={()=>setShowEditDocument(false)}>
                {
                    (showEditDocument) ? 
                        <Uploader dragger folder={documentFolder} onUploaded={(v)=> documentUploaded(v, documentField)} isUploading={(v)=> setUploadingDocument(v)}/>
                    : null
                }
            </BasicModal>
        </div>
    );
}

export default Document;
