import { useState, useEffect } from 'react'
import { Card, Form, Input, InputNumber, Typography, message } from 'antd'
import { SentralModel } from '../../../../models/SentralModel'
import { AddButton, FormModal } from '../../../../components'
import moment from 'moment'

const { Text } = Typography

const Index = () => {
    const [form] = Form.useForm()
    const [datas, setDatas] = useState([])
    const [months, setMonths] = useState([])
    const [formModalProgress, setFormModalProgress] = useState(false)
    const [saving, setSaving] = useState(false)

    const fetchMonitoring = () => {
        SentralModel.action("CapacityMonitor", "getCapacity", null, null).then((res) => {
            setDatas(res.data)
        })
    }

    const fecthMonth = () => {
        setMonths(moment.monthsShort());
    }

    useEffect(() => {
        fetchMonitoring()
        fecthMonth()

    }, [])

    const generateData = () => {
        SentralModel.action("CapacityMonitor", "createCapacityYearly", null, null).then((res) => {
            message.success("Success")
            fetchMonitoring()
            fecthMonth()
        }).catch((err) => {
            message.warning("Failed")
        })
    }


    const editProgress = (v) => {
        form.setFieldsValue({
            capacity_monitor_id: v.capacity_monitor_id,
            progress: v.progress ?? 0,
        })
        setFormModalProgress(true)
    }

    const saveProgress = (v) => {
        setSaving(true)
        SentralModel.action("CapacityMonitor", "updateProgress", v, v.capacity_monitor_id).then((res) => {
            message.success("Success")
            fetchMonitoring()
            fecthMonth()
            setSaving(false)
            setFormModalProgress(false)
        }).catch((err) => {
            message.success("Success")
            setSaving(false)
        })
        setTimeout(() => {
        }, 2000)
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Capacity Monitor - {new Date().getFullYear()}</Text>
                    {
                        datas.length === 0 ?
                            <AddButton right title="New Monitor" onAdd={generateData} />
                            :
                            <AddButton right title="New Monitor" disabled />
                    }
                </div>}>
                {
                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Description</th>
                                {
                                    months.map(month => (
                                        <th className="text-center">
                                            {month}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Melakukan Maintance Pada Server Cloud</td>
                                {
                                    datas.map((el, key) => (
                                        <td key={key} className="text-center" style={{ cursor: 'pointer', verticalAlign: 'center' }} onClick={() => { editProgress(el) }}> {el.progress === null ? '-' : el.progress + '%'} </td>
                                    ))
                                }
                            </tr>
                        </tbody>
                    </table>
                }
            </Card>

            {
                (formModalProgress) ?
                    <FormModal form={form} title="Update Progress" submitForm={(v) => saveProgress(v)} width={400} showModal={formModalProgress} onCloseModal={() => setFormModalProgress(false)} saving={saving}>
                        <Form.Item name="capacity_monitor_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item label="Progress" name="progress" rules={[{ required: true }]}>
                            <InputNumber min={0} max={100} style={{ width: "100%" }} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default Index