import React, { useEffect, useState } from 'react';
import { Card, Empty, Tabs, Row, Select, Space, Button, Form } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { setSessionStorage, getSessionStorage } from '../../../helpers/sessionStorage';
import { FileExcelOutlined } from '@ant-design/icons'

const { TabPane } = Tabs;
const { Option } = Select

const Ranking = () => {
    const [form] = Form.useForm()
    const [appraisals, setAppraisals] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [activeYear, setActiveYear] = useState('')
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [divisions, setDivisions] = useState([])
    const [evaluatorList, setEvaluatorList] = useState([])

    const initialDivision = () => {
        if (getSessionStorage('appraisalDivision')) {
            return parseInt(getSessionStorage('appraisalDivision'))
        }
        return null
    }

    const fetchAppraisals = (divisionId = 0) => {
        setLoaded(false)
        SentralModel.action('Appraisal', 'getRanking', { division_id: divisionId }).then(res => {
            setAppraisals(res.data)
            setActiveYear(res.data.year)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchAppraisalsByEvaluatorCode = (v) => {
        setLoaded(false)
        SentralModel.action('Appraisal', 'getRankingEvaluator', { evaluator_code: v }).then(res => {
            setAppraisals(res.data)
            setActiveYear(res.data.year)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { fields: "division_id,division_name" }).then((res) => {
            setDivisions(res.data)
        })
    }

    const fetchEvaluator = () => {
        SentralModel.action("Appraisal", "getEvaluatorList").then((res) => {
            setEvaluatorList(res.data)
        })
    }

    const changeDivision = (v) => {
        setLoaded(false)
        fetchAppraisals(v)
        setSessionStorage('appraisalDivision', v)
        setSelectedDivision(v)
        // sessionStorage.setItem('selectedAppraisalDivision',v)
    }

    const changeEvaluator = (v) => {
        setLoaded(false)
        if (v === undefined) {
            fetchAppraisals()
        } else {
            fetchAppraisalsByEvaluatorCode(v)
        }
    }

    useEffect(() => {
        let divisionId = sessionStorage.getItem('appraisalDivision') || 0
        if (divisionId) {
            setSelectedDivision(parseInt(divisionId))
        } else {
            setSelectedDivision(0)
        }
        fetchDivisions(divisionId)
        fetchAppraisals()
        fetchEvaluator()

    }, []);

    const exportReportBestEmployee = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/ranking?division_id=' + selectedDivision)
    }

    const exportReportRookie = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/rankingRookie?division_id=' + selectedDivision)
    }

    const resetFilter = () => {
        setLoaded(false)
        fetchAppraisals()
        form.setFieldsValue({
            division: 0,
            evaluator: null
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title={'Ranking ' + activeYear} breadcrumbs={[['Appraisal', '/management/appraisal'], ['Ranking']]}></PageTitle>
                <Card
                // title={
                // <div className="full-width">
                //     <Text strong style={{float:'left'}}>Ranking</Text>
                // </div> }
                >
                    <div>
                        <Row>
                            <div className="full-width">
                                <span className="mb-3" style={{ float: 'right' }}>
                                    <Row justify='end'>
                                        <Space>
                                            <Button type="primary" onClick={() => exportReportBestEmployee()} color="#ededed" icon={<FileExcelOutlined />}>
                                                Export Best Employee
                                            </Button>
                                            <Button type="primary" onClick={() => exportReportRookie()} color="#ededed" icon={<FileExcelOutlined />}>
                                                Export Rookie
                                            </Button>
                                        </Space>
                                    </Row>
                                    <Row>
                                        <Form form={form} layout="vertical">
                                            <Row>
                                                <Space>
                                                    <div style={{ marginTop: 25 }}>
                                                        <Form.Item name="division">
                                                            <Select allowClear placeholder="Select Division" defaultValue={initialDivision} style={{ width: 250 }} onChange={changeDivision} loading={divisions.length === 0}>
                                                                <Option key="division_all" value={0}>Semua Divisi</Option>
                                                                {
                                                                    divisions.map((el, key) => (
                                                                        <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div style={{ marginTop: 25 }}>
                                                        <Form.Item name="evaluator">
                                                            <Select allowClear placeholder="Select Evaluator" style={{ width: 250 }} onChange={changeEvaluator} loading={evaluatorList.length === 0}>
                                                                {
                                                                    evaluatorList.map((el, key) => (
                                                                        <Option key={'evaluator_' + key} value={el.evaluator_code}>{el.name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <Button type='primary' danger onClick={resetFilter}>Reset Filter</Button>
                                                </Space>
                                            </Row>
                                        </Form>
                                    </Row>
                                </span>
                            </div>
                        </Row>
                        {
                            (loaded) ?
                                <div>
                                    <Tabs defaultActiveKey="appraisal" type="card">
                                        <TabPane tab={<span>Best Employee</span>} key="best">
                                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th width='10%'>Rank</th>
                                                        <th width='30%'>Employee Name</th>
                                                        <th width='40%'>Division Name</th>
                                                        <th width='20%'>KPI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (appraisals.best.length !== 0) ?
                                                            appraisals.best.map((el, i) => (
                                                                <tr key={i}>
                                                                    <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                                    <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                                    <td style={{ verticalAlign: 'top' }}>{el.division_name ?? ''}</td>
                                                                    <td className="text-center">{el.kpi_value ?? 0}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td colSpan={4}><Empty /></td></tr>
                                                    }
                                                </tbody>

                                            </table>
                                        </TabPane>

                                        <TabPane tab={<span>Rookie</span>} key="rookie">
                                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th width='10%'>Rank</th>
                                                        <th width='30%'>Employee Name</th>
                                                        <th width='40%'>Division Name</th>
                                                        <th width='20%'>KPI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (appraisals.rookie.length !== 0) ?
                                                            appraisals.rookie.map((el, i) => (
                                                                <tr key={i}>
                                                                    <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                                    <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                                    <td style={{ verticalAlign: 'top' }}>{el.division_name ?? ''}</td>
                                                                    <td className="text-center">{el.kpi_value ?? 0}</td>
                                                                </tr>
                                                            ))
                                                            : <tr><td colSpan={4}><Empty /></td></tr>
                                                    }
                                                </tbody>

                                            </table>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                :
                                <Spinner />
                        }
                    </div>

                </Card >
            </Card >
        </div >
    );
}

export default Ranking;
