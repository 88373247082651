import React, {useEffect, useState} from 'react';
import {Card, Typography, Space, Form,Input, message, Select} from 'antd'
import { EditButton, PageTitle, Spinner, DataTable, FormModal, AddButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const {Option} = Select

const Index = () => {
    const [coas, setCoas] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState(null)
    const [saving, setSaving] = useState(false)

    const fetchCoas = () =>{
        setLoaded(false)
        SentralModel.list('Coa').then(res=>{
            setCoas(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCoas()
    }, []);

    const editCoa = (v) =>{
        setSaving(false)
        setDefaultValues({
            coa_id:v.coa_id,
            fin_statement:v.fin_statement,
            coa_number:v.coa_number,
            coa_code: v.coa_code,
            coa_name:v.coa_name,
            description:v.description
        })
        setFormModal(true)
    }

    const addCoa = () =>{
        setDefaultValues({coa_id:0})
        setFormModal(true)
    }

    const submitCoa = (v) =>{
        message.loading('Saving data',0)
        setSaving(true)
        SentralModel.save('Coa', v, v.coa_id).then(()=>{
            message.destroy()
            message.success('data successfully inserted', 2)
            setSaving(false)
            setFormModal(false)
            fetchCoas()
        }).catch(()=>{
            message.error('failed to insert data', 2)
            setSaving(false)
        })
    }

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Financial Statement',
            dataIndex: 'fin_statement',
            key: 'financial_statement'
        },{
            title: 'COA Number',
            dataIndex: 'coa_number',
            key: 'coa_number'
        },{
            title: 'COA Code',
            dataIndex: 'coa_code',
            key: 'coa_code'
        },{
            title: 'COA Name',
            dataIndex: 'coa_name',
            key: 'coa_name',
        },{
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },{
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editCoa(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const dataSource = [
        ...coas
    ]

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="COA (Chart Of Account)" breadcrumbs={[['COA']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>COA List</Text>
                        <AddButton right title="Add Coa" onAdd={() => addCoa(true)}/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
            </Card>
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={(defaultValues.coa_id)?'Update COA':'Add COA'} submitForm={(v)=>submitCoa(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="coa_id" hidden>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="fin_statement" label="Financial Statement">
                        <Select>
                            <Option value="Cash and Bank">Cash and Bank</Option>
                            <Option value="Other receivables - Third parties">Other receivables - Third parties</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="coa_number" label="COA Number">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="coa_code" label="COA Code">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="coa_name" label="COA Name">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input/>
                    </Form.Item>
                </FormModal> : null
            }
        </div>
    );
}

export default Index;
