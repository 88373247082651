import React,{useState, useEffect} from 'react';
import {Card, Row, Col, Form, Input, Select, Typography, Checkbox, message, Spin} from 'antd'
import { SentralModel } from '../../../models/SentralModel';
import { Spinner, SubmitButton } from '../../../components';
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
const {Option} = Select
const {Text, Title} = Typography

const Regulation = () => {
    const [regulationForm] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [premiCalculation, setPremiCalculation] = useState([])
    const [taxCalculation, setTaxCalculation] = useState([])
    const [employees, setEmployees] = useState([])
    const [time, setTime] = useState(0)
    const [fetching, setFetching] = useState(false)

    const fetchRegulation = (regulationForm) =>{
        setLoaded(false)
        SentralModel.get('PayrollRegulation',{},1).then(res=>{
            regulationForm.setFieldsValue({
                payroll_type:res.data.payroll_type,
                pph:res.data.pph.toString(),
                province_minimum_wage: toIdr(res.data.province_minimum_wage),
                overtime_regulation:res.data.overtime_regulation.toString(),
                absent_penalty:res.data.absent_penalty.toString(),
                absent_rate:toIdr(res.data.absent_rate),
                late_penalty:res.data.late_penalty.toString(),
                jkk:res.data.jkk.toString(),
                bpjs_healthy:res.data.bpjs_healthy.toString(),
                jkm:res.data.jkm.toString(),
                jht:res.data.jht.toString(),
                jip:res.data.jip.toString(),
                jkk_grade:res.data.jkk_grade.toString(),
                tax_calculation: res.data.tax_calculation.split(","),
                premi_calculation: res.data.premi_calculation.split(","),
                daily_incentive_regulation:res.data.daily_incentive_regulation.toString(),
                daily_incentive_rate: toIdr(res.data.daily_incentive_rate),
                weekly_incentive_regulation:res.data.weekly_incentive_regulation.toString(),
                weekly_incentive_rate: toIdr(res.data.weekly_incentive_rate),
                spt_pemotong:res.data.spt_pemotong.toString(),
                mass_leave_regulation:res.data.mass_leave_regulation.toString(),
            })
            setPremiCalculation(res.data.premi_calculation.split())
            setTaxCalculation(res.data.tax_calculation.split())
            setLoaded(true)
            setEmployees([{employee_code:res.data.spt_pemotong, name:(res.data.pemotong) ? res.data.pemotong.name : ''}])
        })
    }
    
    useEffect(() => {
        fetchRegulation(regulationForm)
    }, [regulationForm]);

    const submitRegulation = (v)=>{
        setSaving(true)
        let data={
            payroll_type:v.payroll_type,
            pph:v.pph.toString(),
            province_minimum_wage: extractNumber(v.province_minimum_wage),
            overtime_regulation:v.overtime_regulation.toString(),
            daily_incentive_regulation:v.daily_incentive_regulation.toString(),
            daily_incentive_rate: extractNumber(v.daily_incentive_rate),
            weekly_incentive_regulation:v.weekly_incentive_regulation.toString(),
            weekly_incentive_rate: extractNumber(v.weekly_incentive_rate),
            absent_penalty: v.absent_penalty,
            absent_rate:extractNumber(v.absent_rate),
            late_penalty: v.late_penalty,
            jkk:v.jkk,
            bpjs_healthy:v.bpjs_healthy,
            jkm:v.jkm,
            jht:v.jht,
            jip:v.jip,
            jkk_grade:v.jkk_grade,
            tax_calculation:taxCalculation.join(),
            premi_calculation:premiCalculation.join(),
            spt_pemotong: v.spt_pemotong,
            mass_leave_regulation: v.mass_leave_regulation
        }
        SentralModel.save('PayrollRegulation',data,1).then(()=>{
            message.success('regulation updated')
            setSaving(false)
        })
    }
    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const calculationOptions = [
        { label: 'GAPOK', value: 'GAPOK' },
        { label: 'HONORARIUM', value: 'HONORARIUM' },
        { label: 'TRANSPORT', value: 'TRANSPORT' },
        { label: 'LEMBUR', value: 'LEMBUR' },
        { label: 'LAINNYA', value: 'LAINNYA' }
    ];

    const selectPremi = (v) => {
        setPremiCalculation(v)
    }
    const selectTax = (v) => {
        setTaxCalculation(v)
    }

    const changeCurrency = (v, field) =>{
        if(v.target.value){
            regulationForm.setFieldsValue({[field]: toIdr(extractNumber(v.target.value))})
        }
    }

    const searchEmployee = (v) =>{
        if(time) clearTimeout(time)
        if(v){
            setFetching(true)
            setTime(setTimeout(() => {
                SentralModel.list('Employee',{fields:"employee_code,name", filter:[['name','like','%'+v+'%']]}).then((res) => {
                    setEmployees(res.data)
                })
            }, 1000))
        }
    }
    return (
        <div>
            <Title level={4}>Company Regulation</Title>
            {
                (loaded)?
                <Form form={regulationForm} {...formLayout} onFinish={submitRegulation}>
                    <Text strong>Company</Text>
                    <Card bordered className="mb-5">
                        <Row>
                            <Col span={12}>
                                <Form.Item label="Payroll type" name="payroll_type" required>
                                    <Select>
                                        <Option value="NETT">NETT</Option>
                                        <Option value="GROSS">GROSS</Option>
                                        <Option value="GROSS UP">GROSS UP</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="PPH" name="pph" required>
                                    <Select>
                                        <Option value="21">PPH21</Option>
                                        <Option value="23">PPH23</Option>
                                        <Option value="26">PPH26</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="UMP" name="province_minimum_wage" required>
                                    <Input onChange={(v)=>changeCurrency(v, 'province_minimum_wage')}/>
                                </Form.Item>
                                <Form.Item label="Daily Incentive" name="daily_incentive_regulation" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Daily Incentive Rate" name="daily_incentive_rate" required>
                                    <Input onChange={(v)=>changeCurrency(v, 'daily_incentive_rate')}/>
                                </Form.Item>
                                <Form.Item label="Weekly Incentive" name="weekly_incentive_regulation" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Weekly Incentive Rate" name="weekly_incentive_rate" required>
                                    <Input onChange={(v)=>changeCurrency(v, 'weekly_incentive_rate')}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Overtime Regulation" name="overtime_regulation" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Late penalty" name="late_penalty" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Absent Penalty" name="absent_penalty" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Absent Penalty Rate" name="absent_rate" required>
                                    <Input onChange={(v)=>changeCurrency(v, 'absent_rate')}/>
                                </Form.Item>
                                <Form.Item label="Regulasi Cuti Bersama" name="mass_leave_regulation" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item hidden labelCol={{span:4}} wrapperCol={{span:20}} label="Tax Calculation" name="tax_calculation" required> 
                                    <Checkbox.Group options={calculationOptions} onChange={selectTax} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Text strong>Premi</Text>
                    <Card bordered className="mb-5"> 
                        <Row>
                            <Col span={12}>
                                <Form.Item label="BPJS Kesehatan" name="bpjs_healthy" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="JKK" name="jkk" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="JKK Rate" name="jkk_grade" required>
                                    <Select>
                                        <Option value="0.24">0.24%</Option>
                                        <Option value="0.54">0.54%</Option>
                                        <Option value="0.89">0.89%</Option>
                                        <Option value="1.27">1.27%</Option>
                                        <Option value="1.74">1.74%</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="JHT" name="jht" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="JKM" name="jkm" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="JIP" name="jip" required>
                                    <Select>
                                        <Option value="1">ACTIVE</Option>
                                        <Option value="0">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item hidden labelCol={{span:4}} wrapperCol={{span:20}} label="Premi Calculation" name="premi_calculation"> 
                                    <Checkbox.Group options={calculationOptions} onChange={selectPremi} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Text strong>SPT</Text>
                    <Card bordered>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="Pemotong" name="spt_pemotong" required>
                                    <Select
                                        showSearch
                                        onSearch={searchEmployee}
                                        filterOption={false}
                                        notFoundContent={fetching ? <Spin size="small" /> : null}>
                                        {
                                            (employees).map((el, key) => (
                                                <Option key={key} value={el.employee_code}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Row className="mt-2">
                        <Col span={24}>
                            <div className="text-right">
                                <SubmitButton loading={saving}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
                : <Spinner/>
            }
        </div>
    );
}

export default Regulation;
