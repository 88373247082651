import React, {useState, useEffect} from 'react';
import {Card, Typography, Form, Input, Select, Row, Col, message} from 'antd'
import { PageTitle/*, PrintPdfButton*/, Spinner, SubmitButton} from '../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData'

const {Text} = Typography
const {TextArea} = Input;
const {Option} = Select;

const Exit = () => {
    const {trainingId} = useParams();
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);
    // const [participant, setParticipant] = useState(null)
    const [trainer, setTrainer] = useState([])

    // const fetchParticipant = (trainingId, employeeCode) => {
    //     SentralModel.list('TrainingParticipant', {filter: [['training_id', trainingId], ['employee_code', employeeCode]]}).then((participant) => {
    //         setParticipant(participant.employee_code);
    //     })
    // }

    const fetchTrainer = (trainingId) => {
        SentralModel.list('TrainingTrainer', {filter: [['training_id', trainingId]]}).then((trainer) => {
            setTrainer(trainer.data);
        })
    }

    const fetchQuestion = (trainingId, form) => {
        SentralModel.list('TrainingEvaluationQuestion', {filter: [['is_active', 1]]}).then((question) => {
            setLoaded(false)
            setQuestions(question.data)

            const result = [];
            const map = new Map();
            for (const item of question.data) {
                if(!map.has(item.category)){
                    map.set(item.category, true);
                    result.push(
                        item.category
                    );
                }
            }
            setCategories(result)

            SentralModel.list('TrainingParticipant', {filter: [['training_id', trainingId], ['employee_code', authData.employee_code]]}).then((participant) => {
                SentralModel.list('TrainingEvaluationAnswer', {filter: [['training_participant_id', participant.data[0].training_participant_id]]}).then((answer) => {
                    let defaultValue = {};
        
                    question.data.forEach(element => {
                        // defaultValue[element.question] = answer.data.find((el) => {return el.question.question === element.question}).answer
                    });
        
                    form.setFieldsValue(defaultValue)
                })
            })

        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchTrainer(trainingId);
        fetchQuestion(trainingId, form);
        // fetchParticipant(trainingId, authData.employee_code);
    }, [trainingId, form]);

    const saveExitInterview = (v) => {
        setSaving(true)

        let data = {
            sub_resign_id: trainingId,
        }

        questions.forEach(element => {
            data[element.question] = v[element.question]
        });

        SentralModel.action('Resign', 'saveExitInterview', data, trainingId).then((res)=>{
            message.success('form saved',2);
            setSaving(false)
        })
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Exit Interview" breadcrumbs={[['Resign', '/resign'], ['Exit Interview']]}></PageTitle>
            </div>

            <Card>
                {
                    (loaded) ?
                        <Form size="middle" form={form} layout="vertical" onFinish={saveExitInterview}>
                            {
                                categories.map((cat, i) => (
                                    <div key={'category_' + i}>
                                        <Text strong style={{display:'block'}}>{cat === 'PARTICIPANT' ? 'PESERTA PELATIHAN' : cat === 'SUBJECT' ? 'MATERI & PROGRAM' : cat === 'TRAINER' ? 'TRAINER' : cat === 'TRAINER2' ? 'TRAINER 2' : 'KRITIK DAN SARAN'}</Text>
                                        <hr />
                                        {
                                            cat === 'TRAINER' ?
                                            trainer.map((tra, k) => (
                                                <>
                                                    <Text strong style={{display:'block'}}>{tra.name}</Text>
                                                    <hr />
                                                    {
                                                        questions.filter(question => question.category === cat).map((ques, j) => (
                                                            <Form.Item key={'question_'+j} label={ques.question} name={ques.question+k} rules={[{required: cat === 'TRAINER2' ? false : true}]}>
                                                                {
                                                                    ques.multiple_choice === null ?
                                                                        <TextArea rows={2} placeholder="Answer..."/> :
                                                                        ques.multiple_choice === 'NUMBER' ?
                                                                            <Select placeholder="Select Answer...">
                                                                                <Option value='1'>Sangat Kurang</Option>
                                                                                <Option value='2'>Kurang</Option>
                                                                                <Option value='3'>Cukup</Option>
                                                                                <Option value='4'>Baik</Option>
                                                                                <Option value='5'>Sangat Baik</Option>
                                                                            </Select> :
                                                                            <Select placeholder="Select Answer...">
                                                                                <Option value='a'>Pengajar</Option>
                                                                                <Option value='b'>Peserta</Option>
                                                                                <Option value='c'>Pengajar dan peserta</Option>
                                                                            </Select>
                                                                }
                                                            </Form.Item>
                                                        ))
                                                    }
                                                </>
                                            )) :
                                            questions.filter(question => question.category === cat).map((ques, j) => (
                                                <Form.Item key={'question_'+j} label={ques.question} name={ques.question+j} rules={[{required: cat === 'TRAINER2' ? false : true}]}>
                                                    {
                                                        ques.multiple_choice === null ?
                                                            <TextArea rows={2} placeholder="Answer..."/> :
                                                            ques.multiple_choice === 'NUMBER' ?
                                                                <Select placeholder="Select Answer...">
                                                                    <Option value='1'>Sangat Kurang</Option>
                                                                    <Option value='2'>Kurang</Option>
                                                                    <Option value='3'>Cukup</Option>
                                                                    <Option value='4'>Baik</Option>
                                                                    <Option value='5'>Sangat Baik</Option>
                                                                </Select> :
                                                                <Select placeholder="Select Answer...">
                                                                    <Option value='a'>Pengajar</Option>
                                                                    <Option value='b'>Peserta</Option>
                                                                    <Option value='c'>Pengajar dan peserta</Option>
                                                                </Select>
                                                    }
                                                </Form.Item>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            <Row justify="space-around">
                                <Col span={24} className="text-right">
                                    <SubmitButton loading={saving}/>
                                </Col>
                            </Row>
                        </Form>
                    : <Spinner />
                }
            </Card>
        </Card>
    );
}

export default Exit;
