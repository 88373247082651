import React, {useEffect, useState} from 'react';
import {Card, Typography, message, Space} from 'antd'
import {NewButton, EditButton, DeleteButton, PageTitle, Spinner, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import moment from 'moment'

const {Text} = Typography
const Index = () => {
    const [versions, setVersions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const fetchVersions = () => {
        SentralModel.list('VersionPatch', {orderBy: 'release_date', order: 'desc'}).then(res=>{
            setLoaded(false)
            setVersions(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVersions()
    }, []);
    
    const dataSource = [
        ...versions.map( el => {
            return {
                id: el.version_patch_id,
                version: el.version,
                release_date: el.release_date,
                description: el.description,
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            width:'70px',
            key: 'version',
        },
        {
          title: 'Version',
          dataIndex: 'version',
          key: 'version',
          width: '10%'
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: '70%',
          render: (text) => (
            <p className="mt-3" dangerouslySetInnerHTML={{ __html: text }}></p>
          )
        },
        {
          title: 'Release Date',
          dataIndex: 'release_date',
          key: 'release_date',
          width: '20%',
          render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
          )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton link to={"/setting/version-patch/detail/" + row.id}/>
                        <DeleteButton onConfirm={() => deleteVersions(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const deleteVersions = (id) =>{
        message.loading('deleting version')
        SentralModel.deleteById('VersionPatch', id).then((res)=>{
            message.destroy()
            message.success('Version deleted', 2)
            let tmp = versions
            let index = tmp.findIndex(el => el.version_patch_id===id)
            tmp.splice(index, 1);
            setVersions([])
            setVersions(tmp)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Version Patches" breadcrumbs={[['Version Patch']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Version Patch List</Text>
                        <NewButton right to="/setting/version-patch/detail/0" title="New Patch"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
