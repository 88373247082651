import React, {useState} from 'react';
import {Card, Button, Col, Row, DatePicker, message} from 'antd';
import {FileExcelOutlined} from '@ant-design/icons'
import { PageTitle, DataTable, Spinner} from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment';

const TrainingHours = () => {
    const [loaded, setLoaded] = useState(true)
    const [trainingHours, setTrainingHours] = useState([])
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const onChangePeriod = (v) => {
        let year = moment(v).format('YYYY');
        let month = moment(v).format('MM');

        setSelectedYear(year);
        setSelectedMonth(month);

        fetchTrainingHours(year, month)
    }

    const fetchTrainingHours = (year, month) => {
        setLoaded(false)
        SentralModel.action('Training', 'getTotalTraining', {year: year, month: month}, 0).then(res => {
            setTrainingHours(res.data)
            setLoaded(true)
        })
    }

    const exportReport = () => {
        if (selectedYear === null) {
            message.error('Please select Period first !!')
        } else {
            window.open(process.env.REACT_APP_API_URL +`/report/training-hours?year=${selectedYear}&month=${selectedMonth}`);
        }
    }

    const columns = [
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Internal',
            dataIndex: 'internal_hours',
            key: 'internal_hours',
            width: 120,
            render: (total) => {
                let hours = Math.floor(total / 60);
                let minutes = total % 60;

                return total === 0 ? 
                    <div className="text-center">
                        {total} menit
                    </div> :
                        <div className="text-center">
                            {total} menit ({hours} jam {minutes} menit)
                        </div>
            }
        },
        {
            title: 'External',
            dataIndex: 'external_hours',
            key: 'external_hours',
            width: 120,
            render: (total) => {
                let hours = Math.floor(total / 60);
                let minutes = total % 60;

                return total === 0 ? 
                    <div className="text-center">
                        {total} menit
                    </div> :
                        <div className="text-center">
                            {total} menit ({hours} jam {minutes} menit)
                        </div>
            }
        },
        {
            title: 'Total Hours',
            dataIndex: 'total_hours',
            key: 'total_hours',
            width: 120,
            render: (total) => {
                let hours = Math.floor(total / 60);
                let minutes = total % 60;

                return total === 0 ? 
                    <div className="text-center">
                        {total} menit
                    </div> :
                        <div className="text-center">
                            {total} menit ({hours} jam {minutes} menit)
                        </div>
            }
        },
    ];
    
    return (
        <Card className="content-container">
            <PageTitle title="Training Hours Report" breadcrumbs={[['Training Hours']]}></PageTitle>

            <Row justify="end" className="mb-3">
                <Col span={24} >
                    <span style={{float:'right'}}>
                        <DatePicker picker="month" style={{ marginRight:10 }} onChange={onChangePeriod} />
                        <Button type="primary" onClick={() => exportReport()} color="#ededed" icon={<FileExcelOutlined />}>
                            Export
                        </Button>
                    </span>
                </Col>
            </Row>
            {
                (loaded) ?
                    <DataTable pagination={false} size="small" columns={columns} dataSource={trainingHours} bordered/>
                :<Spinner/>
            }
        </Card>
    );
}

export default TrainingHours;
