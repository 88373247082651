import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, TimePicker } from 'antd';
import { SubmitButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment';

const { RangePicker } = TimePicker;
const RequestForm = (props) => {
    const { overtimeData = null } = props
    const [overtimeForm] = Form.useForm()
    const [types, setTypes] = useState([])

    const fetchTypes = () => {
        SentralModel.list('GeneralDetail', { filter: [["general_code", "OT"]] }).then((res) => {
            setTypes(res.data)
        })
    }

    const setDefaultFormValue = (overtimeData, overtimeForm) => {
        overtimeForm.setFieldsValue({
            date: moment(overtimeData.from_date),
            time: [moment(overtimeData.from_time, 'HH:mm:ss'), moment(overtimeData.to_time, 'HH:mm:ss')],
            date_realization: moment(overtimeData.from_date),
            time_realization: [moment(overtimeData.from_time, 'HH:mm:ss'), moment(overtimeData.to_time, 'HH:mm:ss')],
            necessity: overtimeData.description,
            location: overtimeData.overtime_type.detail_code
        })
    }

    useEffect(() => {
        fetchTypes()
        if (overtimeData) {
            setDefaultFormValue(overtimeData, overtimeForm)
        }
    }, [overtimeData, overtimeForm]);

    const disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    return (
        <Form form={overtimeForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={props.onSubmit}>
            <Row justify="center">
                <Col span={10}>
                    <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                        <DatePicker style={{ width: '100%' }} onChange={props.onChangeDate} disabled={overtimeData} disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item name="time" label="Time" rules={[{ required: true }]}>
                        {
                            props.defaultDate === null ?
                                <RangePicker format="HH:mm" style={{ width: '100%' }} onChange={props.onChangeTime} disabled /> :
                                <RangePicker format="HH:mm" style={{ width: '100%' }} onChange={props.onChangeTime} disabled={overtimeData} />
                        }
                    </Form.Item>
                    {
                        (overtimeData) ?
                            <Form.Item name="date_realization" label="Date (Realization)" rules={[{ required: true }]}>
                                <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} disabled />
                            </Form.Item>
                            : null
                    }

                </Col>
                <Col span={10}>
                    <Form.Item name="necessity" label="Necessity" rules={[{ required: true }]}>
                        <Input disabled={overtimeData} />
                    </Form.Item>
                    <Form.Item name="location" label="Overtime Location" rules={[{ required: true }]}>
                        <Select disabled={overtimeData}>
                            {
                                types.map((el, key) => (
                                    <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    {
                        (overtimeData) ?
                            <Form.Item name="time_realization" label="Time (Realization)" rules={[{ required: true }]}>
                                <RangePicker format="HH:mm" style={{ width: '100%' }} />
                            </Form.Item>
                            : null
                    }
                </Col>
                <Col span={20} className="text-right">
                    <SubmitButton loading={props.saving} />
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
