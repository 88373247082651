import React from 'react';
import dashboard from '../assets/icons/dashboard.svg'
import submission from '../assets/icons/submission.svg'
import approvalCenter from '../assets/icons/approvalCenter.svg'
import document from '../assets/icons/document.svg'
import management from '../assets/icons/management.svg'
import payroll from '../assets/icons/payroll.svg'
import report from '../assets/icons/report.svg'
import setting from '../assets/icons/setting.svg'
import bell from '../assets/icons/bell.svg'
import recruitment from '../assets/icons/recruitment.svg'
import user from '../assets/icons/user.svg'
import users from '../assets/icons/users.svg'
import layers from '../assets/icons/layers.svg'

const Icon = (props) => {
    const { name = "" } = props

    const getSource = (name) => {
        switch (name) {
            case "dashboard":
                return dashboard;
            case "submission":
                return submission;
            case "approvalCenter":
                return approvalCenter;
            case "document":
                return document;
            case "management":
                return management;
            case "payroll":
                return payroll;
            case "report":
                return report;
            case "setting":
                return setting;
            case "bell":
                return bell;
            case "recruitment":
                return recruitment;
            case "user":
                return user;
            case "users":
                return users;
            case "general-affair":
                return layers;
            case "actionPlan":
                return document
            case "itInfra":
                return layers
            default:
                return dashboard;
        }
    }
    return (
        <img src={getSource(name)} alt="icon" style={{ width: '20px', height: '20px' }} />
    );
}

export default Icon;
