import React, { useEffect, useState } from 'react';
import { Card, Typography, Descriptions, Button, Popconfirm, message, Row, Col, Space } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useParams, useHistory } from 'react-router-dom';
import { formatDateTime, toFullDate } from '../../../utils/dateHandler';
import { PlusCircleOutlined/*, CheckCircleOutlined*/ } from '@ant-design/icons';
// import { Link } from 'react-router-dom'
import moment from 'moment'
import authData from '../../../helpers/authData'

const { Text } = Typography
const Detail = () => {
    const history = useHistory()
    const { trainingId } = useParams()
    const [training, setTraining] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [status, setStatus] = useState(null)
    const [absence, setAbsence] = useState(false)

    const fetchTraining = (trainingId) => {
        setLoaded(false)
        SentralModel.get('Training', {}, trainingId).then(res => {
            setTraining(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const checkStatus = (trainingId) => {
        SentralModel.action('Training', 'checkStatus', { training_id: trainingId }, 0).then((res) => {
            setStatus(res.message)
            // console.log(res.message)
        })
    }

    const checkConfirm = (trainingId) => {
        SentralModel.action('Training', 'trainingAbsenceStatus', { training_id: trainingId, employee_code: authData.employee_code }, 0).then((res) => {
            if (res.data.length === 0) {
                setAbsence(false)
            } else {
                setAbsence(true)
            }
        })
    }

    useEffect(() => {
        fetchTraining(trainingId)
        checkStatus(trainingId)
        checkConfirm(trainingId)
    }, [trainingId]);

    const requestJoin = () => {
        setSaving(true)
        let data = {
            training_id: trainingId
        }
        SentralModel.action('Submission', 'saveTrainingRequest', data).then(() => {
            setSaving(false)
            message.destroy()
            message.success('Pengajuan terkirim', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/request-training');
            }, 1000);
        }).catch(() => {
            setSaving(false)
            message.destroy()
            message.error('oops, something went wrong', 2)
            setSaving(false)
        })
    }

    const submitAbsence = () => {
        let payload = {
            training_id: trainingId,
            employee_code: authData.employee_code
        }

        SentralModel.action('Training', 'confirmTrainingAbsence', payload, 0).then((res) => {
            message.success('Success Confirm Absence')
            checkConfirm(trainingId)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Training and Certification" breadcrumbs={[['Training and Certification', '/request-training'], ['Detail']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Row justify='space-between'>
                            <Col>
                                <Text strong>Training and Certification Detail</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <Popconfirm disabled={status !== 'ok'} title="Request join pelatihan ?" okText="Yes" cancelText="No" onConfirm={() => requestJoin()}>
                                        <Button disabled={status !== 'ok'} loading={saving} type="primary" icon={<PlusCircleOutlined />}>
                                            {
                                                (status === 'ok') ?
                                                    'Request Join'
                                                    : (status === 'expired') ?
                                                        'Pendaftaran ditutup'
                                                        : (status === 'requested') ?
                                                            'Menunggu Persetujuan'
                                                            : 'Sudah Terdaftar'
                                            }
                                        </Button>
                                    </Popconfirm>
                                    {/* {
                                            (status === 'joined') ?
                                                <Link to={'/request-training/done/'+trainingId}>
                                                    <Button disabled={status==='done'} loading={saving} type="primary" style={{float:'right', marginLeft: '10px'}} icon={<CheckCircleOutlined/>}>
                                                        Done
                                                    </Button>
                                                </Link>
                                            : null
                                    } */}
                                    {
                                        (status === 'joined') ?
                                            absence ?
                                                <Button disabled type='primary' onClick={submitAbsence}>
                                                    Konfirmasi Absensi
                                                </Button>
                                                :
                                                <Button type='primary' onClick={submitAbsence}>
                                                    Konfirmasi Absensi
                                                </Button>
                                        : null
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </div>}>

                    {
                        (loaded) ?
                            <Descriptions layout="horizontal" bordered column={1}>
                                <Descriptions.Item label={<span className="text-bold">Judul</span>}>
                                    {training.title}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Tanggal</span>}>
                                    {toFullDate(training.training_date)}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Waktu</span>}>
                                    {moment(training.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(training.end_time, 'HH:mm:ss').format('HH:mm')}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Batas Registrasi</span>}>
                                    {formatDateTime(training.register_limit)}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Deskripsi</span>}>
                                    {training.description}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Kuota</span>}>
                                    {training.quota}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Sertifikat</span>}>
                                    {training.certificate ? 'Ya' : 'Tidak'}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Dihitung Jam</span>}>
                                    {training.is_count === '1' ? 'Ya' : 'Tidak'}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold" style={{ verticalAlign: 'top' }}>Trainer</span>}>
                                    <ol style={{ paddingLeft: 20 }}>
                                        {
                                            training.trainers.map((el, key) => (
                                                <li key={key}>{el.name}</li>
                                            ))
                                        }
                                    </ol>
                                </Descriptions.Item>
                            </Descriptions>
                            :
                            <Spinner />
                    }
                </Card >
            </Card >

        </div >
    );
}

export default Detail;
