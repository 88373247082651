import React, {useEffect, useState} from 'react';
import {Card, Space, Select} from 'antd';
import {SettingOutlined} from '@ant-design/icons'
import {PageTitle, Spinner} from '../../../components';
import {EditButton,CustomButton, DataTable} from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {setSessionStorage, getSessionStorage} from '../../../helpers/sessionStorage';
import {toIdr} from '../../../utils/currencyHandler';

const {Option} = Select

const Index = () => {
    const [loaded, setLoaded] = useState(true)
    const [divisions, setDivisions] = useState([])
    const [approvals, setApprovals] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)

    const initialDivision = () => {
        if(getSessionStorage('approvalDivision')){
            return parseInt(getSessionStorage('approvalDivision'))
        }
        return null
    } 

    const fetchDivisions  = () =>{
        SentralModel.list('Division', {fields:"division_id,division_name"}).then((res) => {
            setDivisions(res.data)
        })
    }

    const fetchApprovalData = (divisionId) => {
        SentralModel.action('Approval','schema',{division_id:divisionId},0).then((res) => {
            setApprovals(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDivisions()
        const defaultDivision = getSessionStorage('approvalDivision')
        if(defaultDivision){
            fetchApprovalData(defaultDivision)
            setSessionStorage('approvalDivision', defaultDivision)
            setSelectedDivision(defaultDivision)
        }
    }, []);

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            className:'text-top',
            align:'center',
            key: 'name',
        },
        {
            title: 'Schema Name',
            dataIndex: 'schema_name',
            className:'text-top',
            key: 'schema_name',
        },{
            title: 'Range ',
            className:'text-top',
            render: (row) => (
                (row.category==='PURCHASEONBUDGET') ? 
                <span>{toIdr(row.limit1)} - {toIdr(row.limit2)}</span>
                : '-'
            )
        },{
            title: 'Approver',
            render: (row) =>(
                <ol style={{paddingLeft:'1em', marginBottom:0}}>
                {
                    row.approver.map((el, key) => (
                        <li key={'approver_'+key}>{el.employee ? el.employee.name : null}</li>
                    ))
                }
                </ol>
            )
        },{
            title: 'Action',
            className:'text-top',
            align:'center',
            width:'10%',
            render: (row) =>(
                <Space>
                    <EditButton link to={'/management/approval-schema/detail?division='+selectedDivision+'&schema='+row.id}/>
                    <CustomButton icon={<SettingOutlined/>} link to={'/management/approval-schema/custom?schema='+row.id+'&division='+selectedDivision}/>
                </Space>
            )
        }
    ];
    const dataSource = [
        ...approvals.map((el) => {
            return {
                id: el.approval_schema_id,
                schema_name: el.description,
                category: el.category,
                limit1: el.limit1,
                limit2: el.limit2,
                approver: el.approver
            }
        })
    ]

    const changeDivision = (v)=>{
        setLoaded(false)
        fetchApprovalData(v)
        setSessionStorage('approvalDivision', v)
        setSelectedDivision(v)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Approval Schema" breadcrumbs={[['Approval Schema']]}></PageTitle>
                <div className="full-width">
                    <span className="mb-3" style={{float:'right'}}>
                        <Space>
                            <Select defaultValue={initialDivision} placeholder="Select Division" style={{ width: 250}} onChange={changeDivision} loading={divisions.length===0}>
                                {
                                    divisions.map((el, key)=>(
                                        <Option key={'division_'+key} value={el.division_id}>{el.division_name}</Option>
                                    ))
                                }
                            </Select>
                        </Space>
                    </span>
                </div>
                {
                    (loaded) ?
                    <DataTable bordered dataSource={dataSource} columns={columns}/>
                    :
                    <Spinner/>
                }
            </Card>
        </div>
    );
}

export default Index;
