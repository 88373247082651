export const toIdr = (v) => {
    var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(v)
}

export const extractNumber = (v) => {
    let num = v.replace(/[^0-9-]+/g,"");
    let converted = Number(num)
    return parseInt(converted)
}

export const formatNumber = (v) =>{
    return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const kFormatter = (v) =>{
    return Math.abs(v) > 999 ? formatNumber(Math.sign(v)*((Math.abs(v)/1000).toFixed(1))) + 'k' : Math.sign(v)*Math.abs(v)
}