import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';

const {Text} = Typography

const Detail = () => {
    const {stationeryId} = useParams()
    const [stationery, setStationery] = useState(null)

    const fetchStationery = (id) => {
       SentralModel.get('SubmissionStationery', {}, id).then((res)=>{
           setStationery(res.data)
       })
    }

    useEffect(() => {
        fetchStationery(stationeryId)
    }, [stationeryId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/stationery/' + stationeryId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Office Stationery" breadcrumbs={[['Office Stationery', '/office-stationery'], ['Detail']]}></PageTitle>
            </div>
            {
                (stationery) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <DetailHeader 
                        name={stationery.employee.name} 
                        date={stationery.created_at}
                        status={stationery.status} />

                    <Card bordered className="mb-4">
                        <Text strong>REQUEST INFORMATION</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{stationery.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{stationery.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{stationery.employee.position.position_name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Text strong className="mt-3">REQUEST ITEMS</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Code</th><th>Item Name</th><th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            stationery.items.map((el, key) => (
                                                <tr key={key}>
                                                    <td>{el.item.item_code}</td>
                                                    <td>{el.item.item_name}</td>
                                                    <td>{el.quantity}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    
                    <Card bordered>
                        <ApprovalLog category="STATIONERY" 
                            approvers={stationery.approver} />
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
