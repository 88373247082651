import React, { useState, useEffect } from 'react'
import { Card, Row, Col } from 'antd'
import { DataTable, PageTitle, Spinner } from '../../../components'
import { useParams } from 'react-router-dom'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const Detail = () => {
    const data = useParams()
    const [loaded, setLoaded] = useState(false)
    const [employee, setEmployee] = useState([])
    const [annualDetail, setAnnualDetail] = useState([])
    const [massLeave, setMassLeave] = useState(null)

    // var year = moment().year();

    const fetchEmployee = (data) => {
        setLoaded(false)
        let payload = {
            employee_code: data.employeeCode,
            year: data.year
        }

        SentralModel.action('Report', 'leaveAnnualDetail', payload).then(res => {
            setEmployee(res.data.employee[0])
            setAnnualDetail(res.data.annualLeave)
            setMassLeave(res.data.massLeave)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmployee(data)

    }, [data]);

    const columns = [
        {
            title: 'From Date',
            key: 'from_date',
            dataIndex: 'from_date',
            render: (text) => (
                moment(text).format('DD MMMM YYYY')
            )
        },
        {
            title: 'To Date',
            key: 'to_date',
            dataIndex: 'to_date',
            render: (text) => (
                moment(text).format('DD MMMM YYYY')
            )
        },
        {
            title: 'Total Days',
            key: 'total_days',
            dataIndex: 'total_days',
            align: 'center',
            width: '10',
            render: (text) => (
                `${text} Day`
            )
        },
        {
            title: 'Reason',
            key: 'reason',
            dataIndex: 'reason'
        },
    ]

    return (
        <Card className="content-container">
            <PageTitle title="Annual Leave Report Detail" breadcrumbs={[['Annual Leave Report'], ['Detail']]}></PageTitle>
            <Card bordered className="mb-4">
                <Row className="mb-4 mt-1">
                    <Col span={12}>
                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <th>Employee Code</th><td>{employee.employee_code}</td>
                                </tr>
                                <tr>
                                    <th>Employee Name</th><td>{employee.employee_name}</td>
                                </tr>
                                <tr>
                                    <th>Division</th><td>{employee.division_name}</td>
                                </tr>
                                <tr>
                                    <th>Position</th><td>{employee.position_name}</td>
                                </tr>
                                <tr>
                                    <th>Quota</th><td>{employee.quota} Day</td>
                                </tr>
                                <tr>
                                    <th>Used</th><td>{employee.used} Day</td>
                                </tr>
                                <tr>
                                    <th>Mass Leave</th><td>{massLeave} Day</td>
                                </tr>
                                <tr>
                                    <th>Balance</th><td>{employee.balance - massLeave} Day</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Card>

            <Card>
                {
                    loaded ?
                        <DataTable dataSource={annualDetail} columns={columns} />
                        : <Spinner />
                }
            </Card >
        </Card >
    )
}

export default Detail