import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, TimePicker, message, Space} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import moment from 'moment'

const {Text} = Typography
const Index = () => {
    const [validityHours, setValidityHours] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)

    const fetchValidityHours = () =>{
        SentralModel.list('ValidityHour').then(res=>{
            setValidityHours(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchValidityHours()
    }, []);
    
    const dataSource = [
        ...validityHours.map( el => {
            return {
                id: el.validity_hour_id,
                category: el.category,
                description: el.description,
                start_time: el.start_time,
                end_time: el.end_time
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
          title: 'Category',
          dataIndex: 'category',
          key: 'category',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        {
            title: 'Validity Hours',
            render: (row) =>(
                <div className="text-center">
                    <span>
                        {moment(row.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.end_time, 'HH:mm:ss').format('HH:mm')}
                    </span>
                </div>
            )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editValidityHour(row)}/>
                        <DeleteButton onConfirm={() => deleteValidityHour(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];
    
    const addValidityHour=()=>{
        setModalTitle('Add Validity Hour')
        setDefaultValues({
            validity_hour_id: 0,
            category: '',
            description: '',
            start_time: '',
            end_time: '',
        })
        setFormModal(true)
    }

    const editValidityHour = (v) =>{
        setModalTitle('Edit Validity Hour')
        setDefaultValues({
            validity_hour_id: v.id,
            category: v.category,
            description: v.description,
            start_time: moment(v.start_time, 'HH:mm'),
            end_time: moment(v.end_time, 'HH:mm'),
        })
        setFormModal(true)
    }

    const deleteValidityHour = (id) =>{
        message.loading('deleting validity hour')
        SentralModel.deleteById('ValidityHour', id).then((res)=>{
            message.destroy()
            message.success('Validity Hour deleted', 2)
            let tmp = validityHours
            let index = tmp.findIndex(el => el.validity_hour_id===id)
            tmp.splice(index, 1);
            setValidityHours([])
            setValidityHours(tmp)
        })
    }

    const saveValidityHour = (v) => {
        setSaving(true)
        let payload={
            validity_hour_id:v.validity_hour_id,
            category: v.category,
            description: v.description,
            start_time: moment(v.start_time).format('HH:mm'),
            end_time: moment(v.end_time).format('HH:mm')
        }
        SentralModel.save('ValidityHour', payload, v.validity_hour_id).then((res)=>{
            setFormModal(false)
            fetchValidityHours();
            message.success('Validity hour saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Validity Hours" breadcrumbs={[['Validity Hour']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Validity Hour List</Text>
                        <AddButton right onAdd={addValidityHour} title="New Validity Hour"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveValidityHour(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="validity_hour_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Category" name="category" rules={[{required:true}]}>
                            <Input placeholder="Category" />
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{required:true}]}>
                            <Input placeholder="Description" />
                        </Form.Item>
                        <Form.Item label="Start Time" name="start_time" rules={[{required:true}]}>
                            <TimePicker format="HH:mm" style={{width:'100%'}}/>
                        </Form.Item>
                        <Form.Item label="End Time" name="end_time" rules={[{required:true}]}>
                            <TimePicker format="HH:mm" style={{width:'100%'}}/>
                        </Form.Item>
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
