import React from 'react';
import {Card, Typography, Space, Button} from 'antd';
import {Link} from 'react-router-dom'

const {Title, Text} = Typography

const NotFound = () => {
    return (
        <Card className="content-container text-center">
            <img style={{width:400, marginTop:20}} alt="not-found" src={process.env.PUBLIC_URL + '/images/notfound.png'}/>
            <Title level={2}>Page Not Found</Title>
            <Text>Make sure the address you are trying to open is correct, and you have the right access</Text><br/><br/>
            <Space>
                <Link to="/">
                    <Button type="link">Back to Home</Button>
                </Link>
            </Space>
        </Card>
    );
}

export default NotFound;
