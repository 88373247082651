import React, {useEffect, useState} from 'react';
import {Card, Typography, message, Space} from 'antd'
import {NewButton, DeleteButton, PageTitle, Spinner, DataTable, ShowButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {toFullDate} from '../../../utils/dateHandler'
import {CheckCircleTwoTone} from '@ant-design/icons'
import moment from 'moment'

const {Text} = Typography
const Index = () => {
    const [trainings, setTrainings] = useState([])
    const [loaded, setLoaded] = useState(false)
    
    const fetchTrainings = () =>{
        setLoaded(false)
        SentralModel.list('Training').then(res=>{
            setTrainings(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, []);
    
    const dataSource = [
        ...trainings
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            render : (text) => {
                const obj = {
                    children: text,
                    props: {
                        style: {
                            verticalAlign:'top',
                            textAlign:'center'
                        }
                    },
                };
                return obj
            }
        },
        {
            title: 'Title',
            render : (row) => {
                const obj = {
                    children: row.title,
                    props: {
                        style: {
                            verticalAlign:'top'
                        }
                    },
                };
                return obj
            }
        },{
            title: 'Date',
            render : (row) =>{
                const obj = {
                    children: toFullDate(row.training_date),
                    props: {
                        style: {
                            verticalAlign:'top',
                            textAlign:'center'
                        }
                    },
                };
                return obj
            },
        },{
            title: 'Time',
            render: (row) => {
                const obj = {
                    children: <span>{moment(row.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.end_time, 'HH:mm:ss').format('HH:mm')}</span>,
                    props: {
                        style: {
                            verticalAlign:'top',
                            textAlign:'center'
                        }
                    },
                };
                return obj
            }
        },
        {
            title: 'Sertifikat',
            render : (row) =>{
                const obj = {
                    children: row.certificate ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : '-',
                    props: {
                        style: {
                            verticalAlign:'top',
                            textAlign:'center'
                        }
                    },
                };
                return obj
            }
        },{
            title: 'Trainer',
            render: (row) => (
                <ol style={{paddingLeft:20}}>
                {
                    row.trainers.map((el, index) => (
                        <li key={index}>{el.name}</li>
                    ))
                }
                </ol>
            ),
        },{
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <ShowButton link to={'/training/edit/'+row.training_id}/>
                        <DeleteButton onConfirm={() => deleteTrainings(row.training_id)}/>
                    </Space>
                </div>
            )
        },
    ];
    const deleteTrainings = (id) =>{
        message.loading('deleting training')
        SentralModel.deleteById('Training', id).then((res)=>{
            message.destroy()
            message.success('Training deleted', 2)
            let tmp = trainings
            let index = tmp.findIndex(el => el.training_id===id)
            tmp.splice(index, 1);
            setTrainings([])
            setTrainings(tmp)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Pelatihan dan Sertifikasi" breadcrumbs={[['Pelatihan dan Sertifikasi']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>List Pelatihan dan Sertifikasi</Text>
                        <NewButton to="/training/create" right title="Tambah Baru"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
