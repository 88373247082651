import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Space, Typography } from 'antd'
import { DataTable, NewButton, PageTitle, ShowButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Index = () => {
    const [loaded, setLoaded] = useState(false)
    const [loan, setLoan] = useState([])

    const fetchLoan = () => {
        setLoaded(false)
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionLoan', payload).then((res) => {
            setLoan(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoan()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (row) => (
                toIdr(row)
            ),
        },
        {
            title: 'Start Period',
            dataIndex: 'start_period',
            key: 'start_period',
        },
        {
            title: 'Loan Period',
            dataIndex: 'loan_period',
            key: 'loan_period',
            render: (row) => (
                `${row} Month`
            ),
            align: 'center',
            width: '9%'
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    <ShowButton link to={`/loan/detail/${row.sub_loan_id}`} />
                </Space>
            )
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Loan" breadcrumbs={[['Loan']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Row justify='space-between'>
                        <Col>
                            <Text strong>Loan</Text>
                        </Col>
                        <Col>
                            <NewButton title="New Loan" to='/loan/create/0' />
                        </Col>
                    </Row>
                </div>
            }>
                {
                    loaded ?
                        <DataTable size="small" columns={columns} dataSource={loan} bordered />
                        : <Spinner />
                }
            </Card>
        </Card>
    )
}

export default Index