import React, {useState, useEffect} from 'react';
import {Card, Form, Row, Col, Input, DatePicker, TimePicker, Typography, message} from 'antd';
import {ImageViewer, PageTitle, SubmitButton, Uploader} from '../../../components'
import {SentralModel} from '../../../models/SentralModel';
import {useHistory} from 'react-router-dom'
import moment from 'moment'

const {Text} = Typography
const {TextArea} = Input

const ExternalForm = () => {
    const history = useHistory()
    const [trainingForm] = Form.useForm()
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        
    }, []);

    const onSubmit = (v) => {
        if (!attachmentFile) {
            message.error('Attachment is required', 2)
        } else {
            let data = {
                title: v.title,
                date: moment(v.date).format('YYYY-MM-DD'),
                start_time: moment(v.start_time).format('HH:mm'),
                end_time: moment(v.end_time).format('HH:mm'),
                organizer: v.organizer,
                trainer: v.trainer,
                attachment: attachmentFile
            }
            // console.log(data)
            submitTraining(data)
        }
    }

    const disabledTrainingDate = (current) => {
        return current > moment().startOf('day').add(1, 'days');
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    const submitTraining = (v) => {
        setSaving(true)
        SentralModel.action('Training', 'saveTrainingExternal', v, 0).then(() => {
            message.destroy()
            message.success('Training submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/request-training');
            }, 1000);
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
            setSaving(false)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Training and Certification" breadcrumbs={[['Training and Certification','/request-training'],['Add']]}></PageTitle>
            <Form form={trainingForm} size="middle" layout="vertical" onFinish={onSubmit}>
            <Row justify="space-around" className="mb-3">
                <Col span={12} style={{paddingRight:'10px'}}>
                    <Form.Item name="title" label="Title" rules={[{required:true}]}>
                        <Input/>
                    </Form.Item>
                    <Row justify="space-between">
                        <Col span={12} style={{paddingRight:'10px'}}>
                            <Form.Item name="start_time" label="Start Time" rules={[{required:true}]}>
                                <TimePicker format="HH:mm" style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft:'10px'}}>
                            <Form.Item name="end_time" label="End Time" rules={[{required:true}]}>
                                <TimePicker format="HH:mm" style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="trainer" label="Trainer" rules={[{required:true}]}>
                        <TextArea />
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingLeft:'10px'}}>
                    <Form.Item name="date" label="Training Date" rules={[{required:true}]}>
                        <DatePicker format="YYYY-MM-DD" style={{width:'100%'}} disabledDate={disabledTrainingDate}/>
                    </Form.Item>
                    <Form.Item name="organizer" label="Organizer" rules={[{required:true}]}>
                        <Input/>
                    </Form.Item>
                    <Row style={{marginBottom: '8px'}}>
                        <Text style={{display: 'block', color: '#ed5b56'}}>*</Text>
                        <Text style={{display: 'block', marginLeft: '5px'}}>Attachment (screenshot, photo, certificate, etc)</Text>
                    </Row>
                    <Row span={12}>
                        <Form.Item>
                            <Uploader folder="training-external" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                        </Form.Item>
                        <div style={{ width: 200, maxWidth: '100%', marginLeft: 20, border: attachmentFile !== null ? attachmentFile.includes('.pdf') ? '0px' : '1px solid #ededed' : '0px' }}>
                            {
                                attachmentFile !== null ?
                                attachmentFile.includes('.pdf') ?
                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + attachmentFile}>
                                        Show Attachment
                                    </a> :
                                    <ImageViewer previewable={attachmentFile ? true : false} src={process.env.REACT_APP_API_FILE + "/" + attachmentFile} alt="attachment" /> 
                                : null
                            }
                        </div>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="text-right mt-3">
                    <SubmitButton loading={saving || uploading}/>
                </Col>
            </Row>
        </Form>
        </Card>
    );
}

export default ExternalForm;
