import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, Typography, DatePicker, Button } from 'antd';
import { PageTitle, DataTable, Spinner, BasicModal, ViewButton2 } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import {/*useHistory,*/ useParams } from 'react-router-dom'
import moment from 'moment';
import { FileExcelOutlined } from '@ant-design/icons';

const { Text } = Typography

const Detail = () => {
    // const history = useHistory()
    const { voteId } = useParams()
    const [loaded, setLoaded] = useState(false);
    const [form] = Form.useForm();
    const [modal, setModal] = useState(false);
    const [voteResult, setVoteResult] = useState([]);
    const [voteAcc, setVoteAcc] = useState([]);
    const [voteReject, setVoteReject] = useState([]);
    const [voteNetral, setVoteNetral] = useState([]);
    const [value, setValue] = useState(0);

    const fetchVoteResult = (voteId, form) => {
        setLoaded(false)
        SentralModel.list('Vote', { filter: [['vote_id', voteId]] }).then(res => {
            form.setFieldsValue({
                title: res.data[0].title,
                due_date: moment(res.data[0].due_date, 'YYYY-MM-DD'),
            })
            setVoteResult(res.data);
            setLoaded(true)
        })
    }

    const fetchVoteResultAcc = (voteId) => {
        setLoaded(false)
        SentralModel.action('Report', 'voteAcc', { vote_id: voteId }, 0).then((res) => {
            setVoteAcc(res.data);
            setLoaded(true)
        })
    }

    const fetchVoteResultReject = (voteId) => {
        setLoaded(false)
        SentralModel.action('Report', 'voteReject', { vote_id: voteId }, 0).then((res) => {
            setVoteReject(res.data);
            setLoaded(true)
        })
    }

    const fetchVoteResultNetral = (voteId) => {
        setLoaded(false)
        SentralModel.action('Report', 'voteNetral', { vote_id: voteId }, 0).then((res) => {
            setVoteNetral(res.data);
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVoteResult(voteId, form)
        fetchVoteResultAcc(voteId)
        fetchVoteResultReject(voteId)
        fetchVoteResultNetral(voteId)
    }, [voteId, form]);

    const modalVoteAcc = () => {
        setValue(1);
        setModal(true);
    }

    const modalVoteReject = () => {
        setValue(2);
        setModal(true);
    }

    const modalVoteNetral = () => {
        setValue(3);
        setModal(true);
    }

    // const modalVoteAll = () => {
    //     setValue(4);
    //     setModal(true)
    // }

    const columns = [
        {
            title: 'Accept',
            render: () => (
                <ViewButton2 onView={modalVoteAcc} color="#47b538" >
                    {(voteAcc).length}
                </ViewButton2 >
            ),
            key: 'accept',
            align: 'center'
        },
        {
            title: 'Reject',
            render: () => (
                <ViewButton2 onView={modalVoteReject} color="#d13d3d">
                    {(voteReject).length}
                </ViewButton2>
            ),
            key: 'reject',
            align: 'center'
        },
        {
            title: 'No Voting',
            render: () => (
                <ViewButton2 onView={modalVoteNetral}>
                    {(voteNetral).length}
                </ViewButton2>
            ),
            key: 'netral',
            align: 'center'
        },
        // {
        //     title: 'Action',
        //     width: 120,
        //     align: 'center',
        //     render: () => (
        //         <ViewButton2 icon={<ZoomInOutlined />} onView={modalVoteAll} />
        //     )
        // },
    ];

    const columnAcc = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Employee Name',
            dataIndex: 'acc_name',
            key: 'name'
        },
        {
            title: 'Division',
            dataIndex: 'acc_division',
            key: 'division_name'
        }
    ]

    const columnReject = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Employee Name',
            dataIndex: 'reject_name',
            key: 'name'
        },
        {
            title: 'Division',
            dataIndex: 'reject_division',
            key: 'division_name'
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason'
        },
    ]


    const columnNetral = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Employee Name',
            dataIndex: 'netral_name',
            key: 'name'
        },
        {
            title: 'Division',
            dataIndex: 'netral_division',
            key: 'division_name'
        }
    ]

    // const allColumn = [
    //     {
    //         title: 'No',
    //         dataIndex: 'no',
    //         key: 'no',
    //         width: '5%'
    //     },
    //     {
    //         title: 'Accept',
    //         children: [
    //             {
    //                 title: 'Employee Name',
    //                 dataIndex: 'acc_name',
    //                 key: 'name'
    //             },
    //             {
    //                 title: 'Division',
    //                 dataIndex: 'acc_division',
    //                 key: 'division_name'
    //             }
    //         ],
    //         key: 'accept'
    //     },
    //     {
    //         title: 'Reject',
    //         children: [
    //             {
    //                 title: 'Employee Name',
    //                 dataIndex: 'reject_name',
    //                 key: 'name'
    //             },
    //             {
    //                 title: 'Division',
    //                 dataIndex: 'reject_division',
    //                 key: 'division_name'
    //             },
    //             {
    //                 title: 'Reason',
    //                 dataIndex: 'reason',
    //                 key: 'reason'
    //             }
    //         ],
    //         key: 'reject'
    //     },
    //     {
    //         title: 'Netral',
    //         children: [
    //             {
    //                 title: 'Employee Name',
    //                 dataIndex: 'netral_name',
    //                 key: 'name'
    //             },
    //             {
    //                 title: 'Division',
    //                 dataIndex: 'netral_division',
    //                 key: 'division_name'
    //             }
    //         ],
    //         key: 'netral'
    //     }
    // ]

    // const data1 = voteAcc.concat(voteReject, voteNetral);

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/vote/${voteId}`);
    }

    const exportReportNetral = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/vote-result');
    }

    return (
        <Card className="content-container">
            <PageTitle title="Vote Result" breadcrumbs={[['Vote Result Report', '/report/vote-result'], ['Result Detail']]}></PageTitle>
            {
                loaded ?
                    <div>
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{ float: 'left' }}>Vote</Text>
                                <Button style={{ float: 'right', borderRadius: 5 }} type="primary" onClick={() => exportReport()} icon={<FileExcelOutlined />}>Export All Data</Button>
                            </div>}>
                            <Form size="middle" form={form} layout="vertical">
                                <Row justify="space-around">
                                    <Col span={11}>
                                        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                                            <Input placeholder="Title" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Due Date" name="due_date" rules={[{ required: true }]}>
                                            <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <br />

                        <Card title={
                            <div className="full-width">
                                <Text strong style={{ float: 'left' }}>Result Detailt</Text>
                            </div>}>

                            <DataTable size='small' bordered dataSource={voteResult} columns={columns} />
                        </Card>

                    </div>
                    : <Spinner />
            }

            {
                (modal) && value === 1 ?
                    <BasicModal title={"Data Accepted Vote"} width={800} showModal={modal} onCloseModal={() => setModal(false)}>
                        <DataTable size='small' bordered dataSource={voteAcc} columns={columnAcc} />
                    </BasicModal>
                    : (modal) && value === 2 ?
                        <BasicModal title={"Data Reject Vote"} width={800} showModal={modal} onCloseModal={() => setModal(false)}>
                            <DataTable size='small' bordered dataSource={voteReject} columns={columnReject} />
                        </BasicModal>
                        : (modal) && value === 3 ?
                            <BasicModal title={"Data No Vote"} width={800} showModal={modal} onCloseModal={() => setModal(false)}>
                                <Button style={{ float: 'right', borderRadius: 5, marginBottom: 10 }} type="primary" onClick={() => exportReportNetral()} icon={<FileExcelOutlined />}> Export No Voting </Button>
                                <DataTable size='small' bordered dataSource={voteNetral} columns={columnNetral} />
                            </BasicModal>
                            // : (modal) && value === 4 ?
                            //     <BasicModal title={"Data All Vote"} width={1000} showModal={modal} onCloseModal={() => setModal(false)}>
                            //         <DataTable size='small' bordered dataSource={data1} columns={allColumn} />
                            //     </BasicModal>
                            : null
            }
        </Card>
    );
}

export default Detail;
