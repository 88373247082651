import { useEffect, useState } from 'react';
import { Card, Typography, Form, Input, message, Space, Select, Row, Col } from 'antd'
import { AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import { checkRole } from '../../../helpers/roleData';

const { Text } = Typography
const { Option } = Select

const Component = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [components, setComponents] = useState([])
    const [categories, setCategories] = useState([])
    const [divisions, setDivisions] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState(authData.division_id)

    const fetchComponents = (v) => {
        SentralModel.list('KpiItem', { filter: [['division_id', v]] }).then(res => {
            setComponents(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then(res => {
            let arr = []
            res.data.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })
            setDivisions(arr)
            setLoaded(true)
        })
    }

    const fetchCategories = () => {
        SentralModel.list('KpiItemCategory').then((res) => {
            setCategories(res.data)
        })
    }

    useEffect(() => {
        fetchComponents(authData.division_id)
        fetchCategories()
        fetchDivisions()
    }, []);

    const dataSource = [
        ...components.map(el => {
            return {
                id: el.kpi_item_id,
                kpi_item_category_id: el.kpi_item_category_id,
                category: el.category?.name,
                description: el.description,
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editComponent(row)} />
                        <DeleteButton onConfirm={() => deleteComponent(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    const addComponent = () => {
        setModalTitle('Add Component')
        setDefaultValues({
            kpi_item_id: 0,
            kpi_item_category_id: '',
            description: '',
        })
        setFormModal(true)
    }

    const editComponent = (v) => {
        setModalTitle('Edit Component')
        setDefaultValues({
            kpi_item_id: v.id,
            kpi_item_category_id: v.kpi_item_category_id,
            description: v.description
        })
        setFormModal(true)
    }

    const deleteComponent = (id) => {
        message.loading('deleting component')
        SentralModel.deleteById('KpiItem', id).then((res) => {
            message.destroy()
            message.success('Component KPI deleted', 2)
            let tmp = components
            let index = tmp.findIndex(el => el.kpi_item_id === id)
            tmp.splice(index, 1);
            setComponents([])
            setComponents(tmp)
        })
    }

    const saveComponent = (v) => {
        setSaving(true)
        let payload = {
            kpi_item_id: v.kpi_item_id,
            kpi_item_category_id: v.kpi_item_category_id,
            division_id: v.division_id,
            description: v.description
        }

        SentralModel.save('KpiItem', payload, v.kpi_item_id).then((res) => {
            setFormModal(false)
            fetchComponents(authData.division_id);
            message.success('Component KPI saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Component KPI" breadcrumbs={[['Component']]}></PageTitle>
                <Card title={
                    <Row justify='space-between'>
                        <Col>
                            <Text strong style={{ float: 'left' }}>Component</Text>
                        </Col>
                        <Col>
                            <Space>
                                {
                                    checkRole('SUPERADMIN') || checkRole('ADMIN') || checkRole('HR') ?
                                        <Select
                                            defaultValue={selectedDivision}
                                            style={{ width: '200px' }}
                                            options={divisions}
                                            onChange={(v) => {
                                                setSelectedDivision(v)
                                                fetchComponents(v)
                                            }} />
                                        : null
                                }
                                <AddButton right onAdd={addComponent} title="New Component" />
                            </Space>
                        </Col>
                    </Row>
                }>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveComponent(v)} width={600} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="kpi_item_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item label="Category" name="kpi_item_category_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Category" style={{ width: '100%' }}>
                                    <Option value="">Select Category</Option>
                                    {
                                        categories.map((el, key) => (
                                            <Option key={'category_' + key} value={el.kpi_item_category_id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Divison" name="division_id" rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="Select Division"
                                    options={divisions}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                <Input placeholder="Description" />
                            </Form.Item>
                        </FormModal>
                        : null
                }
            </Card>
        </div >
    );
}

export default Component;
