import React, { useState } from 'react';
import { Card, message, Typography } from 'antd';
import { PageTitle } from '../../../components'
import CreateForm from './Form'
import { SentralModel } from '../../../models/SentralModel';
import { useHistory } from 'react-router-dom'

const { Text } = Typography

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitReward = (v) => {
        setSaving(true)
        SentralModel.action("RewardPunishment", "saveReward", v, 0).then((res) => {
            message.success("Reward saved", 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/management/reward');
            }, 1000);
        }).catch((err) => {
            message.error(err.message, 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Reward" breadcrumbs={[['Reward', '/management/reward'], ['Create']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Form Reward</Text>
                </div>
            }>
                <CreateForm onSubmit={(v) => submitReward(v)} saving={saving} />
            </Card>
        </Card>
    );
}

export default Create;
