import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Form, Input, Typography, Select, DatePicker, Button} from 'antd';
import moment from 'moment'
import {SubmitButton, AddButton} from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel';
import {DeleteOutlined} from '@ant-design/icons'
const {Text} = Typography
const {Option} = Select

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    const [vendors, setVendors] = useState([])
    const [items, setItems] = useState([])
    const [assetItem, setAssetItem] = useState([{item_id:'', quantity: null, serial_number:''}])


    const fetchVendors = () =>{
        SentralModel.list('Vendor', {orderBy: "vendor_name", order:"ASC"}).then((res)=>{
            setVendors(res.data)
        })
    }

    const fetchItems = () =>{
        SentralModel.list('AssetItem', {orderBy: "item_name", order:"ASC"}).then((res)=>{
            setItems(res.data)
        })
    }

    useEffect(() => {
        fetchVendors()
        fetchItems()
    }, []);

    const addAssetItem = () =>{
        let x=assetItem
        let key = assetItem.length
        x.push({item_id:'', quantity: null, serial_number:''})
        setAssetItem(x)
        forceUpdate()
        form.setFieldsValue({
            ['item_id_'+key] : null,
            ['serial_number_'+key] : null,
            ['quantity_'+key] : null,
        })
    }

    const submitGoodsReceipt = (v) =>{
        let data={
            date: moment(v.date).format('YYYY-MM-DD'),
            purchase_number: v.purchase_number,
            vendor_id: v.vendor_id,
            goods_receipt_item: assetItem,
            remarks: v.remarks
        }
        props.onSubmit(data)
    }

    const removeItem = (key) =>{
        let item = assetItem
        item.splice(key,1)
        setAssetItem(item)
        forceUpdate()
    }
    return (
        <Form form={form} size="middle" layout="vertical" onFinish={submitGoodsReceipt}>
            <Row justify="center">
                <Col span={20}>
                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item name="purchase_number" label="PO Number" rules={[{required:true}]}>
                                <Input placeholder="PO Number"/>
                            </Form.Item>
                            <Form.Item name="date" label="Date" rules={[{required:true}]}>
                                <DatePicker style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item name="vendor_id" label="Vendor" rules={[{required:true}]}>
                                <Select showSearch allowClear placeholder="Select Vendor" style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        vendors.map((el, key)=>(
                                            <Option key={key} value={el.vendor_id}>{el.vendor_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="remarks" label="Remarks">
                                <Input placeholder="Remarks"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card title={
                                <div className="full-width">
                                    <Text strong style={{float:'left'}}>Asset Item</Text>
                                    <AddButton right onAdd={() => addAssetItem()} title="Add Item"/>
                                </div>}>
                                
                                <table className="table-item" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th width="50%">Item</th>
                                            <th width="30%">Serial Number</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            assetItem.map((el, key) =>(
                                                <tr key={'item'+key}>
                                                    <td>
                                                        <Form.Item name={`item_id_${key}`} rules={[{required:true}]}>
                                                            <Select showSearch allowClear placeholder="Item Name" onChange={(v) => {
                                                                let x= assetItem;
                                                                x[key].item_id = v
                                                                setAssetItem(x)
                                                            }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                {
                                                                    items.map((el, key)=>(
                                                                        <Option key={key} value={el.asset_item_id}>{el.item_name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`serial_number_${key}`} rules={[{required:true}]}>
                                                            <Input placeholder="Serial Number" onChange={(v) => {
                                                                let x= assetItem;
                                                                x[key].serial_number = v.target.value
                                                                setAssetItem(x)
                                                            }}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`quantity_${key}`} rules={[{required:true}]}>
                                                            <Input type="number" placeholder="Quantity" onChange={(v) => {
                                                                let x= assetItem;
                                                                x[key].quantity = v.target.value
                                                                setAssetItem(x)
                                                            }}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td className="text-center" style={{width:'4em'}}>
                                                        {
                                                            ((key+1)===assetItem.length && assetItem.length>1) ? 
                                                            <Button type="text" danger onClick={() => removeItem(key)} icon={<DeleteOutlined/>}/>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
