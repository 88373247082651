import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [purchaseRequest, setPurchaseRequest] = useState(null)

    const fetchPurchaseRequest = (id) => {
        SentralModel.action('Submission', 'getPurchaseRequest', { sub_purchase_request_id: id }, id).then((res) => {
            setPurchaseRequest(res.data)
        })
    }

    useEffect(() => {
        fetchPurchaseRequest(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setPurchaseRequest(null)
        fetchPurchaseRequest(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Purchase Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (purchaseRequest) ?
                    <div>
                        <DetailHeader
                            name={purchaseRequest?.employee.name}
                            date={purchaseRequest?.created_at}
                            status={purchaseRequest?.status}
                            pr={purchaseRequest?.is_over_budget} />
                        <Card bordered className="mb-4">
                            <Text strong>PURCHASE REQUEST DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{purchaseRequest?.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{purchaseRequest?.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{purchaseRequest?.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>COA Code</th><td>{purchaseRequest?.coa_code}</td>
                                            </tr>
                                            <tr>
                                                <th>Budget Planning</th><td>{purchaseRequest?.budget_planning.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Budget Total</th><td>{toIdr(purchaseRequest?.budget.amount)}</td>
                                            </tr>
                                            <tr>
                                                <th>Budget Used</th><td>{toIdr(purchaseRequest?.budget.used)}</td>
                                            </tr>
                                            <tr>
                                                <th>Budget Remaining</th><td>{toIdr(purchaseRequest?.budget.amount - purchaseRequest?.budget.used)}</td>
                                            </tr>
                                            <tr>
                                                <th>Purchase Request Total</th><td>{toIdr(purchaseRequest?.total_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered className="mb-4">
                            <Text strong>PURCHASE REQUEST ITEM</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={24}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Item Name</th>
                                                <th>Remark</th>
                                                <th>Item Price</th>
                                                <th className="text-center">Item QTY</th>
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchaseRequest?.items.map((el, key) => (
                                                    <tr key={key}>
                                                        <td>{el.item_name}</td>
                                                        <td>{el.remarks}</td>
                                                        <td>{toIdr(el.price)}</td>
                                                        <td className="text-center">{el.quantity}</td>
                                                        <td>{toIdr(el.total)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th id="total" colspan="4">Grand Total</th>
                                                <td>{toIdr(purchaseRequest?.total_amount)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category={(purchaseRequest?.is_over_budget === '1') ? 'PURCHASEOVERBUDGET' : 'PURCHASEONBUDGET'}
                                requestId={purchaseRequest?.sub_purchase_request_id}
                                approvers={purchaseRequest?.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;
