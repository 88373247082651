import React, { useEffect, useState } from 'react';
import { Card, Col, Descriptions, Row, Space, Form, Select, message, TimePicker, Input } from 'antd';
import { DeleteButton, PageTitle, Spinner, FormModal, AddButton } from '../../../components';
import { EditButton, DataTable } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import { getQuery } from '../../../helpers/url';
import moment from 'moment';

const {Option} = Select

const Custom = () => {
    const [loaded, setLoaded] = useState(false)
    const [customs, setCustoms] = useState([])
    const [info, setInfo] = useState(null)
    const [divisionId, setDivisionId] = useState(null)
    const [customModal, setCustomModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const fetchCustomWorkingHour = (divisionId) => {
        setLoaded(false)
        SentralModel.action('Division', 'customWorkingHour',{division_id: divisionId}, 0).then((res) => {
            setCustoms(res.data.customs)
            setInfo(res.data.info)
            setLoaded(true)
        })
    }

    const fetchEmployees = (divisionId) => {
        SentralModel.action('Division', 'getEmployeeByCustom',{division_id: divisionId}, 0).then((res)=>{
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        let divId = getQuery('id')
        setDivisionId(divId)

        fetchCustomWorkingHour(divId)
        fetchEmployees(divId)
    }, []);


    const dataSource = [
        ...customs.map((el) => {
            return {
                id: el.custom_working_hour_id,
                division_id: el.division_id,
                employee_code: el.employee_code,
                name: el.employee ? el.employee.name : null,
                daily_in: el.daily_in,
                daily_out: el.daily_out
            }
        })
    ]

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            className:'text-top',
            width:'70px',
            align:'center',
            key: 'no',
        },{
            title: 'Employee',
            dataIndex: 'name',
            className:'text-top',
            key: 'name',
        },{
            title: 'Working Hours',
            render: (row) => (
                <div className="text-center">
                    <span>
                        {row.daily_in} - {row.daily_out}
                    </span>
                </div>
            )
        },{
            title: 'Action',
            className:'text-top',
            align:'center',
            width:'10%',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => editCustom(row)}/>
                    <DeleteButton onConfirm={() => deleteCustomWorkingHour(row.id)}/>
                </Space>
            )
        }
    ];

    const addCustom = () => {
        setModalTitle('Add Custom Working Hour')
        setDefaultValues({
            custom_working_hour_id: 0,
            employee_code: '',
            daily_in: '',
            daily_out: '',
        })
        setCustomModal(true)
    }

    const editCustom = (v) => {
        setModalTitle('Edit Custom Working Hour')
        employees.push({employee_code: v.employee_code, name: v.name})
        setDefaultValues({
            custom_working_hour_id: v.id,
            employee_code: v.employee_code,
            daily_in: moment(v.daily_in, 'HH:mm'),
            daily_out: moment(v.daily_out, 'HH:mm'),
        })
        setDisabled(true)
        setCustomModal(true)
    }

    const saveCustomWorkingHour = (v) => {
        setSaving(true)
        let payload = {
            id: v.custom_working_hour_id,
            division_id: divisionId,
            employee_code: v.employee_code,
            daily_in: moment(v.daily_in).format('HH:mm'),
            daily_out: moment(v.daily_out).format('HH:mm')
        }

        SentralModel.action('Division', 'saveCustomWorkingHour', payload, v.custom_working_hour_id).then((res) => {
            setCustomModal(false);
            fetchCustomWorkingHour(divisionId);
            fetchEmployees(divisionId);
            message.success('Custom working hour saved', 2);
        }).finally(() => {
            setSaving(false);
            setDisabled(false);
        })
    }

    const deleteCustomWorkingHour = (id) => {
        message.loading('Deleting custom working hour')
        SentralModel.deleteById('CustomWorkingHour', id).then((res) => {
            message.destroy()
            message.success('Custom working hour deleted', 2)
            fetchCustomWorkingHour(divisionId)
            fetchEmployees(divisionId);
        })
    }

    const closeModal = () => {
        setCustomModal(false);
        setDisabled(false);
        fetchEmployees(divisionId);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Working Hour" breadcrumbs={[['Division', '/organization/division'], ['Custom']]}></PageTitle>
                <div className="full-width">
                    <Row>
                        <Col span={12}>
                            <Descriptions layout="horizontal" column={1} bordered size="small">
                                <Descriptions.Item label="Divisi">{(info) ? info.division_name: '-'}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={12} className="text-right">
                            <AddButton onAdd={addCustom} title="Tambah Working Hour"/>
                        </Col>
                    </Row>
                </div>
                {
                    (loaded) ?
                        <DataTable bordered dataSource={dataSource} columns={columns}/>
                    : <Spinner/>
                }
            </Card>

            {
                (customModal) ?
                    <FormModal title={modalTitle} defaultValues={defaultValues} submitForm={(v)=>saveCustomWorkingHour(v)} width={500} showModal={customModal} onCloseModal={closeModal} saving={saving}>
                        <Form.Item name="custom_working_hour_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item label="Employee" name="employee_code" rules={[{required:true}]}>
                            <Select showSearch disabled={disabled} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    employees.map((el, key) => (
                                        <Option key={key} value={el.employee_code}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Daily In" name="daily_in" rules={[{required:true}]}>
                            <TimePicker format="HH:mm" style={{width:'100%'}}/>
                        </Form.Item>
                        <Form.Item label="Daily Out" name="daily_out" rules={[{required:true}]}>
                            <TimePicker format="HH:mm"  style={{width:'100%'}}/>
                        </Form.Item>
                    </FormModal> 
                : null
            }
        </div>
    );
}

export default Custom;
