import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Button} from 'antd'
import {AimOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [areas, setAreas] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchAreas = () =>{
        setLoaded(false)
        SentralModel.list('Area', {order:'asc',orderBy:'created_at'}).then(res=>{
            setAreas(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAreas()
    }, []);
    
    const dataSource = [
        ...areas.map( el => {
            return {
                id: el.area_id,
                area_name: el.area_name,
                latitude: el.latitude,
                longitude: el.longitude,
                radius:el.radius
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
            width:'70px'
        },{
            title: 'Area Name',
            dataIndex: 'area_name',
            key: 'area_name',
        },{
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
        },{
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
        },{
            title: 'Radius',
            dataIndex: 'radius',
            align:'center',
            key: 'radius',
        },{
            title: 'Action',
            align:'center',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editAreas(row)}/>
                        <DeleteButton onConfirm={() => deleteAreas(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addArea=()=>{
        setModalTitle('Add Area')
        setDefaultValues({
            area_id: 0,
            area_name: '',
            latitude: '',
            longitude: '',
            radius: null
        })
        setFormModal(true)
    }

    const editAreas = (v) =>{
        setModalTitle('Edit Areas')
        setDefaultValues({
            area_id: v.id,
            area_name: v.area_name,
            latitude: v.latitude,
            longitude: v.longitude,
            radius: v.radius
        })
        setFormModal(true)
    }

    const deleteAreas = (id) =>{
        message.loading('deleting area')
        SentralModel.deleteById('Area', id).then((res)=>{
            message.destroy()
            message.success('Areas deleted', 2)
            let tmp = areas
            let index = tmp.findIndex(el => el.area_id===id)
            tmp.splice(index, 1);
            setAreas([])
            setAreas(tmp)
        })
    }

    const saveArea = (v) => {
        setSaving(true)
        let payload={
            area_id: v.id,
            area_name: v.area_name,
            latitude: v.latitude,
            longitude: v.longitude,
            radius: v.radius
        }
        SentralModel.save('Area', payload, v.area_id).then((res)=>{
            setFormModal(false)
            fetchAreas()
            message.success('Area saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Areas" breadcrumbs={[['Area']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Area List</Text>
                        <Space style={{float:'right'}}>
                            <Link to="/setting/division-attendance-area">
                                <Button type="primary" icon={<AimOutlined />}>Setup Attendance Area</Button>
                            </Link>
                            <AddButton onAdd={addArea} title="New Area"/>
                        </Space>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveArea(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="area_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Area Name" name="area_name"  required rules={[{required:true}]}>
                            <Input placeholder="Area Name" />
                        </Form.Item>
                        <Form.Item label="Latitude" name="latitude" rules={[{required:true}]}>
                            <Input placeholder="Area Code" />
                        </Form.Item>
                        <Form.Item label="Logitude" name="longitude" rules={[{required:true}]}>
                            <Input placeholder="Area Code" />
                        </Form.Item>
                        <Form.Item label="Radius" name="radius" rules={[{required:true}]}>
                            <Input type="number" placeholder="Radius" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
