import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { formatDateTime } from '../../../utils/dateFormat'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'
import { toIdr } from '../../../utils/currencyHandler';

const { RangePicker } = DatePicker
const { Option } = Select

const Reimbursement = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [reimbursementData, setReimbursementData] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [employees, setEmployees] = useState([])

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchEmployees()
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ? v.division_id : null,
            employee_code: v.employee_code ? v.employee_code : null,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', { fields: 'employee_code,name,division_id', orderBy: 'name', order: 'ASC', filter: [['is_active', '=', '1']] }, 0).then((res) => {
            setEmployees(res.data);
        })
    }

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'reimbursement', ft, 0).then((res) => {
            setReimbursementData(res.data)
            setLoaded(true)
        })
    }

    const reimbursementColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        className: 'text-top',
        key: 'name',
    },
    {
        title: 'Employee Name',
        dataIndex: 'name',
        className: 'text-top',
    }, {
        title: 'Division',
        dataIndex: 'division_name',
        className: 'text-top',
    }, {
        title: 'Request Date',
        dataIndex: 'request_date',
        className: 'text-top',
    }, {
        title: 'Title',
        dataIndex: 'title',
        className: 'text-top',
    }, {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        className: 'text-top',
    }, {
        title: 'Items',
        width: '300px',
        render: (row) => (
            <ol style={{ paddingLeft: '20px' }}>
                {
                    row.items.map((el, i) => (
                        <li key={i}>{el.description}<br />{toIdr(el.amount)}</li>
                    ))
                }
            </ol>
        )
    }
    ];

    const dataSource = [
        ...reimbursementData.map(el => {
            return {
                sub_stationery_id: el.sub_stationery_id,
                employee_code: el.employee_code,
                name: el.name,
                division_name: el.division_name,
                total_amount: toIdr(el.total_amount),
                title: el.title,
                items: el.items,
                request_date: formatDateTime(el.created_at)
            }
        })
    ]

    const changeDateRange = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/reimbursement?division_id=' + selectedDivision + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    return (
        <Card className="content-container">
            <PageTitle title="Reimbursement Report" breadcrumbs={[['Reimbursement Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable pagination={false} size="small" columns={reimbursementColumn} dataSource={dataSource} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            onChange={(v) => {
                                setSelectedDivision(v)
                            }}
                            allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="employee_code" label="Employee">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Employee" allowClear>
                            <Option value={null}>All Employee</Option>
                            {
                                employees.filter(el => el.division_id === selectedDivision).map((el, key) => (
                                    <Option
                                        key={key}
                                        value={el.employee_code}
                                    >{el.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range">
                        <RangePicker onChange={(v) => changeDateRange(v)} />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Reimbursement;
